/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 media-console.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function MediaConsole(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/Media-Console.glb'))
  // const { nodes, materials } = useGLTF('m23d/Media-Console.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.16,0,0.53]}>
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_UPHOLTERY_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_STICHES001_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_STICHES000_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_POWER_OUTPUT006_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_POWER_OUTPUT005_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_POWER_OUTPUT004_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_POWER_OUTPUT003_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_POWER_OUTPUT002_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_POWER_OUTPUT001_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS011_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS010_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS009_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS008_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS007_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS006_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS005_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS004_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS003_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS002_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS001_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_HARDWARE_PARTS000_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_ARMREAST_COVER004_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_ARMREAST_COVER003_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_COOLER_ARMREAST_COVER002_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_RUBBER_FEET001_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_RUBBER_FEET000_n3d.geometry} material={props.materials.feet} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/Media-Console.glb'))
// useGLTF.preload('m23d/Media-Console.glb')
