import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ulid } from 'ulid';
import { PRIVACY_NOTICE_URL, TERMS_OF_USE_URL } from '../../constants';
import EmailDesignIllustration from './EmailDesignIllustration';
import * as FormElements from './FormElements';
import * as Button from './Button';
import Container from './Container';
import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET,
  ENVIRONMENT,
} from '../../constants';
import { useAppContext } from '../context/app';
import useCreateConfig from '../hooks/useCreateConfig';
import renderNodeToJpeg from '../helpers/RenderNodeToJpeg';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3rem 0 0 0;
  justify-content: center;
  background-color: var(--dark-tyrian-blue);
  background-image: url('/images/M1Background.svg');
  opacity: 0;
  transform: translateY(100%);
  &._displaytrue {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.25s ease-in-out;
  }
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.875rem 0 0;
`;

const Illustration = styled.div`
  margin: 0 auto 1rem;
  width: 11.25rem;
  height: 13.3125rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const EmailDesign = ({ closeHandler, piecesMatrix }) => {
  const { storeNumber, apiProducts, activeColor, salesId, webUser } = useAppContext();
  const [shown, setShown] = useState(false);
  const [sent, setSent] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  useEffect(() => {
    setShown(true);
  }, []);

  const closeModal = useCallback(() => {
    setShown(false);
    setTimeout(() => {
      closeHandler();
    }, 250);
  }, [setShown, closeHandler]);

  const onError = useCallback(
    (error, variables, context) => {
      setFormProcessing(false);
      console.error('Could not save config.');
    },
    [setFormProcessing]
  );

  const onSuccess = useCallback(
    async (data, variables, context) => {
      console.log('onSuccess');
      setSent(true);
      setFormProcessing(false);
    },
    [setSent, setFormProcessing]
  );

  const configMutation = useCreateConfig({
    onError,
    onSuccess,
  });

  // initiate email
  const generateScreenshot = useCallback(async () => {
    const node = document.getElementById('configuration-image');
    const dataUrl = await renderNodeToJpeg(node);

    return dataUrl;
  }, []);

  const uploadFile = useCallback(
    async (file) => {
      return new Promise((resolve, reject) => {
        const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        xhr.upload.onerror = function () {
          reject(`Error during the upload: ${xhr.status}`);
        };

        xhr.onreadystatechange = function (e) {
          if (xhr.readyState == 4 && xhr.status == 200) {
            const response = JSON.parse(xhr.responseText);
            resolve(response);
          }
        };

        fd.append('public_id', [ENVIRONMENT, storeNumber, ulid()].join('/'));
        fd.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
        fd.append(
          'tags',
          `user_configuration_upload,store-number-${storeNumber},${ENVIRONMENT}`
        );
        fd.append('file', file);
        xhr.send(fd);
      });
    },
    [storeNumber]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setFormProcessing(true);
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        const screenshot = await generateScreenshot();
        const response = await uploadFile(screenshot);

        const skus = Object.values(piecesMatrix).map((piece) => {
          const { x, y, pieceType } = piece;
          const product = apiProducts[activeColor][pieceType];
          return {
            x,
            y,
            pieceType,
            sku: product.sku,
            color: product.color,
            description: product.productDescription,
          };
        });

        configMutation.mutate({
          url: response.url,
          storeNumber,
          ...formJson,
          skus,
        });
      } catch (e) {
        console.log(e.message);
        setFormProcessing(false);
      }
    },
    [
      generateScreenshot,
      uploadFile,
      piecesMatrix,
      configMutation,
      storeNumber,
      apiProducts,
      activeColor,
    ]
  );

  return (
    <Wrapper className={`_display${shown}`} data-shown={shown}>
      <BackButtonWrapper>
        <Container>
          <Button.OutlineUI clickHandler={closeModal} icon="back">
            Back
          </Button.OutlineUI>
        </Container>
      </BackButtonWrapper>

      <Illustration>
        <EmailDesignIllustration sent={sent} />
      </Illustration>

      <FormElements.FormWrapper>
        {sent && (
          <>
            <FormElements.Title>Email Sent.</FormElements.Title>
            <FormElements.TextCentered>
              Check your inbox for a copy of your saved design.
            </FormElements.TextCentered>
          </>
        )}
        {!sent && (
          <>
            <FormElements.Form method="post" onSubmit={handleSubmit}>
              <FormElements.Title>
                Send the saved design to your email.
              </FormElements.Title>

              <FormElements.Field>
                <FormElements.FieldHeader>
                  <FormElements.FieldLabel>
                    Email Address
                  </FormElements.FieldLabel>
                </FormElements.FieldHeader>
                <FormElements.FieldInput type="email" name="email" />
              </FormElements.Field>

              <FormElements.Separator />
              {!webUser && (
                <>
                  <FormElements.Text>
                    Did a Sales Associate help you?
                  </FormElements.Text>

                  <FormElements.Field>
                    <FormElements.FieldHeader>
                      <FormElements.FieldLabel>
                        Sales Associate ID
                      </FormElements.FieldLabel>
                      <FormElements.FieldRequiredIndicator>
                        optional
                      </FormElements.FieldRequiredIndicator>
                    </FormElements.FieldHeader>
                    <FormElements.FieldInput
                      type="text"
                      maxLength={7}
                      name="salesAssociateID"
                      value={salesId}
                    />
                  </FormElements.Field>
                </>
              )}
              <FormElements.Actions>
                <Button.StandardUI disabled={formProcessing} type="submit">
                  {!formProcessing && 'Save & Email My Design'}
                  {formProcessing && 'Saving...'}
                </Button.StandardUI>
              </FormElements.Actions>

              <FormElements.Separator />
              <FormElements.LegalText>
                By providing your email address, you consent to receive
                marketing and promotional messages and agree to our{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={TERMS_OF_USE_URL}
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PRIVACY_NOTICE_URL}
                >
                  Privacy Notice
                </a>
                .
              </FormElements.LegalText>
            </FormElements.Form>
          </>
        )}
      </FormElements.FormWrapper>
    </Wrapper>
  );
};

export default EmailDesign;
