// Create our number formatter.
const USFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, 
  minimumFractionDigits: 0, 
});

const formatCurrency = (price) => {
  return USFormat.format(price);
}

export default formatCurrency;