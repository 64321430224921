import defineMatrixPiece from "./DefineMatrixPiece";

const addPieceToMatrixBasic = (piecesMatrix, x, y, activePieceType, newGroupNum) => {
  
  // make a copy of the piecesMatrix to mess around with
  let newPiecesMatrix = {...piecesMatrix};

  console.log('addPieceToMatrixBasic', 'newPiecesMatrix', newPiecesMatrix);

  // define the new piece
  const newMatrixPiece = defineMatrixPiece(activePieceType, 0, x, y, newGroupNum);

  // now add the piece
  newPiecesMatrix[`${x}${y}`] = newMatrixPiece;

  console.log('addPieceToMatrixBasic', 'newPiecesMatrix after added', newPiecesMatrix);

  // return updated matrix
  return newPiecesMatrix;
  
}

export default addPieceToMatrixBasic;