import defineMatrixPiece from "./DefineMatrixPiece";
import checkMatrixForOverlappingPieces from "./CheckMatrixForOverlappingPieces";

const shiftOtherGroupsAfterDelete = (piecesMatrix, deletedMatrixPiece, connectingFrom) => {

  console.log('shiftOtherGroupsAfterDelete() - ', 'removeMultipleGroups', 'deletedMatrixPiece.groupNum', deletedMatrixPiece.groupNum, 'connectingFrom', connectingFrom);

  // make a copy of the piecesMatrix to mess around with
  let newPiecesMatrix = {...piecesMatrix};

  /*
    what is the greatest NE.x of all pieces in each group?
  */
  const groups = [];
  Object.keys(newPiecesMatrix).map(key => {
    const thisPieceGroupNum = newPiecesMatrix[key].groupNum;
    const thisPieceXMax = newPiecesMatrix[key].coords.NE.x;
    const thisPieceXMin = newPiecesMatrix[key].x;
    const thisPieceYMax = newPiecesMatrix[key].coords.SW.y;
    const thisPieceYMin = newPiecesMatrix[key].y;
    console.log('groups', 'thisPieceGroupNum', thisPieceGroupNum, 'thisPieceXMax', thisPieceXMax);
    if (!groups[thisPieceGroupNum]) {
      groups[thisPieceGroupNum] = {
        xMax: thisPieceXMax,
        xMin: thisPieceXMin,
        yMax: thisPieceYMax,
        yMin: thisPieceYMin
      }
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].xMax < thisPieceXMax) {
      groups[thisPieceGroupNum].xMax = thisPieceXMax;      
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].xMin > thisPieceXMin) {
      groups[thisPieceGroupNum].xMin = thisPieceXMin;      
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].yMax < thisPieceYMax) {
      groups[thisPieceGroupNum].yMax = thisPieceYMax;      
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].yMin > thisPieceYMin) {
      groups[thisPieceGroupNum].yMin = thisPieceYMin;      
    }    
  });
  console.log('groupsLength', groups.length);

  // If there's only one group, stop here and return the newPiecesMatrix
  if (groups.length === 1) {
    console.log('groupsLength', 'returning original');
    return newPiecesMatrix;
  };
  
  // get the xMax and yMax of the deletedMatrixPiece's groupNum or the piece itself if it's not in a group
  const deletedMatrixPieceGroupXMax = groups[deletedMatrixPiece.groupNum] ? groups[deletedMatrixPiece.groupNum].xMax  : deletedMatrixPiece.coords.NE.x;
  const deletedMatrixPieceGroupYMax = groups[deletedMatrixPiece.groupNum] ? groups[deletedMatrixPiece.groupNum].yMax  : deletedMatrixPiece.coords.SE.y;
  
  // loop through piecesMatrix and shift all pieces that are in a groupNum other than the deletedMatrixPiece's groupNum
  const shiftedPiecesMatrix = {};
  Object.keys(newPiecesMatrix).map(key => {
    const thisPiece = newPiecesMatrix[key];
    // if same groupNum, add a copy without shifting
    if (thisPiece.groupNum === deletedMatrixPiece.groupNum) {
      shiftedPiecesMatrix[`${thisPiece.x}${thisPiece.y}`] = thisPiece;
    }

    console.log('shouldDeleteShift', 'thisPiece:', thisPiece.pieceType, 'groupNum', thisPiece.groupNum, 'if ottoman should be !== deletedMatrixPiece.groupNum', thisPiece.groupNum !== deletedMatrixPiece.groupNum, 'deletedMatrixPiece.groupNum', deletedMatrixPiece.groupNum, 'connectingFrom', connectingFrom, 'thisPiece.groupNum', thisPiece.groupNum, 'deletedMatrixPieceGroupXMax', deletedMatrixPieceGroupXMax, 'groups[thisPiece.groupNum].xMax', groups[thisPiece.groupNum].xMax, 'groups[thisPiece.groupNum].xMax > deletedMatrixPieceGroupXMax', groups[thisPiece.groupNum].xMax > deletedMatrixPieceGroupXMax, 'connectingFrom === "east"', connectingFrom === 'east', 'thisPiece.groupNum !== deletedMatrixPiece.groupNum', deletedMatrixPiece.groupNum);

    // if different groupNum, shift if necessary
    if (thisPiece.groupNum !== deletedMatrixPiece.groupNum) {
      
      // if connectingFrom is east
      // and thisPiece's group xMax is greater than deletedMatrixPiece's group xMax
      console.log('shouldDeleteShift', 'groups[thisPiece.groupNum].xMax should be > deletedMatrixPieceGroupXMax', groups[thisPiece.groupNum].xMax, deletedMatrixPieceGroupXMax);     
      console.log('shouldDeleteShift', 'should be connecting from east', connectingFrom);
      if (connectingFrom === 'east' && groups[thisPiece.groupNum].xMax > deletedMatrixPieceGroupXMax) {
        const shiftedMatrixPiece = defineMatrixPiece(thisPiece.pieceType, thisPiece.rotation, thisPiece.x - deletedMatrixPiece.width, thisPiece.y, thisPiece.groupNum);
        shiftedPiecesMatrix[`${shiftedMatrixPiece.x}${shiftedMatrixPiece.y}`] = shiftedMatrixPiece;
      }
      // if not, add a copy without shifting
      if (connectingFrom === 'east' && groups[thisPiece.groupNum].xMax <= deletedMatrixPieceGroupXMax) {
        shiftedPiecesMatrix[`${thisPiece.x}${thisPiece.y}`] = thisPiece;
      }

      // and thisPiece's group yMax is greater than deletedMatrixPiece's group yMax
      if (connectingFrom === 'south' && groups[thisPiece.groupNum].yMax > deletedMatrixPieceGroupYMax) {
        const shiftedMatrixPiece = defineMatrixPiece(thisPiece.pieceType, thisPiece.rotation, thisPiece.x, thisPiece.y - deletedMatrixPiece.height, thisPiece.groupNum);
        shiftedPiecesMatrix[`${shiftedMatrixPiece.x}${shiftedMatrixPiece.y}`] = shiftedMatrixPiece;
      }
      // if not, add a copy without shifting
      if (connectingFrom === 'south' && groups[thisPiece.groupNum].yMax <= deletedMatrixPieceGroupYMax) {
        shiftedPiecesMatrix[`${thisPiece.x}${thisPiece.y}`] = thisPiece;
      }

    }
  });

  // check for overlapping pieces
  // if there are overlapping pieces, return the original piecesMatrix
  return checkMatrixForOverlappingPieces(shiftedPiecesMatrix) ? newPiecesMatrix : shiftedPiecesMatrix;

}

export default shiftOtherGroupsAfterDelete;