import getOverlappingMatrixPiece from "./GetOverlappingMatrixPiece";

const checkMatrixForOverlappingPieces = ( piecesMatrix ) => {
  let overlappingPiece = false;
  
  // Iterate over the matrix
  let i = 0;
  Object.keys(piecesMatrix).map((key) => {
    const thisPiece = piecesMatrix[key];
    const findOverlappingPiece = getOverlappingMatrixPiece(piecesMatrix, thisPiece);
    overlappingPiece = findOverlappingPiece ? findOverlappingPiece : overlappingPiece;
  });
  return overlappingPiece;
}

export default checkMatrixForOverlappingPieces;