import defineMatrixPiece from "./DefineMatrixPiece";
import checkMatrixForOverlappingPieces from "./CheckMatrixForOverlappingPieces";
import shiftOtherGroupsAfterInsert from "./ShiftOtherGroupsAfterInsert";

const addPieceToMatrixAndShift = (newMatrixPiece, action, piecesToMove, connectingFrom, piecesMatrix, numGridColumns, numGridRows) => {
  
  console.log('addPieceToMatrixAndShift', 'newMatrixPiece.groupNum', newMatrixPiece.groupNum);
  
  // make a copy of the piecesMatrix to mess around with
  let newPiecesMatrix = {...piecesMatrix};

  // make a copy of the piecesMatrix to reference
  let origPiecesMatrix = {...piecesMatrix};


  if (action === 'lessThanZero') {
    const shiftedPiecesMatrix = {};

    // shift pieces over by width of the newMatrixPiece
    // reset 'saved neighbors'
    Object.keys(newPiecesMatrix).map(key => { 
      const shiftedMatrixPiece = defineMatrixPiece(newPiecesMatrix[key].pieceType, newPiecesMatrix[key].rotation, newPiecesMatrix[key].x + newMatrixPiece.width, newPiecesMatrix[key].y, newPiecesMatrix[key].groupNum);
      shiftedPiecesMatrix[`${shiftedMatrixPiece.x}${shiftedMatrixPiece.y}`] = shiftedMatrixPiece;
    });

    // set the newMatrixPiece
    const shiftedNewMatrixPiece = defineMatrixPiece(newMatrixPiece.pieceType, newMatrixPiece.rotation, newMatrixPiece.x + newMatrixPiece.width, newMatrixPiece.y, newMatrixPiece.groupNum);
    shiftedPiecesMatrix[`${shiftedNewMatrixPiece.x}${shiftedNewMatrixPiece.y}`] = shiftedNewMatrixPiece;
    
    return shiftedPiecesMatrix;
  }

  if (action === 'add' || action === 'addToZero') {
    // now add the piece
    newPiecesMatrix[`${newMatrixPiece.x}${newMatrixPiece.y}`] = newMatrixPiece; 

    // reset 'saved neighbors'
    Object.keys(newPiecesMatrix).map(key => { 
      newPiecesMatrix[key].neighbor = {
        north: null,
        east: null,
        south: null,
        west: null,
      }
    });


    return newPiecesMatrix;
  }

  // if we are inserting a piece - that's the business
  if (action === 'insert') {    
    
    // make a copy of the newPiecesMatrix to mess around with                        
        
    // loop thru the piecesToMove and remove them all from newPiecesMatrix
    piecesToMove.map((pieceToMove, index) => {                  
      // remove from newPiecesMatrix
      delete newPiecesMatrix[`${pieceToMove.x}${pieceToMove.y}`];
    });

    // loop thru the piecesToMove and add the updated versions
    piecesToMove.map((pieceToMove, index) => {
      console.log('piecesToMove loop', 'groupNum', pieceToMove.groupNum);
      const pieceToAdd = defineMatrixPiece(pieceToMove.pieceType, pieceToMove.rotation, pieceToMove.newX, pieceToMove.newY, pieceToMove.groupNum);
      // add the piece to our mess around copy
      newPiecesMatrix[`${pieceToAdd.x}${pieceToAdd.y}`] = pieceToAdd;
      // alert('test');      
    });

    // now add the piece we were always going to add
    newPiecesMatrix[`${newMatrixPiece.x}${newMatrixPiece.y}`] = newMatrixPiece;

    // check if this new moved matrix has any overlapping pieces
    const overlappingPiece = checkMatrixForOverlappingPieces(newPiecesMatrix);
    
    // if we have overlapping pieces
    if (overlappingPiece) {
      console.log('overlappingPiece.groupNum', overlappingPiece.groupNum);
      // shift the other groups
      return shiftOtherGroupsAfterInsert(newPiecesMatrix, newMatrixPiece, connectingFrom);
    }

    // reset 'saved neighbors'
    Object.keys(newPiecesMatrix).map(key => { 
      newPiecesMatrix[key].neighbor = {
        north: null,
        east: null,
        south: null,
        west: null,
      }
    });


    return newPiecesMatrix;
  }  
}

export default addPieceToMatrixAndShift;