import { useMutation } from 'react-query';
import * as API from '../services/API';

const sendToCart = async (body) => {
  const data = await API.sendToCart(body);

  return data;
};

export default function useSendToCart({
  onError = () => {},
  onSuccess = () => {},
}) {
  return useMutation((newInvitation) => sendToCart(newInvitation), {
    onError,
    onSuccess,
  });
}
