/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 LAF-Chaise.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function LeftChaise(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/LAF-Chaise.glb'))
  // const { nodes, materials } = useGLTF('m23d/LAF-Chaise.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.57, 0, 0.99]}>
        <mesh geometry={nodes.RTG_MT_SEATCUSHIONS_LARGE001_Retopo001_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_028_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH075_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH076_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Back_Standing022_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_UPHOLSTERY006_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH006_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH007_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_RUBBER_FEET003_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT026_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT025_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT024_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT023_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT022_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT021_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER058_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER057_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER056_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER055_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER054_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER053_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER052_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER051_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER050_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER049_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER048_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER047_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER046_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER045_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH000_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH001_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH000_n3d2.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE003_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE002_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE000_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE001_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_027_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER010_Retopo002_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE004_n3d.geometry} material={props.materials.fabric} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/LAF-Chaise.glb'))
// useGLTF.preload('m23d/LAF-Chaise.glb')
