import React, { useEffect, useCallback, useState } from 'react';
import Image from 'next/image';
import formatCurrency from '../helpers/FormatCurrency';
import getAvailability from '../helpers/GetAvailability';
import PieceTypesMetaData from '../constants/PieceTypesMetaData';
import styled from 'styled-components';
import { FocusOn } from 'react-focus-on';
import { useAppContext } from '../context/app';
import * as Button from './Button';
import CloudinaryImage from './CloudinaryImage';

const ModalWrap = styled.div`
  position: fixed;
  z-index: 300;
  top: -100000rem;
  left: 0;
  right: 0;
  height: 100vh;
  opacity: 0;
  background-color: rgba(7, 38, 59, 0.4);
  transition: opacity 0.25s ease-in-out, top 0s ease 0.25s;
  &._displaytrue {
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    transition: opacity 0.25s ease-in-out;
  }
`;
const ModalContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: var(--white);
  padding: 1.25rem;
  width: 100%;
  max-width: 64.5rem;
  @media (min-width: 48em) {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    box-shadow: 0 0.25rem 2.5rem rgba(0, 0, 0, 0.35);
  }
`;

const ModalClose = styled.button`
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  background-color: var(--white);
  position: absolute;
  z-index: 2;
  top: 1.875rem;
  right: 1.875rem;
  transition: background-color 0.25s ease-in-out;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0.4375rem;
    height: 0.0625rem;
    width: 1.15625rem;
    background-color: var(--dark-tyrian-blue);
    transform-origin: top left;
    transition: background-color 0.25s ease-in-out;
  }
  &:before {
    top: 0.4375rem;
    transform: rotate(45deg);
  }
  &:after {
    bottom: 0.4375rem;
    transform: rotate(-45deg);
  }
  &:hover,
  &:focus-visible {
    background-color: var(--dark-tyrian-blue);
    &:before,
    &:after {
      background-color: var(--white);
    }
  }
  &:focus {
    outline: 1px dotted rgba(0, 0, 0, 0.2);
  }
`;

const Cols = styled.div`
  display: grid;
  gap: 0.8725rem;
  grid-template-columns: 1fr;
  grid-template-areas:
    "images"
    "content"
  ;
  background-image: url('/images/M1BackgroundLight.svg');
  background-size: 40% auto;
  background-repeat: repeat-y;
  @media (min-width: 48em) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "content images"
  ;
  }
  @media (min-width: 64em) {
    grid-template-columns: 1fr 1.5fr;
  }
`;

const Content = styled.div`
  grid-area: content;
  padding-right: 2rem;  
`;

const Name = styled.h1`
  margin: 0 0 0.15em 0;
  color: var(--dark-tyrian-blue);
`;
const NameA = styled.span`
  text-wrap: balance;
  display: block;
  color: var(--rtg-blue);
  font: var(--h-large);
  margin-bottom: -0.5em;
`;
const NameB = styled.span`
  display: block;
  font: var(--h-xxxl);
  text-transform: uppercase;
`;

const Availability = styled.div`
  display: block;
  .subheading {
    &:before {
      content: '';
      display: inline-block;
      width: 0.5625rem;
      height: 0.5625rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    & + p {
      margin-top: 0.375rem;
    }
  }
  &.green {
    .subheading {
      &:before {
        background-color: #08A160;
      }
    }
  }
  &.yellow {
    .subheading {
      &:before {
        background-color: #F7C824;
      }
    }
  }
  &.orange {
    .subheading {
      &:before {
        background-color: #F28808;
      }
    }
  }
  &.red {
    .subheading {
      &:before {
        background-color: #DC2932;
      }
    }
  }
`;

const Subheading = styled.h3`
  margin: 2em 0 0;
  font: var(--subheading);
  text-transform: uppercase;
  color: var(--rtg-dark-blue);
`;

const Price = styled.p`
  font: var(--h-large);
  color: var(--dark-tyrian-blue);
  margin: 0 0 0.5em;
`;

const Images = styled.div`
  grid-area: images;
  width: calc(100vw - 2.5rem);
  @media (min-width: 48em) {
    width: 100%;
  }
`;

const MainImage = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
  background-color: var(--offwhite);
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: opacity 0.15s;
    opacity: 0;
    &[data-active='on'] {
      opacity: 1;
    }
  }
`;

const ImageMenu = styled.ul`
  display: flex;
  gap: 0.625rem;
  list-style-type: none;
  margin: 0.625rem 0 0 0;
  padding: 0;
`;

const ImageMenuOption = styled.button`
  width: 100%;
  // height: 5rem;
  transition: border 0.15s;
  background-color: var(--offwhite);
  border: 2px solid transparent;
  &[aria-selected='true'] {
    border-color: var(--electric-lime);
  }
  @media (min-width: 48em) {
    width: 5rem;
    height: 5rem;
  }
  img {
    width: 100%;
    max-width: 5rem;
    height: auto;
    object-fit: contain;
    transform: scale(0.85);
    @media (min-width: 48em) {
      width: 5rem;
      height: 5rem;
    }
  }
`;

const PieceModal = ({ pieceType, closeHandler }) => {
  console.log('modalpieceType', pieceType);
  const { activeColor, apiProducts } = useAppContext();
  const [modalColor, setModalColor] = useState(activeColor);
  const [activeAngle, setActiveAngle] = useState('default');
  const [shown, setShown] = useState(false);
  const activePieceTypeMeta = PieceTypesMetaData[pieceType];
  const price = apiProducts[activeColor][pieceType].price;
  const availableDate = apiProducts[activeColor][pieceType].availability === 'Available' ? '' : apiProducts[activeColor][pieceType].availability+' ';
  const availabilityMeta = getAvailability(apiProducts[activeColor][pieceType]?.availability);
  useEffect(() => {
    setShown(true);
  }, []);

  const closeModal = useCallback(() => {
    setShown(false);
    setTimeout(() => {
      closeHandler();
    }, 250);
  }, [closeHandler]);

  return (
    <ModalWrap className={`_display${shown}`} data-shown={shown}>
      <FocusOn onClickOutside={closeModal} onEscapeKey={closeModal}>
        <ModalContent>
          <ModalClose autoFocus onClick={closeModal} />
          <Cols>
            <Content>
              <Name>
                {activePieceTypeMeta.nameA && (
                  <NameA>{activePieceTypeMeta.nameA}</NameA>
                )}
                {activePieceTypeMeta.nameB && (
                  <NameB>{activePieceTypeMeta.nameB}</NameB>
                )}
              </Name>
              <p>{activePieceTypeMeta.description}</p>
              <Subheading>Price</Subheading>
              <Price>{formatCurrency(price)}</Price>
              <Availability className={availabilityMeta.color}>
                <Subheading className='subheading'>{availabilityMeta.text}</Subheading>
                {availabilityMeta.color !== 'green' && (
                <p>Earliest arrival of your order will be {availableDate} as we are awaiting shipments of this product.</p>
                )}
              </Availability>
              <Subheading>Dimensions</Subheading>
              <p>
                W: {activePieceTypeMeta.rotation[0].productWidth}&ldquo; &times;
                D: {activePieceTypeMeta.rotation[0].productDepth}&ldquo; &times;
                H: {activePieceTypeMeta.productHeight}&ldquo;
              </p>
            </Content>
            <Images>
              <MainImage>
                <CloudinaryImage
                  imageId={`${activeColor}/default/${pieceType}`}
                  alt={`${pieceType} default angle`}
                  width={600}
                  height={600}
                  data-active={activeAngle === 'default' ? 'on' : ''}
                />
                <CloudinaryImage
                  imageId={`${activeColor}/back/${pieceType}`}
                  alt={`${pieceType} back angle`}
                  width={600}
                  height={600}
                  data-active={activeAngle === 'back' ? 'on' : ''}
                />
                <CloudinaryImage
                  imageId={`${activeColor}/front/${pieceType}`}
                  alt={`${pieceType} front angle`}
                  width={600}
                  height={600}
                  data-active={activeAngle === 'front' ? 'on' : ''}
                />
                <CloudinaryImage
                  imageId={`${activeColor}/three-quarters/${pieceType}`}
                  alt={`${pieceType} three-quarters angle`}
                  width={600}
                  height={600}
                  data-active={activeAngle === 'three-quarters' ? 'on' : ''}
                />
              </MainImage>
              <ImageMenu>
                <li>
                  <ImageMenuOption
                    onClick={() => setActiveAngle('default')}
                    aria-selected={activeAngle === 'default'}
                  >
                    <CloudinaryImage
                      imageId={`${activeColor}/default/${pieceType}`}
                      alt={`${pieceType} default angle`}
                      width={80}
                      height={80}
                    />
                  </ImageMenuOption>
                </li>
                <li>
                  <ImageMenuOption
                    onClick={() => setActiveAngle('back')}
                    aria-selected={activeAngle === 'back'}
                  >
                    <CloudinaryImage
                      imageId={`${activeColor}/back/${pieceType}`}
                      alt={`${pieceType} back angle`}
                      width={80}
                      height={80}
                    />
                  </ImageMenuOption>
                </li>
                <li>
                  <ImageMenuOption
                    onClick={() => setActiveAngle('front')}
                    aria-selected={activeAngle === 'front'}
                  >
                    <CloudinaryImage
                      imageId={`${activeColor}/front/${pieceType}`}
                      alt={`${pieceType} front angle`}
                      width={80}
                      height={80}
                    />
                  </ImageMenuOption>
                </li>
                <li>
                  <ImageMenuOption
                    onClick={() => setActiveAngle('three-quarters')}
                    aria-selected={activeAngle === 'three-quarters'}
                  >
                    <CloudinaryImage
                      imageId={`${activeColor}/three-quarters/${pieceType}`}
                      alt={`${pieceType} three-quarters angle`}
                      width={80}
                      height={80}
                    />
                  </ImageMenuOption>
                </li>
              </ImageMenu>
            </Images>
          </Cols>
        </ModalContent>
      </FocusOn>
    </ModalWrap>
  );
};

export default PieceModal;
