import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { ulid } from 'ulid';
import * as FormElements from './FormElements';
import * as Button from './Button';
import Container from './Container';
import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET,
  ENVIRONMENT
} from '../../constants';
import { useAppContext } from '../context/app';
import useSendToCart from '../hooks/useSendToCart';
import renderNodeToJpeg from '../helpers/RenderNodeToJpeg';
import Image from 'next/image';
import PriceTable from './PriceTable';
import { cloudinaryImageSrc } from '../helpers/CloudinaryHelpers';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3rem 0 0 0;
  justify-content: center;
  background-color: var(--white);
  background-image: url('/images/M1BackgroundLight.svg');
  opacity: 0;
  transform: translateY(100%);
  &._displaytrue {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.25s ease-in-out;
  }
  p {
    color: var(--dark-tyrian-blue);
  }
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.875rem 0 0;
`;

const Illustration = styled.div`
  margin: 0 auto 1rem;
  width: 11.25rem;
  height: 13.3125rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const PurchaseDesign = ({ closeHandler, piecesMatrix, configurationMeta }) => {
  // console.log('[purchase screen]', configurationMeta, piecesMatrix);
  const { storeNumber, apiProducts, activeColor, salesId, sessionId } = useAppContext();
  const [shown, setShown] = useState(false);
  const [sent, setSent] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  useEffect(() => {
    setShown(true);
  }, []);

  const closeModal = useCallback(() => {
    setShown(false);
    setTimeout(() => {
      closeHandler();
    }, 250);
  }, [setShown, closeHandler]);

  const onError = useCallback(
    (error, variables, context) => {
      setFormProcessing(false);
      console.error('Could not save config.');
    },
    [setFormProcessing]
  );

  const onSuccess = useCallback(
    async (data, variables, context) => {
      console.log('onSuccess');
      setSent(true);
      setFormProcessing(false);
      setTimeout(() => {
        window.location.href = `${process.env.NEXT_PUBLIC_RTG_CART_URL}?cartId=${sessionId}`;
      }, 1000);
    },
    [setSent, setFormProcessing]
  );

  const cartCreation = useSendToCart({
    onError,
    onSuccess,
  });

  const generateScreenshot = useCallback(async () => {
    const node = document.getElementById('configuration-image');
    const dataUrl = await renderNodeToJpeg(node);

    return dataUrl;
  }, []);

  const uploadFile = useCallback(
    async (file) => {
      return new Promise((resolve, reject) => {
        const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        xhr.upload.onerror = function () {
          reject(`Error during the upload: ${xhr.status}`);
        };

        xhr.onreadystatechange = function (e) {
          if (xhr.readyState == 4 && xhr.status == 200) {
            const response = JSON.parse(xhr.responseText);
            resolve(response);
          }
        };

        fd.append('public_id', [ENVIRONMENT, storeNumber, ulid()].join('/'));
        fd.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
        fd.append(
          'tags',
          `user_configuration_upload,store-number-${storeNumber},${ENVIRONMENT}`
        );
        fd.append('file', file);
        xhr.send(fd);
      });
    },
    [storeNumber]
  );

  const formRef = useRef(null);
  const associateId = useRef(null);

  const buildCartPayload = useCallback((screenshot) => {
    let children = [];
    for (const [key, value] of Object.entries(configurationMeta.piecesList)) {
      if (value.quantity > 0) {
        const product = apiProducts[activeColor][key];
        const previewImageSrc = cloudinaryImageSrc(`${activeColor}/three-quarters/${key}`, 'webp', 75, 280, 280);
        children.push({
          sku: product.sku,
          quantity: value.quantity,
          imageUrl: previewImageSrc
        });
      }
    }

    return {
      cartId: sessionId,
      imageUrl: screenshot,
      lineItems: children
    };
  }, [apiProducts, activeColor, sessionId, configurationMeta.piecesList]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setFormProcessing(true);
        
        const screenshot = await generateScreenshot();
        const upload = await uploadFile(screenshot);
        // console.log('cart', buildCartPayload(upload.url));

        cartCreation.mutate(buildCartPayload(upload.url));
      } catch (e) {
        console.log(e.message);
        setFormProcessing(false);
      }
    },
    [
      cartCreation,
      buildCartPayload,
      generateScreenshot,
      uploadFile
    ]
  );

  return (
    <Wrapper className={`_display${shown}`} data-shown={shown}>
      <BackButtonWrapper>
        <Container>
          <Button.OutlineUI clickHandler={closeModal} icon="back">
            Back
          </Button.OutlineUI>
        </Container>
      </BackButtonWrapper>

      <Illustration>
        <Image 
          alt="Shopping Cart"
          src={'/images/shopping-cart.svg'}
          width="138"
          height="138"
        />
      </Illustration>

      <FormElements.FormWrapper>
        {sent && (
          <>
            <FormElements.TitleBlue>Thank you, we received your order!</FormElements.TitleBlue>
            <FormElements.TextCentered>
              Redirecting you to the cart...
            </FormElements.TextCentered>
          </>
        )}
        {!sent && (
          <>
            <FormElements.Form method="post" onSubmit={handleSubmit} ref={formRef}>
              <h2>In Your Selection</h2>
              <PriceTable
                piecesList={configurationMeta.piecesList}
                totalPrice={configurationMeta.price}
              />
              <FormElements.Actions>
                <Button.StandardUI disabled={formProcessing} type="submit">
                  {!formProcessing && 'Add to Cart'}
                  {formProcessing && 'Sending...'}
                </Button.StandardUI>
                
              </FormElements.Actions>
            </FormElements.Form>
          </>
        )}
      </FormElements.FormWrapper>
    </Wrapper>
  );
};

export default PurchaseDesign;
