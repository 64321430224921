/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 RAF-Recliner.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function RightRecliner(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/RAF-Recliner.glb'))
  // const { nodes, materials } = useGLTF('m23d/RAF-Recliner.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.44,0,0.54]}>
        <mesh geometry={nodes.Seat023_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Feet066_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.Hardware_Cover023_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.holding_tag049_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.holding_tag050_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH117_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH118_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_015_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_SEATCUSHIONS_RECLINER_CLOSED016_Retopo003_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_061_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH081_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Back_Standing024_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_UPHOLSTERY023_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH031_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH030_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_RUBBER_FEET011_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT096_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT095_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT094_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT093_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT092_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT091_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER178_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER177_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER176_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER175_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER174_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER173_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER172_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER171_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER170_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER169_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER168_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER167_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER166_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER165_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_014_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER_OPEN004_Retopo001_n3d.geometry} material={props.materials.fabric} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/RAF-Recliner.glb'))
// useGLTF.preload('m23d/RAF-Recliner.glb')
