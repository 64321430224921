/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 LAF-Recliner.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function LeftRecliner(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/LAF-Recliner.glb'))
  // const { nodes, materials } = useGLTF('m23d/LAF-Recliner.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.58,0,0.54]}>
        <mesh geometry={nodes.Seat025_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH122_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH121_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.holding_tag054_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.holding_tag053_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Hardware_Cover025_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Feet068_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_058_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_SEATCUSHIONS_RECLINER_CLOSED016_Retopo016_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_059_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Back_Standing036_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH123_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_UPHOLSTERY021_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_RUBBER_FEET010_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH028_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH027_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT089_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT088_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT087_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT086_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT085_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT084_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER163_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER162_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER161_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER160_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER159_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER158_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER157_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER156_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER155_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER154_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER153_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER152_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER151_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER150_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_060_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER008_Retopo_n3d.geometry} material={props.materials.fabric} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/LAF-Recliner.glb'))
// useGLTF.preload('m23d/LAF-Recliner.glb')
