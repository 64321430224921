import generateEmptyMatrix from "./GenerateEmptyMatrix";
import PieceTypesMetaData from "../constants/PieceTypesMetaData";
import definePiecesToMove from "./DefinePiecesToMove";
import defineMatrixPiece from "./DefineMatrixPiece";
import getNeighbor from "./GetNeighbor";
import getOverlappingMatrixPiece from "./GetOverlappingMatrixPiece";

const commonValues = (arr1, arr2) => {
  return arr1.filter(value => arr2.includes(value));
}

const updateButtonsMatrix = async ( piecesMatrix, activePieceType, numGridColumns, numGridRows, setButtonsMatrix ) => {

  const newButtonsMatrix = [];
  
  if(activePieceType) {
    var piecesMatrixValues = piecesMatrix ? Object.values(piecesMatrix) : 0;
    for (var i = 0; i < piecesMatrixValues.length; i++) {

      const thisPiece = piecesMatrixValues[i];                        
      const {connects, x, y, width, height, pieceType, rotation, groupNum} = thisPiece;
      const thisPieceConnects = connects;          
                  
      // neighbors?
      const neighborWest = getNeighbor('west', piecesMatrix, x, y);
      const neighborEast = getNeighbor('east', piecesMatrix, x, y);
      const neighborNorth = getNeighbor('north', piecesMatrix, x, y);
      const neighborSouth = getNeighbor('south', piecesMatrix, x, y);
      

      // CONNECTS WEST
      // ================================================================================================
      
      // if matrixPiece will connect west to the activePieceType
      if (thisPieceConnects.west[activePieceType]) {
        const activePieceRotation = thisPieceConnects.west[activePieceType][0];
        const activePieceTypeWidth = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].width;
        const activePieceTypeHeight = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].height;
        let activePieceConnects = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].connects;

        let newPieceX = x - activePieceTypeWidth;
        // newPieceY is the same as the existing piece Y by default (lines up at the top)
        let newPieceY = y;
        console.log(`^^∆∆ West 180 --------------------- `);
        console.log(`^^∆∆ West initial y= ${y}`);
        
        // Adding to a 180 piece,
        if (rotation === 180) {
          newPieceY = y - (activePieceTypeHeight - height);
        }

        // If we are adding a new 180 piece, the newPieceY needs to be pushed down to line up at the bottom
        if (activePieceRotation === 180) {
          newPieceY = y + (height - activePieceTypeHeight);
        }


        if (newPieceY >= 0) {
          if (!neighborWest) {
            let action = 'add';            
            // allow west add button but shift everything by activePieceWidth
            if (x - activePieceTypeWidth < 0) {
              action = 'lessThanZero';
            }

            // Don't add a button if the newPiece overlaps an existing piece
            const newPiece = defineMatrixPiece(activePieceType, activePieceRotation, newPieceX, newPieceY, groupNum);
            if (!getOverlappingMatrixPiece(piecesMatrix, newPiece)) {
              newButtonsMatrix.push({ addToPieceType: pieceType, newMatrixPiece: newPiece, action: action, piecesToMove: null, connectingFrom: 'west', pieceX: x, pieceY: y, pieceWidth: width, pieceHeight: height, groupNum: groupNum});
            }
          }
        }                      
      }          

      // CONNECTS EAST
      // ================================================================================================
      
      // if matrixPiece will connect east to the activePieceType
      if (thisPieceConnects.east[activePieceType]) {
        const activePieceRotation = thisPieceConnects.east[activePieceType][0];
        const activePieceTypeWidth = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].width;
        const activePieceTypeHeight = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].height;
        let activePieceConnects = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].connects;

        let newPieceX = x + width;
        let newPieceY = y;

        // Adding to a 180 piece,
        if (rotation === 180) {
          newPieceY = y - (activePieceTypeHeight - height);
        }

        // If we are adding a new 180 piece, the newPieceY needs to be pushed down to line up at the bottom
        if (activePieceRotation === 180) {
          newPieceY = y + (height - activePieceTypeHeight);
        }

        if (!neighborEast) {
          const newPiece = defineMatrixPiece(activePieceType, activePieceRotation, newPieceX, newPieceY, groupNum);
          // Don't add a button if the newPiece overlaps an existing piece
          if (!getOverlappingMatrixPiece(piecesMatrix, newPiece)) {
            newButtonsMatrix.push({ addToPieceType: pieceType, newMatrixPiece: newPiece, action: 'add', piecesToMove: null, connectingFrom: 'east', pieceX: x, pieceY: y, pieceWidth: width, pieceHeight: height, groupNum: groupNum});
          }
        }
        if (neighborEast) {
          if (neighborEast.connects.west[activePieceType] && thisPieceConnects.east[activePieceType]) {
            const commonRotations = commonValues(neighborEast.connects.west[activePieceType], thisPieceConnects.east[activePieceType]);
            if (commonRotations.length > 0) {              
              activePieceConnects = PieceTypesMetaData[activePieceType].rotation[commonRotations[0]].connects;
              const newMatrixPiece = defineMatrixPiece(activePieceType, commonRotations[0], x, y + height, groupNum);
              const piecesToMove = await definePiecesToMove(piecesMatrix, thisPiece, newMatrixPiece, 'east');          
              newButtonsMatrix.push({ addToPieceType: pieceType, newMatrixPiece: defineMatrixPiece(activePieceType, commonRotations[0], newPieceX, newPieceY, groupNum), action: 'insert', piecesToMove: piecesToMove, connectingFrom: 'east', pieceX: x, pieceY: y, pieceWidth: width, pieceHeight: height, groupNum: groupNum});
            }
          }
        }                    
      }


      // CONNECTS NORTH
      // ================================================================================================
      
      // if matrixPiece will connect north to the activePieceType
      if (thisPieceConnects.north[activePieceType]) {
        const activePieceRotation = thisPieceConnects.north[activePieceType][0];            
        const activePieceTypeWidth = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].width;
        const activePieceTypeHeight = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].height;
        let activePieceConnects = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].connects;

        let newPieceX = x
        let newPieceY = y - activePieceTypeHeight;
        
        // Adding to a 90 piece,
        if (rotation === 90) {
          newPieceX = x - (activePieceTypeWidth - width);
        }

        // If we are adding a new 90 piece
        if (activePieceRotation === 90) {
          newPieceX = x + (width - activePieceTypeWidth);
        }


        if (y - activePieceTypeHeight >= 0) {
          if (!neighborNorth) {
            const newPiece = defineMatrixPiece(activePieceType, activePieceRotation, newPieceX, newPieceY, groupNum);
            // Don't add a button if the newPiece overlaps an existing piece              
            if (!getOverlappingMatrixPiece(piecesMatrix, newPiece)) {
              newButtonsMatrix.push({ addToPieceType: pieceType, newMatrixPiece: newPiece, action: 'add', piecesToMove: null, connectingFrom: 'north', pieceX: x, pieceY: y, pieceWidth: width, pieceHeight: height, groupNum: groupNum});
            }
          }
        }                      
      }


      // CONNECTS SOUTH
      // ================================================================================================
      
      // if matrixPiece will connect south to the activePieceType
      if (thisPieceConnects.south[activePieceType]) {
        const activePieceRotation = thisPieceConnects.south[activePieceType][0];            
        let activePieceConnects = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].connects;
        // width and height of the activePiece after rotation defined
        const activePieceTypeWidth = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].width;
        const activePieceTypeHeight = PieceTypesMetaData[activePieceType].rotation[activePieceRotation].height;        

        // default coords of the new piece
        let newPieceX = x;
        let newPieceY = y + height;

        // Adding to a 90 piece,
        if (rotation === 90) {
          newPieceX = x - (activePieceTypeWidth - width);
        }

        // If we are adding a new 90 piece
        if (activePieceRotation === 90) {
          newPieceX = x + (width - activePieceTypeWidth);
        }

        if ( y + activePieceTypeHeight) {
          if (!neighborSouth) {
            // Don't add a button if the newPiece overlaps an existing piece
            const newPiece = defineMatrixPiece(activePieceType, activePieceRotation, newPieceX, newPieceY, groupNum);
            if (!getOverlappingMatrixPiece(piecesMatrix, newPiece)) {
              newButtonsMatrix.push({ addToPieceType: pieceType, newMatrixPiece: newPiece, action: 'add', piecesToMove: null, connectingFrom: 'south', pieceX: x, pieceY: y, pieceWidth: width, pieceHeight: height, groupNum: groupNum});
            }
          }
          if (neighborSouth) {       
            if (neighborSouth.connects.north[activePieceType] && thisPieceConnects.south[activePieceType]) {
              const commonRotations = commonValues(neighborSouth.connects.north[activePieceType], thisPieceConnects.south[activePieceType]);                              
              if (commonRotations.length > 0) {
                const newMatrixPiece = defineMatrixPiece(activePieceType, commonRotations[0], newPieceX, newPieceY, groupNum);
                const piecesToMove = await definePiecesToMove(piecesMatrix, thisPiece, newMatrixPiece, 'south');
                activePieceConnects = PieceTypesMetaData[activePieceType].rotation[commonRotations[0]].connects;
                newButtonsMatrix.push({ addToPieceType: pieceType, newMatrixPiece: newMatrixPiece, action: 'insert', piecesToMove: piecesToMove, connectingFrom: 'south', pieceX: x, pieceY: y, pieceWidth: width, pieceHeight: height, groupNum: groupNum}); 
              }
            }
          }
        }                      
      }               
            
    } // end map through piecesMatrix

  } // if activePieceType
  

  return newButtonsMatrix;

};

export default updateButtonsMatrix;