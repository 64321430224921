import getMatrixPieceById from "./GetMatrixPieceById";
import getNeighbor from "./GetNeighbor";


// Get a piece to move
function getPieceToMove(array, x, y) {
  return array.find(obj => obj.id === `${x}${y}`);
}

// find connected blocks
async function getNeighbors(groupNum, direction, piecesMatrix, origPieceX, origPieceY, origPieceCounter, pieceX, pieceY, moveX, moveY, piecesToMove, testRecursive, recursiveDirection) {


  const neighbor = getNeighbor(direction, piecesMatrix, pieceX, pieceY);     
  if ( !neighbor ) {     
    return; 
  }
  
  if ( getPieceToMove(piecesToMove, neighbor.x, neighbor.y) ) {
    const thisPieceToMove = getPieceToMove(piecesToMove, neighbor.x, neighbor.y);
    // this piece has been added to piecesToMove
    if ( thisPieceToMove.checkedDirections.includes(direction) ) {
      return;
    }
    // if we haven't already checked going this direction, add it
    else {            
      thisPieceToMove.checkedDirections.push(direction);
    }
  }  
  else {    
    // add the current item to the list of piecesToMove    
    piecesToMove.push({
      id: `${neighbor.x}${neighbor.y}`,
      groupNum: groupNum,
      pieceType: neighbor.pieceType,
      x: neighbor.x,
      y: neighbor.y,
      newX: neighbor.x + moveX,
      newY: neighbor.y + moveY,
      rotation: neighbor.rotation,
      checkedDirections: [direction]
    });        
  }  


  // now check the neighbor!
  const neighborX = neighbor.x;
  const neighborY = neighbor.y;
  const newNeighbor = getMatrixPieceById(piecesMatrix, neighborX, neighborY);
  const neighborConnects = newNeighbor?.connects;
  
  const getNeighborsFunctions = [];
  
  if (neighborConnects) {
  
    if (
          neighborConnects.west && 
          direction !== 'east' &&
          getNeighbor('west', piecesMatrix, neighborX, neighborY)
        ) 
      {
      
        
        getNeighborsFunctions.push(
          getNeighbors(groupNum, 'west', piecesMatrix, origPieceX, origPieceY, origPieceCounter, neighborX, neighborY, moveX, moveY, piecesToMove, `  ^_^  ◀◀`, 'west')
        );
      }
      if (
          neighborConnects.east &&
          direction !== 'west' &&
          getNeighbor('east', piecesMatrix, neighborX, neighborY)
        )
      {
      
        getNeighborsFunctions.push(
          getNeighbors(groupNum, 'east', piecesMatrix, origPieceX, origPieceY, origPieceCounter, neighborX, neighborY, moveX, moveY, piecesToMove, `  ^_^    ▶▶`, 'east')
        );
      }
      if (
          neighborConnects.north &&
          direction !== 'south' &&
          getNeighbor('north', piecesMatrix, neighborX, neighborY)
        )
      {
      
        getNeighborsFunctions.push(
          getNeighbors(groupNum, 'north', piecesMatrix, origPieceX, origPieceY, origPieceCounter, neighborX, neighborY, moveX, moveY, piecesToMove, `  ^_^    ▲▲`, 'north')
        );
      }
      if (
          neighborConnects.south &&
          direction !== 'north' &&
          getNeighbor('south', piecesMatrix, neighborX, neighborY))
      {
      
        getNeighborsFunctions.push(
          getNeighbors(groupNum, 'south', piecesMatrix, origPieceX, origPieceY, origPieceCounter, neighborX, neighborY, moveX, moveY, piecesToMove, `  ^_^    ▼▼ `, 'south')
        );
      }

  } // end if neighborConnects


  // find connected blocks in all directions (north, south, east, west) using await and Promises  
  await Promise.all(getNeighborsFunctions);

  // now continue on the main path  
  await getNeighbors(groupNum, direction, piecesMatrix, origPieceX, origPieceY, origPieceCounter, pieceX, pieceY, moveX, moveY, piecesToMove, `^_^' MAIN DIRECTION at bottom`, null);
  
}

/*
  Define the pieces to move by
  - the piecesMatrix
  - the currentPiece
  - the size reference piece 'newPiece' for add
  - the direction you want to check
*/



const definePieceForDelete = async (
  piecesMatrix,
  currentPiece,
  sizeReferencePiece,
  direction
) => {
  let moveX = 0;
  let moveY = 0;
  let limitX = null;
  

  let testRecursive = '===  ▼';
  if (direction === 'south') {
    moveY = sizeReferencePiece.height;
  }
  if (direction === 'north') {
    moveY = -1 * sizeReferencePiece.height;
    testRecursive = '===  ▲';
  }
  if (direction === 'east') {
    moveX = sizeReferencePiece.width;
    testRecursive = '===  ▶';
  }
  if (direction === 'west') {
    moveX = -1 * sizeReferencePiece.width;
    testRecursive = '===  ◀';
  }


  const pieceX = currentPiece.x; 
  const pieceY = currentPiece.y;
  const groupNum = currentPiece.groupNum;
  // array to store the connected pieces
  const piecesToMove = [];
  

  const origPieceX = pieceX;
  const origPieceY = pieceY;
  let origPieceCounter = -1;

  await getNeighbors(groupNum, direction, piecesMatrix, origPieceX, origPieceY, origPieceCounter, pieceX, pieceY, moveX, moveY, piecesToMove, testRecursive, null);

  return piecesToMove;

}


export default definePieceForDelete;