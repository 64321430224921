// import PieceTypes from "../constants/PieceTypesMetaData";
import getMatrixPieceById from "./GetMatrixPieceById";

const getNeighbor = (direction, piecesMatrix, pieceX, pieceY) => {
  // if there is a saved neighbor right away
  const lookingPiece = getMatrixPieceById(piecesMatrix, pieceX, pieceY);
  const storedNeighbor = lookingPiece.neighbor[direction];
  if (storedNeighbor) {
    return storedNeighbor;
  }

  let neighbor = null;

  for (let key in piecesMatrix) {
    const thisPiece = piecesMatrix[key];

    if (direction === 'west') {
      if (
        lookingPiece.coords.NW.x -1 === thisPiece.coords.NE.x && 
        (lookingPiece.coords.SW.y === thisPiece.coords.SE.y || lookingPiece.coords.NW.y === thisPiece.coords.NE.y)
      ) {
        neighbor = thisPiece;
        lookingPiece.neighbor[direction] = neighbor;
        break;
      }
     }
   
     if (direction === 'east') {
      if (
        lookingPiece.coords.NE.x + 1 === thisPiece.coords.NW.x && 
        (lookingPiece.coords.SE.y === thisPiece.coords.SW.y || lookingPiece.coords.NE.y === thisPiece.coords.NW.y)
      ) {
        neighbor = thisPiece;
        lookingPiece.neighbor[direction] = neighbor;
        break;
      }
     }
      
     if (direction === 'north') {
      if (
        lookingPiece.coords.NW.y - 1 === thisPiece.coords.SW.y && 
        (lookingPiece.coords.NW.x === thisPiece.coords.SW.x || lookingPiece.coords.NE.x === thisPiece.coords.SE.x)
      ) {
        neighbor = thisPiece;
        lookingPiece.neighbor[direction] = neighbor;
        break;
      }
     }
   
     if (direction === 'south') {
      if (
        lookingPiece.coords.SW.y + 1 === thisPiece.coords.NW.y &&
        (lookingPiece.coords.SW.x === thisPiece.coords.NW.x || lookingPiece.coords.SE.x === thisPiece.coords.NE.x)
      ) {
        neighbor = thisPiece;
        lookingPiece.neighbor[direction] = neighbor;
        break;
      }
     }    
  }

  return neighbor;
}

export default getNeighbor;