import React, { useRef, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { isBrowser } from 'react-device-detect';
import { useDrag, useDrop, useDragLayer, DragPreviewImage } from 'react-dnd';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { TransformWrapper, TransformComponent, useTransformContext } from 'react-zoom-pan-pinch';
import PieceTypesMetaData from '../constants/PieceTypesMetaData';
import { CONFIGURATOR_GRID_PAD } from '../../constants';
import formatCurrency from '../helpers/FormatCurrency';
import configurationToPiecesMatrix from '../helpers/ConfigurationToPiecesMatrix';
import getOverlappingMatrixPiece from '../helpers/GetOverlappingMatrixPiece';
import getMatrixPieceById from '../helpers/GetMatrixPieceById';
import addPieceToMatrixAndShift from '../helpers/AddPieceToMatrixAndShift';
import addPieceToMatrixBasic from '../helpers/AddPieceToMatrixBasic';
import rotatePiece from '../helpers/RotatePiece';
import removePieceFromMatrixAndShift from '../helpers/RemovePieceFromMatrixAndShift';
import updateButtonsMatrix from '../helpers/UpdateButtonsMatrix';
import updateConfigurationMeta from '../helpers/UpdateConfigurationMeta';
import { CirclePlus, TechGlyph, ReclinerGlyph } from './Icons';
import * as Button from './Button';
import IconButton from './IconButton';
import Container from './Container';
import EmailDesign from './EmailDesign';
import PurchaseDesign from './PurchaseDesign';
import ActivePieceTypePreview from './ActivePieceTypePreview';
import ColorMenu from './ColorMenu';
import defineMatrixPiece from '../helpers/DefineMatrixPiece';
import { useAppContext } from '../context/app';
import CloudinaryImage from './CloudinaryImage';
import { cloudinaryImageSrc } from '../helpers/CloudinaryHelpers';
import ViewerModal from './ViewerModal';
import PriceTableRow from './PriceTableRow';

// Layout
// ====================================================================================

const ACTIVE_ON_SPACING_STYLES = `
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  width: calc(100% - 0.5rem);
  height: calc(100% - 0.5rem);
`;

const ConfiguratorWrapGrid = styled.div`
  margin-bottom: 1.875rem;
  padding: 0;
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  @media (min-width: 50em) {
    width: calc(100vw - 2rem);
    padding: 0 1rem;
    gap: 1rem;
    width: 100%;
    //grid-template-columns: 66.666% 33.333%;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "title title"
      "pieces pieces"
      "configurator ${(props) => props.activePieceType ? 'preview' : 'sidebar'}"
      "configurator sidebar"
    ;
  }
`;

// CONFIGURATION MENU
// ====================================================================================

const ConfigurationMenuTitle = styled.h1`
  max-width: 20em;
  text-align: center;
  font: var(--body-medium);
  font-size: 1.53125rem;
  margin: 0.875rem auto;
  b {
    font: var(--body-medium-bold);
    font-size: inherit;
  }
  span {
    display: none;
  }
  @media (min-width: 50em) {
    max-width: 100%;
    margin: 4% 0 0;
    grid-area: title;
    span {
      display: inline;
    }
  }
`;
const ConfigurationMenuWrapper = styled.ul`
  margin: 0.625rem 0 0;
  padding: 0 1rem 0.75rem;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(10, 5rem);
  grid-auto-columns: 5rem;
  grid-template-rows: 5rem;
  gap: 0.625rem;
  user-select: none;
  overflow-x: scroll;
  width: 100vw;
  &:before, &:after {
    content: '';
    display: block;
    width: 2rem;
    height: 5.5rem;
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }
  &:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
  @media (min-width: 50em) {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    width: 100%;
    grid-area: pieces;
    &:before, &:after {
      display: none;
    }
  }
  li {
    width: 5rem;
    position: realtive;
    svg {
      position: absolute;
      bottom: 5%;
      left: 5%;
      width: 1.375rem;
      height: 1.375rem;
    }
    @media (min-width: 50em) {
      width: calc(10% - 0.625rem);
    }
  }
`;

const ConfigurationMenuOptionControl = styled.a`
  overflow: hidden;
  text-indent: 999vw;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  aspect-ratio: 1/1;
  border: 2px solid transparent;
  background: var(--offwhite);
  color: black;
  display: block;
  &:focus-visible {
    outline: none;
    border-color: var(--electric-lime);
  }
  &[aria-pressed='true'] {
    border-color: var(--electric-lime);
  }
  img {
    position: absolute;
    transition: all 0.15s;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
  }
`;

// returns a <a>
const ConfigurationMenuOption = ({
  pieceType,
  isActive,
  handleConfigurationMenuOptionClick,
  pieceTypeSetter,
}) => {
  const { activeColor, apiProducts } = useAppContext();

  console.log('∆194', `apiProducts[${activeColor}][${pieceType}]?.availability`);

  const isAvailable = apiProducts[activeColor][pieceType]?.availability  === 'Available' ? true : false;
  // collected is extraProps
  const [collected, drag, preview] = useDrag(() => ({
    type: 'draggablePieceType',
    item: { pieceType: pieceType, pieceTypeSetterFunc: pieceTypeSetter },
    isDragging(monitor) {
      monitor.getItem().pieceTypeSetterFunc(monitor.getItem().pieceType);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const scrollToCenter = (piece) => {
    if(window.innerWidth < 800) {
      document.querySelector(`.${piece}[aria-label="${piece}"]`).scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      });
    }
  };

  const opacity = collected.isDragging ? 0 : 1;
  const previewImageWidth = 40;

  console.log('∆221', `PieceTypesMetaData[${pieceType}].rotation`);

  const previewImageHeight = Math.floor(( PieceTypesMetaData[pieceType].rotation[0].height / PieceTypesMetaData[pieceType].rotation[0].width ) * previewImageWidth);
  const previewImageSrc = cloudinaryImageSrc(`${activeColor}/topdown/${pieceType}`, 'webp', 75, previewImageWidth, previewImageHeight);
  // console.log('previewImageSrc', '****');
  // console.log('previewImageSrc', '**** width', previewImageWidth);
  // console.log('previewImageSrc', '**** height', previewImageHeight);
  // console.log('previewImageSrc', `**** ${activeColor}/topdown/${pieceType}`, previewImageSrc);

  return (
    <>
       <DragPreviewImage 
        connect={preview}
        src={previewImageSrc}
      />
      <ConfigurationMenuOptionControl
         role="Handle"
         // if isActive or isBrowser, then drag, else null
         ref={isActive || isBrowser ? drag : null}
         className={pieceType}
         aria-pressed={isActive}
         aria-label={pieceType}
         onClick={() => { handleConfigurationMenuOptionClick(pieceType); scrollToCenter(pieceType);} }
         style={{ opacity }}
         data-is-available={isAvailable}       
      >
        <CloudinaryImage
          imageId={`${activeColor}/three-quarters/${pieceType}`}
          width={280}
          height={280}
          alt={pieceType}
        />
        {pieceType === 'consoleTech' && <TechGlyph />}
        {pieceType === 'armlessRecliner' && <ReclinerGlyph />}
        {pieceType === 'chaiseLeft' && <ReclinerGlyph />}
        {pieceType === 'chaiseRight' && <ReclinerGlyph />}
        {pieceType === 'reclinerLeft' && <ReclinerGlyph />}
        {pieceType === 'reclinerRight' && <ReclinerGlyph />}
      </ConfigurationMenuOptionControl>
    </>
  );
};


// CONFIGURATION MENU PREVIEW FOR HTML
// ====================================================================================

const ConfigurationMenuOptionHTMLPreview = styled.div`
  z-index: 9999999;
  width: 140px;
  height: 140px;
  position: relative;
  cursor: grabbing;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(0.35);
  }  
`;

function CustomDragLayer({ activePieceType }) {
  const { activeColor } = useAppContext();
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));  

  if (!isDragging) {
    return null;
  }

  function renderItem() {
    // define piecetype metadata
    const {width, height} = PieceTypesMetaData[activePieceType].rotation[0];
    console.log('customdraglayer', 140*(height/width), 'width', width, 'height', height);
    switch (itemType) {
      case 'draggablePieceType':
        return (
          <ConfigurationMenuOptionHTMLPreview item={item} className={activePieceType}>
            <CloudinaryImage          
              imageId={`${activeColor}/topdown/${activePieceType}`}
              width={140}
              height={Math.ceil(140*(height/width))}
              alt=""
            />
          </ConfigurationMenuOptionHTMLPreview>
        );
      default:
        return null;
    }
  }

  const transform = initialOffset && currentOffset ? `translate(${currentOffset.x}px, ${currentOffset.y}px)` : '';

  return (
    <div style={{ position: 'fixed', pointerEvents: 'none', zIndex: 100, left: 0, top: 0, width: '100%', height: '100%' }}>
      <div style={{ transform }}>{renderItem()}</div>
    </div>
  );
}

// CONFIGURATION PIECE
// ====================================================================================

// Lays out the pieces
const GridPieceWrapper = styled.li`
  pointer-events: auto;
  position: relative;
  display: block;
  grid-column: ${(props) => props.x + 1} / span ${(props) => props.width};
  grid-row: ${(props) => props.y + 1} / span ${(props) => props.height};
  // overflow: hidden;
  transition: opacity 0.125s linear,
    transform 0.15s cubic-bezier(0.39, 1.35, 0.45, 1.36);
  opacity: 0;
  transform: scale(0.4);
  transform: scale(1);
  background: var(--offwhite);
  &[data-rendered='true'] {
    opacity: 1;
    transform: scale(1);
  }
  // &[data-group-num='0'] { background: grey; border: 2px solid grey; }
  // &[data-group-num='1'] { background: yellow; border: 2px solid yellow; }
  // &[data-group-num='2'] { background: orange; border: 2px solid orange; }
  // &[data-group-num='3'] { background: red; border: 2px solid red; }
  // &[data-group-num='4'] { background: purple; border: 2px solid purple; }
  // &[data-group-num='5'] { background: black; border: 2px solid black; }
  // &[data-group-num='6'] { background: lightblue border: 2px solid lightblue; }
  // &[data-group-num='7'] { background: lightgreen; border: 2px solid lightgreen; }
`;

// The button control in the piece wrapper
// this has the visual distinction
const GridPiece = styled.div`  
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  appearance: none;
  transition: all 0.2s;
  background: var(--offwhite);
  &[data-highlighted='on-disabled'] {
    background: lightblue;
  }
  img {
    transition: opacity 0.125s linear,
      top 0.15s cubic-bezier(0.39, 1.35, 0.45, 1.36),
      bottom 0.15s cubic-bezier(0.39, 1.35, 0.45, 1.36),
      left 0.15s cubic-bezier(0.39, 1.35, 0.45, 1.36),
      right 0.15s cubic-bezier(0.39, 1.35, 0.45, 1.36),
      width 0.15s cubic-bezier(0.39, 1.35, 0.45, 1.36),
      height 0.15s cubic-bezier(0.39, 1.35, 0.45, 1.36);
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &[data-rotation='45'] img {
    transform: scale(1, 1) rotate(45deg);
  }
  &[data-rotation='135'] img {
    transform: scale(-1,-1) rotate(-45deg)
  }
  &[data-rotation='180'] img {
    transform: scale(-1, -1);
  }
  &[data-rotation='225'] img {
    transform: scale(-1,-1) rotate(45deg)
  }
  &[data-rotation='270'] img {
    transform: scale(-1, -1);
  }
  &[data-rotation='315'] img {
    transform: scale(1,1) rotate(-45deg)
  }
  &[data-adding-active='on'] img {
    ${ACTIVE_ON_SPACING_STYLES}
  }
  &.swivelChair {
    &[data-rotation='90'] img {
      transform: scale(-1, -1) rotate(270deg);
    }
    &[data-rotation='270'] img {
      transform: scale(-1, -1) rotate(90deg);
    }
  }
`;

const GridPieceActions = styled.div`
  position: absolute;
  opacity: 1;
  transition: opacity 0.25s;
  background: rgba(255, 255, 255, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.15s;
  button {
    transform: translate(-9999rem, -9999rem);
    pointer-events: none;
    opacity: 0;
    transition: transform 0s .125s ease, opacity 0.25s ease;
  }
  &[data-adding-active='on'] {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &[data-delete-highlighted='on'],
  &:hover {
    background: rgba(255, 255, 255, 0.33);
    button {
      transform: translate(0, 0);
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

const GridPieceActionsButtons = styled.div`
  transition: opacity 0.25s;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;    
  button {
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: 0 !important;
    div {
      width: 1.5rem !important;
      height: 1.5rem !important;
      padding: 0.25rem !important;
    }
    @media (min-width: 50em) {
      width: 2rem !important;
      height: 2rem !important;
      div {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }
  button + button {
    margin: 0 0 0 0.15rem;
  }
  &[data-is-vertical='on'] {
    flex-direction: column;
    button + button {
      margin: 0.15rem 0 0 0;
    }
  }
`;

const ConfigurationPiece = ({
  piece,
  activePieceType,
  highlightedPieceType,
  numGridColumns,
  numGridRows,
  deleteHandler,
  rotateHandler,
  highlighted
}) => {
  const { activeColor } = useAppContext();
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, [setRendered]);
  const pieceTypeMetaData = PieceTypesMetaData[piece.pieceType];

  let imageRotationDirectory = '';
  if (piece.rotation === 90 && piece.pieceType !== 'swivelChair') {
    imageRotationDirectory = 'r/';
  }
  if (piece.rotation === 270 && piece.pieceType !== 'swivelChair') {
    imageRotationDirectory = 'r/';
  }
  const isSolo = !piece.neighbor.east && !piece.neighbor.west && !piece.neighbor.north && !piece.neighbor.south;
  console.log('isSolo', piece.pieceType, piece.x, piece.y, isSolo, piece.neighbor);
  return (
    <GridPieceWrapper
      data-rendered={rendered}
      data-group-num={piece.groupNum}
      className={piece.pieceType}
      x={piece.x}
      y={piece.y}
      width={piece.width}
      height={piece.height}
      numGridColumns={numGridColumns}
      data-rotation={piece.rotation}
      data-adding-active={activePieceType ? 'on' : null}
    >
      <GridPiece
        className={piece.pieceType}
        width={piece.width}
        height={piece.height}
        numGridColumns={numGridColumns}
        data-adding-active={activePieceType ? 'on' : null}
        data-highlighted={piece.highlighted ? 'on' : null}
        data-rotation={piece.rotation}
      >
        <CloudinaryImage
          data-rotation={piece.rotation}
          imageId={`${activeColor}/topdown/${imageRotationDirectory}${piece.pieceType}`}
          width={piece.width * 10}
          height={piece.height * 10}
          alt=""
        />
      </GridPiece>
      <GridPieceActions
        data-adding-active={activePieceType ? 'on' : null}
        data-delete-highlighted={
          piece.pieceType === highlightedPieceType ? 'on' : ''
        }
        >
        <GridPieceActionsButtons
          data-is-vertical={piece.height > piece.width ? 'on' : null}
          className={piece.pieceType}
          data-rotation={piece.rotation}
        >
          <IconButton
            type="Trash"
            style="rounded"
            ariaLabel={`delete ${piece.pieceType}`}
            clickHandler={() =>
              deleteHandler(piece.x, piece.y)
            }
          />
          {isSolo && piece.pieceType !== 'ottoman' && (
            <IconButton
              type="Rotate"
              style="rounded"
              ariaLabel={`rotate ${piece.pieceType}`}
              clickHandler={() =>
                rotateHandler(piece.x, piece.y, piece.rotation, piece.groupNum)
              }
            />
          )}
        </GridPieceActionsButtons>
      </GridPieceActions>
    </GridPieceWrapper>
  );
};

// CONFIGURATOR Add Buttons
// ====================================================================================

const ConfigurationPieceAddButtonWrapper = styled.li`
  position: relative;
  display: block;
  grid-column: ${(props) => props.pieceX + 1} / span ${(props) =>
  props.pieceWidth + 1}};
  grid-row: ${(props) => props.pieceY + 1} / span ${(props) =>
  props.pieceHeight + 1}};
  &[data-add-to-zero='on'] {
    margin: 12%;
    background: var(--medium-gray);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%230050B5' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      transform: scale(2);
    }
  }
`;

const ConfigurationZeroMessage = styled.li`
  list-style-type: none;
  margin: 10%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @media (min-width: 50em) {
    flex-direction: column;
    margin: 20%;
  }
  img {
    width: 100%;
    height: auto;
    transform: rotate(180deg);
    transform-origin: center;
    margin-top: 1rem;
    @media (min-width: 50em) {
      transform: rotate(0deg);
      margin: 0;
    }
  }
`;
const ConfigurationZeroMessageContent = styled.div`
  background-color: var(--white);
  background-image: url('/images/M1BackgroundLight.svg');
  text-align: center;
  margin: 8% 0 0 0;
  padding: 8% 2%;
  @media (min-width: 50em) {
    padding: 8% 4%;
  }
  h2 {
    margin: 0 0 .5em 0;
    font: var(--h-small-regular);
    text-transform: uppercase;
    color: var(--rtg-blue);
    b {
      font: var(--h-small-extra-bold);
    }
  }
  p {
    margin: 0;
   color: var(--dark-tyrian-blue);
   font: var(--h-large);
   font-size: 1.5rem;
   @media (min-width: 50em) {
     font-size: 1.85rem;
   }
   b {
    font: var(--h-large-extra-bold);
    display: inline-block;
    margin-left: .1875em;
    font-size: 1.5rem;
    @media (min-width: 50em) {
      font-size: 1.85rem;
    }
    &.above {
      display: none;
      @media (min-width: 50em) {
        display: inline-block;
      }
    }
    &.below {
      display: inline-block;
      @media (min-width: 50em) {
        display: none;
      }
    }
   }
  }
`;

const ConfigurationPieceAddButtonControl = styled.button`
  position: absolute;
  width: 2rem;
  height: 2rem;
  &[data-size='large'] {}
  &[data-size='medium'] {
  }
  &[data-size='small'] {
    width: 1.5rem;
    height: 1.5rem;
  }
  &[data-size='xsmall'] {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  border-radius: 50%;
  border: none;
  padding: 0;
  background: none;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.15);
  transition: opacity 0.5s;
  opacity: 0;
  &[data-rendered='on'] {
    opacity: 1;
  }
  &[data-connects='north'] {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  &[data-connects='south'] {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &[data-connects='east'] {
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
  }
  &[data-connects='west'] {
    top: 50%;
    right: 100%;
    transform: translate(50%, -50%);    
  }
  &[data-action='insert'] {
    &[data-connects='east'] {
      margin-left: -${(props) => props.configuratorWrapperWidth / props.numGridColumns}px;
    }
    &[data-connects='south'] {
      margin-top: -${(props) => props.configuratorWrapperWidth / props.numGridColumns}px;
    }
  }

  &.isOver,
  &:hover,
  &:focus-visible {
    svg {
      transition: transform .15s;
      transform: scale(1.25);
      height: 100%;
      width: 100%;
      aspect-ratio: 1/1;
      --background-color: var(--dark-tyrian-blue);
      --border-color: var(--rtg-blue);
      --glyph-color: white;
    }
  }
  &.isOver {
    svg {
      transform: scale(2.5);
    }
  }
  &[data-testing='on'] {
    background: white;
    svg {
      display: none;
    }
    &[data-connects='north'] {
      &::before {
        content: 'N';
      }
    }
    &[data-connects='south'] {
      &::before {
        content: 'S';
      }
    }
    &[data-connects='east'] {
      &::before {
        content: 'E';
      }
    }
    &[data-connects='west'] {
      &::before {
        content: 'W';
      }
    }
  }
`;

const ConfigurationPieceAddButton = ({
  dropHandler,
  clickHandler,
  newMatrixPiece,
  action,
  piecesToMove,
  connectingFrom,
  pieceX,
  pieceY,
  pieceWidth,
  pieceHeight,
  numGridColumns,
  configuratorWrapperWidth,
  addToZero,
  addToPieceType,
  groupNum
}) => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, [setRendered]);

  const testing = false;

  let buttonSize = '';
  if (numGridColumns > 0) { buttonSize = 'large'; }
  if (numGridColumns > 200) { buttonSize = 'medium'; }
  if (numGridColumns > 300) { buttonSize = 'small'; }
  if (numGridColumns > 500) { buttonSize = 'xsmall'; }

  const { x, y, connects, rotation } = newMatrixPiece;
  const [{ canDrop, isOver, newMatrixPieceCollected }, dropRef] = useDrop(
    {
      accept: 'draggablePieceType',
      drop: (item, monitor) => {
        dropHandler(newMatrixPiece, action, piecesToMove, connectingFrom, groupNum);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [newMatrixPiece, action, piecesToMove, connectingFrom]
  ); // pass the dependencies as the second argument

  return (
    <ConfigurationPieceAddButtonWrapper
      pieceX={pieceX}
      pieceY={pieceY}
      pieceWidth={pieceWidth}
      pieceHeight={pieceHeight}
      data-add-to-zero={addToZero ? 'on' : ''}
    >
      <ConfigurationPieceAddButtonControl
        ref={dropRef}
        className={isOver ? 'isOver' : ''}
        data-rendered={rendered ? 'on' : ''}
        data-testing={testing ? 'on' : ''}
        data-add-to-zero={addToZero ? 'on' : ''}
        data-connects={connectingFrom}
        data-will-add-to-x={x}
        data-will-add-to-y={y}
        data-rotation={rotation}
        data-action={action}
        data-size={buttonSize}
        data-add-to-piece-type={addToPieceType}
        numGridColumns={numGridColumns}
        configuratorWrapperWidth={configuratorWrapperWidth}
        onClick={() =>
          clickHandler(newMatrixPiece, action, piecesToMove, connectingFrom, groupNum)
        }
        aria-label={`Add ${newMatrixPiece.pieceType}`}
      >
        {testing && ` ${buttonSize}`}
        <CirclePlus />
      </ConfigurationPieceAddButtonControl>
    </ConfigurationPieceAddButtonWrapper>
  );
};

// CONFIGURATOR (bringing it all together)
// ====================================================================================

// Wraps the Configurator Grid and Buttons Together
/* aspect-ratio: ${props => props.numGridColumns} / ${props => props.numGridRows}; */
// Wraps the Configurator Grid and Buttons Together
const ConfiguratorWrapper = styled.div`
  z-index: 100;
  position: relative;
  // margin: 0 -1rem;
  // width: calc(100% + 2rem);
  // max-width: 100vw;
  background: var(--offwhite);
  aspect-ratio: 1/1;

  @media (min-width: 50em) {
    width: 100%;
    margin: 0;
    grid-area: configurator;
    align-self: start;
  }
`;

const ConfiguratorToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  background-color: var(--dark-tyrian-blue);
  min-height: 3.125rem;
  @media (min-width: 50em) {
    display: block;
    padding: 0;
  }
`;

const ConfiguratorZoomControls = styled.div`
  
  display: flex;
  gap: 0.0725rem;
  button {
    text-align: center;
    display: block;
    width: 2rem;
    height: 2rem;
    &[aria-pressed="true"], &:hover, &:focus-visible {
      --icon-background-color: var(--rtg-blue);
    }
  }
  @media (min-width: 50em) {
    position: absolute;
    z-index: 120;
    top: 0.5rem;
    right: 0.625rem;
  }
`;

const Configurator3Dbutton = styled.div`
  display: inline-block;
  margin-left: auto;
  margin-right: .375rem;
  @media (min-width: 50em) {
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  button {
    height: 1.8125rem;
    min-height: 1.8125rem;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    box-shadow: 0 0.125rem 0.1875rem rgba(#000000, 0.05);
    border-radius: 0;
    @media (min-width: 50em) {
      height: 2rem;
    }
    &:hover,
    &:focus-visible {
      color: var(--white);
      --glyph-color: var(--white);
      background-color: var(--rtg-blue);
    }
    span.hideMobile {
      display: none;
      @media (min-width: 50em) {
        display: inline;
      }
    }
  }
`;

const ConfiguratorEditControls = styled.div`
  
  button {
    text-align: center;
    display: block;
    height: 2rem;
    span {
      color: var(--white);
    }
    div {
      transform: scale(0.90625);
    }
    @media (min-width: 50em) {
      div {
        transform: scale(1);
      }
    }
  }
  @media (min-width: 50em) {
    position: absolute;
    z-index: 120;
    top: 0.5rem;
    left: 0.625rem;
  }
`;

const ConfiguratorLayers = styled.div`
  z-index: 100;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  background: var(--offwhite);
  aspect-ratio: ${(props) => props.numGridColumns} /
    ${(props) => props.numGridRows};
`;

const ConfiguratorGrid = styled.ul`
  z-index: 100;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(${(props) => props.numGridColumns}, 1fr);
  grid-template-rows: repeat(${(props) => props.numGridRows}, 1fr);
  transition: all 0.2s;
  pointer-events: none;
`;

const ConfigurationDimensions = styled(ConfiguratorGrid)`
  z-index: 105;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
  &[data-shown='on'] {
    opacity: 1;
  }
`;

const ConfiguratorDimensionsControls = styled.div`
  
  @media (min-width: 50em) {
    z-index: 110;
    position: absolute;
    bottom: 0.625rem;
    left: 0.625rem;
  }
`;
const ConfiguratorDimensionsControl = styled.button`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  span {
    color: var(--white);
    font: var(--button);
    text-transform: uppercase;
  }
  div {
    position: relative;
    width: 3.25rem;
    height: 1.75rem;
    border-radius: 6.25rem;
    background: var(--white);
    box-shadow: box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--medium-dark-gray);
    padding: 0.125rem;
    display: flex;
    justify-content: flex-start;
    transition: all .15s;
    &::after {
      content: ''; 
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      display: block;  
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 50%;
      background: var(--gray);
      transition: left .15s ease, background-color .15s ease;
    }    
  }
  &:focus-visible,
  &:hover {
    div {
      //border-color: var(--electric-lime);
    }
  }
  &[aria-pressed='true'] {
    div::after {
      left: 1.625rem;
      background: var(--dark-tyrian-blue);
    }
  }
`;
const ConfiguratorDimensionsControlIndicator = styled.div``;

const ConfigurationDimensionHorizontal = styled.li`
  list-style-type: none;
  position: relative;
  grid-column: ${(props) => props.startX} / ${(props) => props.endX};
  grid-row: ${(props) => props.startY} / ${(props) => props.endY};
  &::before,
  &::after {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &::before {
    left: 0;
    border-width: 0.1875rem 0 0.1875rem 0.375rem;
    border-color: transparent transparent transparent var(--medium-dark-gray);
  }
  &::after {
    right: 0;
    border-width: 0.1875rem 0.375rem 0.1875rem 0;
    border-color: transparent var(--medium-dark-gray) transparent transparent;
  }
  div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background: var(--medium-dark-gray);
    height: 1px;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.66);
      display: inline-block;
      padding: 0 1rem;
      color: var(--dark-gray);
      font: var(--body-small);
      background: var(--offwhite);
    }
  }
`;
const ConfigurationDimensionVertical = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: ${(props) => props.startX} / ${(props) => props.endX};
  grid-row: ${(props) => props.startY} / ${(props) => props.endY};
  &::before,
  &::after {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &::before {
    top: 0;
    border-width: 0.375rem 0.1875rem 0 0.1875rem;
    border-color: var(--medium-dark-gray) transparent transparent transparent;
  }
  &::after {
    bottom: 0;
    border-width: 0 0.1875rem 0.375rem 0.1875rem;
    border-color: transparent transparent var(--medium-dark-gray) transparent;
  }
  div {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-right: 1px solid var(--medium-dark-gray);
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.66);
      display: inline-block;
      padding: 1rem 0;
      color: var(--dark-gray);
      font: var(--body-small);
      background: var(--offwhite);
    }
  }
`;

const ConfigurationPieceButtons = styled(ConfiguratorGrid)`
  pointer-events: none;
  z-index: 110;
  button {
    pointer-events: auto;
  }
`;

const BuildYourOwnCTA = styled.div`
  margin: 1.25rem 0;
  text-align: center;
  width: 100%;
  padding: 0 1rem;
  a, button {
    margin: 0 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    span {
      display: none;
    }
  }
  @media (min-width: 50em) {
    grid-area: configurator;
    padding-top: calc(100% + 3.125rem);
    margin-bottom: auto;
    
  }  
`;

const ConfiguratorAside = styled.div`
  h2 {
    color: var(--rtg-dark-blue);
    text-transform: uppercase;
    font: var(--subheading);
    margin: 0;
    padding-bottom: 0.25rem;
    &.alt {
      color: var(--dark-tyrian-blue);
    }
  }
  p {
    margin: 0 0 1em;
    &.alt {
      color: var(--dark-tyrian-blue);
    }
  }
  padding: 0 1em;
  @media (min-width: 50em) {
    grid-area: sidebar;
    padding: 0;
  }
`;

const ConfiguratorAsideSeparator = styled.div`
  margin: 1rem 0 1.25rem;
  border-top: 1px solid var(--lighter-gray);
`;

const TotalPriceTableWrapper = styled.table`
  width: 100%;
`;
const TotalPriceTableFooter = styled.div`
  display: flex;
  gap: 22%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--lighter-gray);
  margin: 1.25rem 0 -0.375rem;
  padding: 0.725rem 0 0;
  @media (min-width: 50em) {
    margin: 1.25rem 0 0;
    padding: 0.725rem 0 0.5rem;
  }
`;
const TotalPriceTableFooterLabel = styled.span`
  font: var(--subheading);
  color: var(--rtg-dark-blue);
  text-transform: uppercase;
`;
const TotalPriceTableFooterPrice = styled.span`
  font: var(--h-large);
  color: var(--dark-tyrian-blue);
`;

const TotalPriceTable = ({
  piecesList,
  highlightedPieceType,
  clickHandler,
  totalPrice,
}) => {

  if (totalPrice === 0) {
    return (
      <TotalPriceTableWrapper>
        <tbody>
          <tr>
            <td className="icon">
              <div />
            </td>
            <td className="name">
              <div />
            </td>
            <td className="price">
              <div />
            </td>
          </tr>
        </tbody>
      </TotalPriceTableWrapper>
    );
  }
  if (piecesList) {
    return (
      <TotalPriceTableWrapper>
        <tbody>
          {Object.keys(piecesList).map((key, index) => {
            if (piecesList[key].quantity > 0) {
              return (
                <PriceTableRow 
                  key={`price-table-row-${key}`}
                  name={piecesList[key].name}
                  pieceType={piecesList[key].pieceType} 
                  quantity={piecesList[key].quantity} 
                  price={piecesList[key].price} 
                  available={piecesList[key].available}
                  totalCost={piecesList[key].totalCost}
                  index={index}
                />
              )
            }
          })}
        </tbody>
      </TotalPriceTableWrapper>
    );
  }
};

const SoloDropZoneArea = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const SoloDropZoneAreaInner = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const SoloDropZone = ({
  zoomProps,
  dropHandler,
  activePieceType,
  hoverHandler,
  numGridColumns,
  numGridRows,
  configuratorSize,
  piecesMatrix
}) => {

  const { scale, positionX, positionY} = useTransformContext().state;

  console.log('solodropzone', 'zoomProps', scale, positionX, positionY);
  // ∆∆∆ Define width of configurator here
  console.log('solodropindicator SoloDropZone', 'ConfigSize', configuratorSize);
  const CONFIGURATOR_SIZE = configuratorSize;
  console.log('solodropindicator SoloDropZone', 'CONFIGURATOR_SIZE', CONFIGURATOR_SIZE);

  const innerRef = useRef(null);
  const {width, height} = PieceTypesMetaData[activePieceType].rotation[0];

    const [{ canDrop, isOver }, dropRef] = useDrop(
      {
        accept: 'draggablePieceType',
        hover: (item, monitor) => {
          const clientOffset = monitor.getClientOffset();
          const { left, top } = innerRef.current.getBoundingClientRect();
          const mouseX = clientOffset.x - left;
          const mouseY = clientOffset.y - top;
          const gridX = Math.floor((mouseX * numGridColumns) / (CONFIGURATOR_SIZE*scale)) - Math.floor(width / 2);
          const gridY = Math.floor((mouseY * numGridRows) / (CONFIGURATOR_SIZE*scale)) - Math.floor(height / 2);          
          hoverHandler(activePieceType, gridX, gridY);
        },

      drop: (item, monitor) => {
        const clientOffset = monitor.getClientOffset();          
        const { left, top } = innerRef.current.getBoundingClientRect();
        const mouseX = clientOffset.x - left;
        const mouseY = clientOffset.y - top;
        const gridX = Math.floor((mouseX * numGridColumns) / (CONFIGURATOR_SIZE*scale)) - Math.floor(width / 2);
        const gridY = Math.floor((mouseY * numGridRows) / (CONFIGURATOR_SIZE*scale)) - Math.floor(height / 2);
        if (gridX > -1 && gridY > -1) {
          dropHandler(activePieceType, gridX, gridY);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [innerRef, piecesMatrix, numGridColumns, numGridRows, activePieceType, width, height, positionX, positionY]
  ); // pass the dependencies as the second argument  

  return (
    <SoloDropZoneArea
      ref={dropRef}
      className={isOver ? 'isOver' : ''}      
    >
      <SoloDropZoneAreaInner ref={innerRef} 
        numGridColumns={numGridColumns}
        numGridRows={numGridRows}
      />
    </SoloDropZoneArea>
  );
};

const SoloDropIndicatorGrid = styled(ConfiguratorGrid)``;

const SoloDropIndicatorBox = styled.li`
  position: relative;
  pointer-events: none;
  grid-column: ${props => props.left} / span ${props => props.width};
  grid-row: ${props => props.top} / span ${props => props.height};  
  display: ${props => props.display}; 
`;
const SoloDropIndicatorBoxInner = styled.div`
  position: absolute;
  ${ACTIVE_ON_SPACING_STYLES}
  border: 2px dashed lightgreen;
  background: white;
`;


const SoloDropIndicator = ({
  settings,
  activePieceType,
  numGridColumns,
  numGridRows
}) => {
  console.log('solodropindicator', 'settings', settings);
  const {width, height} = PieceTypesMetaData[activePieceType].rotation[0];
  return (
    <SoloDropIndicatorGrid
      numGridColumns={numGridColumns}
      numGridRows={numGridRows}
    >
      <SoloDropIndicatorBox 
        top={settings.y}
        left={settings.x}
        width={width}
        height={height}
        display={settings.display ? 'block' : 'none'}
      >
        <SoloDropIndicatorBoxInner />
      </SoloDropIndicatorBox>
    </SoloDropIndicatorGrid>
  )
}

const Configurator = ({ startingConfiguration }) => {
  const { activeColor, apiProducts, sessionId } = useAppContext();
  const configuratorWrapperRef = useRef(null);
  const transformComponentRef = useRef(null);

  // Define the starting enabledPieces (all of em)
  const STARTING_ENABLED_PIECE_TYPES = [
    'corner',
    'armless',
    'armlessRecliner',
    'reclinerLeft',
    'reclinerRight',
    'chaiseLeft',
    'chaiseRight',
    'console',
    'consoleTech',
  ];

  // save group number in a state var
  const [groupNum, setGroupNum] = useState(1);

  // save email design in a state var
  const [emailDesignActive, setEmailDesignActive] = useState(null);

  const [purchaseDesignActive, setPurchaseDesignActive] = useState(null);

  // save showDimensions in a state var
  const [showDimensions, setShowDimensions] = useState(false);

  const [configuratorWrapperHeight, setConfiguratorWrapperHeight] =
    useState(null);
  const [configuratorWrapperWidth, setConfiguratorWrapperWidth] =
    useState(null);

  // save enabledPieceTypes array in a state var
  const [enabledPieceTypes, setEnabledPieceTypes] = useState(
    STARTING_ENABLED_PIECE_TYPES
  );

  // save activePieceType in a state var
  const [activePieceType, setActivePieceType] = useState(null);

  // save highlightedPieceType array in a state var
  const [highlightedPieceType, setHighlightedPieceType] = useState(null);

  // save  in a state var
  const [configurationMeta, setConfigurationMeta] = useState({});

  // define piecesMatrix as state var
  const [piecesMatrix, setPiecesMatrix] = useState({});

  // buttons matrix state var
  const [buttonsMatrix, setButtonsMatrix] = useState([]);

  // solo drop indicator
  const [soloDropIndicator, setSoloDropIndicator] = useState({
    x: 0,
    y: 0, 
    display: false
  });

  const handleTransform = useCallback(() => {
    console.log('handleTransform');
  }, []);
    

  // on 'First Load'
  // set config wrapper height & width and set piecesMatrix from configuration
  useEffect(() => {
    console.log('$$$', 'initial useEffect', 'piecesMatrix:', piecesMatrix);
    setPiecesMatrix(configurationToPiecesMatrix(startingConfiguration));
    setConfiguratorWrapperHeight(configuratorWrapperRef.current.clientHeight);
    setConfiguratorWrapperWidth(configuratorWrapperRef.current.clientWidth);    
  }, []);

  // update buttons matrix when necessary
  useEffect(() => {
    console.log('$$$', 'update buttons matrix', 'piecesMatrix:', piecesMatrix);
    async function updateMatrix() {
      console.log('updatingButtonsMatrix');
      const updatedButtonsMatrix = await updateButtonsMatrix(
        piecesMatrix,
        activePieceType,
        configurationMeta.numGridColumns,
        configurationMeta.numGridRows,
        setButtonsMatrix
      );
      setButtonsMatrix(updatedButtonsMatrix);
    }
    updateMatrix();
  }, [activePieceType, piecesMatrix]);

  // update the grid columns/rows (zoom maybe)
  useEffect(() => {
    console.log('++', 'isSolo', 'piecesMatrix:', piecesMatrix);
    setConfigurationMeta(
      updateConfigurationMeta(piecesMatrix, activeColor, apiProducts, showDimensions)
    );
  }, [piecesMatrix, showDimensions]);

  // test
  useEffect(() => {
    console.log('!!!', 'useeffect', 'piecesMatrix:', Object.keys(piecesMatrix).length, piecesMatrix);    
  }, [piecesMatrix]);

  // function to reset config to startingConfig
  const resetConfiguration = useCallback(
    () => {
      console.log('$$$', 'resetConfiguration', 'piecesMatrix:', piecesMatrix);
      if (confirm("Are you sure you want to reset your configuration? You will lose any changes you have made.") == true) {
        setPiecesMatrix(configurationToPiecesMatrix(startingConfiguration));
      } else {}
    }, [setPiecesMatrix, startingConfiguration]
  );

  // function to reset config to startingConfig
  const startFromZero = useCallback(
    () => {
      console.log('$$$', 'startFromZero', 'piecesMatrix:', piecesMatrix);
      if (confirm("Are you sure you want to build your own? You will lose any changes you have made.") == true) {
        setActivePieceType(null);
        setShowDimensions(true);
        const { resetTransform } = transformComponentRef.current;
        resetTransform();
        setPiecesMatrix({});
      } else {}
    }, [setPiecesMatrix]
  );

  // function to add a piece to the matrix and shift if inserting
  const addPieceToMatrix = useCallback(
    (newMatrixPiece, action, piecesToMove, connectingFrom, groupNum) => {
      console.log('$$$', 'addPieceToMatrix', 'piecesMatrix:', piecesMatrix);
      updateConfigurationMeta
      setPiecesMatrix(
        addPieceToMatrixAndShift(
          newMatrixPiece,
          action,
          piecesToMove,
          connectingFrom,
          piecesMatrix,
          configurationMeta.numGridColumns,
          configurationMeta.numGridRows
        )
      );
    },
    [piecesMatrix, setPiecesMatrix, configurationMeta]
  );

  // function to add a piece to the matrix from drop
  const addPieceToMatrixFromDrop = useCallback(
    (thisPieceType, x, y) => {
      console.log('!!!', 'addPieceToMatrixFromDrop', 'piecesMatrix:', Object.keys(piecesMatrix).length, piecesMatrix);
      // if on the canvas
      if (x > -1 && y > -1) {
        // if piece doesn't overlap, add to piecesMatrix
        const newMatrixPiece = defineMatrixPiece(thisPieceType, 0, x, y);
        if (!getOverlappingMatrixPiece(piecesMatrix, newMatrixPiece)) {
          // reset solo drop indicator
          setSoloDropIndicator({x: 0, y: 0, display: false});
          // increment groupNum
          let newGroupNum = groupNum + 1;
          setGroupNum(newGroupNum);
          // add to piecesMatrix
          const newPiecesMatrix = addPieceToMatrixBasic(piecesMatrix, x, y, thisPieceType, newGroupNum);
          setPiecesMatrix(newPiecesMatrix);
          console.log('!!!', 'after piece has dropped ', 'newPiecesMatrix:', Object.keys(newPiecesMatrix).length, newPiecesMatrix);

        }
      }
    },
    [piecesMatrix, setPiecesMatrix, groupNum]
  );

  // function to handle solo hover
  const handleSoloDropZoneHover = useCallback(
    (thisPieceType, x, y) => {
      console.log('!!!', 'handleSoloDropZoneHover', 'piecesMatrix:', Object.keys(piecesMatrix).length, piecesMatrix);
      // if on the canvas
      if (x > -1 && y > -1) {
        // if piece doesn't overlap, show drop indicator       
        const newMatrixPiece = defineMatrixPiece(thisPieceType, 0, x, y);
        console.log('∆∆∆∆∆', 'newMatrixPiece', newMatrixPiece);
        if (!getOverlappingMatrixPiece(piecesMatrix, newMatrixPiece)) {
        // do it? 
          console.log('∆∆∆∆∆', 'YES CAN drop here');
          console.log('solodropindicator HOVER',x, y );
          setSoloDropIndicator({x: x, y: y, display: true});
        } else {
          console.log('∆∆∆∆∆', 'NO cant drop here');
          setSoloDropIndicator({x: x, y: y, display: false});
        }
      // if not on canvas, disable indicator
      } else {
        console.log('∆∆∆∆∆', 'NOT on canvas');
        setSoloDropIndicator({x: 0, y: 0, display: false});
      }
    },
    [piecesMatrix]
  );

  
  // function to remove a piece to the matrix and shift if necessary
  const removePieceFromMatrix = useCallback(
    (x, y) => {
      console.log('$$$', 'removePieceFromMatrix', 'piecesMatrix:', piecesMatrix);
      async function updateMatrix() {
        const updatedPiecesMatrix = await removePieceFromMatrixAndShift(x, y, piecesMatrix);
        setPiecesMatrix(updatedPiecesMatrix);
      }
      updateMatrix();
    },
    [piecesMatrix, setPiecesMatrix]
  );

  // function to rotate a piece in the matrix
  const rotatePieceInMatrix = useCallback(
    (x, y, currentRotation, groupNum) => {
      console.log('$$$', 'rotatePiece', 'piecesMatrix:', piecesMatrix);
      async function updateMatrix() {
        const updatedPiecesMatrix = await rotatePiece(x, y, currentRotation, groupNum, piecesMatrix);
        setPiecesMatrix(updatedPiecesMatrix);
      }
      updateMatrix();
    },
    [piecesMatrix, setPiecesMatrix]
  );


  // testing function, highlight the pieces that will move
  const showHighlightPiecesToMove = useCallback(
    (action, piecesToMove, connectingFrom) => {
      if (piecesToMove) {
        const newPiecesMatrix = { ...piecesMatrix };
        // map thru piecesToMove
        piecesToMove.map((pieceToMove, index) => {
          // add 'highlighted to each piece'
          const selectedPiece = getMatrixPieceById(
            newPiecesMatrix,
            pieceToMove.x,
            pieceToMove.y
          );
          if (selectedPiece) {
            selectedPiece.highlighted = true;
          }
        });
        setPiecesMatrix(newPiecesMatrix);
      }
    },
    [piecesMatrix]
  );

  // testing function, turn off highlight the pieces that will move
  const hideHighlightPiecesToMove = useCallback(
    (action, piecesToMove, connectingFrom) => {
      if (piecesToMove) {
        const newPiecesMatrix = { ...piecesMatrix };
        // map thru piecesToMove
        piecesToMove.map((pieceToMove, index) => {
          // remove 'highlighted to each piece'
          const selectedPiece = getMatrixPieceById(
            newPiecesMatrix,
            pieceToMove.x,
            pieceToMove.y
          );
          if (selectedPiece) {
            selectedPiece.highlighted = false;
          }
        });
        setPiecesMatrix(newPiecesMatrix);
      }
    },
    [piecesMatrix]
  );

  // toggleHighlightedPieceType for delete from pieces list
  const toggleHighlightedPieceType = useCallback(
    (pieceType) => {
      const newHighlightedPieceType =
        pieceType === highlightedPieceType ? null : pieceType;
      setHighlightedPieceType(newHighlightedPieceType);
    },
    [highlightedPieceType]
  );

  const initiateEmail = useCallback(() => {
    window.scrollTo(0, 0);
    setEmailDesignActive(true);
    setActivePieceType(null);
    setShowDimensions(true);
    const { resetTransform } = transformComponentRef.current;
    resetTransform();
  }, [setActivePieceType, setEmailDesignActive]);

  const exitEmail = useCallback(() => {
    setEmailDesignActive(false);
  }, [setEmailDesignActive]);

  const initiatePurchase = useCallback(() => {
    window.scrollTo(0, 0);
    setPurchaseDesignActive(true);
    setActivePieceType(null);
    setShowDimensions(true);
    const { resetTransform } = transformComponentRef.current;
    resetTransform();
  }, [setPurchaseDesignActive]);

  const exitPurchase = useCallback(() => {
    setPurchaseDesignActive(false);
  }, [setPurchaseDesignActive]);

  const toggleShowDimensions = useCallback(() => {
    setShowDimensions(!showDimensions);
  }, [setShowDimensions, showDimensions]);

  const [showModal, setShowModal] = useState(false);

  function toggle3dModal() {
    setShowModal(!showModal);
  }

  const AvailabilityMesssage = styled.label`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    p {
      font: var(--body-small);
      margin-bottom: 0;
    }
    & + button {
      disabled: true;
    }
  `;
  
  const EmailMessage = (list) => {
    const [agree, setAgree] = useState(false);
    const today = new Date();
    console.log('[price]', configurationMeta.price);

    if (list.list !== undefined) {
      const dates = Object.values(list.list)
        .map(a => a.available && a.quantity > 0 ? a.available : '')
        .filter((avb) => avb !== 'Available' && avb !== '');

      if (dates.length) {
        const soonest = dates.reduce((a, b) => {
          const aDate = a.split('/')[2]+'-'+a.split('/')[0]+'-'+a.split('/')[1];
          const bDate = b.split('/')[2]+'-'+b.split('/')[0]+'-'+b.split('/')[1];
          const adiff = new Date(aDate) - today;
          return (adiff < 0 && adiff > new Date(bDate) - today) ? a : b;
        });
        return (
          <>
            <h2>{sessionId ? 'Check Out' : 'Email'}</h2>
            <p>
              Once you are happy with your design, {sessionId ? 'add it to your cart' : 'email it to yourself.'}
            </p>
            <AvailabilityMesssage htmlFor="agree" className={(agree) ? "agreed" : ""}>
              <input type="checkbox" id="agree" name="agree" value="agree" onChange={() => setAgree(!agree)} />
              <p>I acknowledge that due to piece availability, my configuration won&apos;t be available for order until <b>{soonest}</b>.</p>
            </AvailabilityMesssage>
            {sessionId && (
              <>
                <Button.StandardUI style={{display: 'block', width: '100%'}} disabled={!agree} clickHandler={initiatePurchase}>
                  Add to Cart
                </Button.StandardUI>
                <br/>
                <p>Or email it to yourself.</p>
              </>
            )}
            <Button.OutlineUI style={{display: 'block', width: '100%'}} disabled={!agree} clickHandler={initiateEmail}>
              Save & Email My Design
            </Button.OutlineUI>
          </>
        );
      } else {
        console.log('no dates');
        // setAgree(true);
      }
    }
    return (
      <>
        <h2>{sessionId ? 'Check Out' : 'Email'}</h2>
        <p>
          Once you are happy with your design, {sessionId ? 'add it to your cart' : 'email it to yourself.'}
        </p>
        {sessionId && (
          <>
            <Button.StandardUI style={{display: 'block', width: '100%'}} disabled={configurationMeta.price === 0} clickHandler={initiatePurchase}>
              Add to Cart
            </Button.StandardUI>
            <br/>
            <p>Or email it to yourself.</p>
          </>
        )}
        <Button.OutlineUI style={{display: 'block', width: '100%'}} disabled={configurationMeta.price === 0} clickHandler={initiateEmail}>
          Save & Email My Design
        </Button.OutlineUI>
      </>
    );
    
  }
  
  return (
    <>
      {showModal && 
        <ViewerModal 
          config={configurationMeta}
          closeHandler={toggle3dModal} 
        />
      }
      {emailDesignActive && (
        <EmailDesign piecesMatrix={piecesMatrix} closeHandler={exitEmail} />
      )}
      {purchaseDesignActive && (
        <PurchaseDesign piecesMatrix={piecesMatrix} configurationMeta={configurationMeta} closeHandler={exitPurchase} />
      )}
      <DndProvider options={HTML5toTouch}>
        <Container className='nopadding'>
          <ConfiguratorWrapGrid activePieceType={activePieceType}>
            <ConfiguratorWrapper
              ref={configuratorWrapperRef}
              numGridColumns={configurationMeta.numGridColumns}
              numGridRows={configurationMeta.numGridRows}
            >
              <TransformWrapper
                ref={transformComponentRef}
                onTransformed={handleTransform}
                // initialScale={1.25}
                // initialPositionX={-0.9*CONFIGURATOR_GRID_PAD}
                // initialPositionY={-0.9*CONFIGURATOR_GRID_PAD}
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
                wheel={{ step: 0.05 }}
                doubleClick={{ disabled: true }}
              >
                {({ zoomIn, zoomOut, ...rest }) => (
                  <>
                    <ConfiguratorToolbar>
                      {activePieceType && (
                        <ConfiguratorEditControls>
                          <IconButton
                            type="X"
                            aria-label="Exit Edit Mode"
                            label="Exit Edit Mode"

                            clickHandler={() => setActivePieceType(null)}
                          />
                        </ConfiguratorEditControls>
                      )}
                      {!activePieceType && (
                        <ConfiguratorDimensionsControls>
                          <ConfiguratorDimensionsControl
                            aria-pressed={showDimensions}
                            onClick={toggleShowDimensions}
                          >
                            <ConfiguratorDimensionsControlIndicator />
                            <span>Dimensions</span>
                          </ConfiguratorDimensionsControl>
                        </ConfiguratorDimensionsControls>
                      )}
                      <Configurator3Dbutton>
                        <Button.OutlineUI 
                          icon="threed"
                          clickHandler={() => toggle3dModal()}
                        ><span className='hideMobile'>View in </span>3D</Button.OutlineUI>
                      </Configurator3Dbutton>
                      <ConfiguratorZoomControls>
                        <IconButton
                          type="Minus"
                          ariaLabel="zoom out"
                          clickHandler={() => zoomOut()}
                        />
                        <IconButton
                          type="Plus"
                          ariaLabel="zoom in"
                          clickHandler={() => zoomIn()}
                        />
                      </ConfiguratorZoomControls>
                    </ConfiguratorToolbar>

                    <TransformComponent>
                      <ConfiguratorLayers
                        id="configuration-image"
                        style={{
                          height: configuratorWrapperWidth,
                          width: configuratorWrapperWidth,
                        }}
                        numGridColumns={configurationMeta.numGridColumns}
                        numGridRows={configurationMeta.numGridRows}
                      >
                        {activePieceType && piecesMatrix && Object.keys(piecesMatrix).length > 0 && (
                          <>
                            <SoloDropZone
                              configuratorSize={configuratorWrapperWidth}
                              hoverHandler={handleSoloDropZoneHover}
                              dropHandler={addPieceToMatrixFromDrop}
                              numGridColumns={configurationMeta.numGridColumns}
                              numGridRows={configurationMeta.numGridRows}
                              activePieceType={activePieceType}
                              piecesMatrix={piecesMatrix}
                            />
                            <SoloDropIndicator
                              activePieceType={activePieceType}
                              numGridColumns={configurationMeta.numGridColumns}
                              numGridRows={configurationMeta.numGridRows}
                              settings={soloDropIndicator} 
                            />
                          </>
                        )}
                        {activePieceType && (
                          <ConfigurationPieceButtons
                            numGridColumns={configurationMeta.numGridColumns}
                            numGridRows={configurationMeta.numGridRows}
                          >
                            {buttonsMatrix.map((button, i) => {
                              return (
                                <ConfigurationPieceAddButton
                                  key={`configuration-piece-add-${i}`}
                                  dropHandler={addPieceToMatrix}
                                  clickHandler={addPieceToMatrix}
                                  numGridColumns={
                                    configurationMeta.numGridColumns
                                  }
                                  configuratorWrapperWidth={
                                    configuratorWrapperWidth
                                  }
                                  newMatrixPiece={button.newMatrixPiece}
                                  connectingFrom={button.connectingFrom}
                                  addToPieceType={button.addToPieceType}
                                  groupNum={button.groupNum}
                                  action={button.action}
                                  piecesToMove={button.piecesToMove}
                                  pieceX={button.pieceX}
                                  pieceY={button.pieceY}
                                  pieceWidth={button.pieceWidth}
                                  pieceHeight={button.pieceHeight}
                                />
                              );
                            })}
                          </ConfigurationPieceButtons>
                        )}
                        {configurationMeta.dimensions && showDimensions && (
                          <ConfigurationDimensions
                            data-shown={
                              showDimensions && !activePieceType ? 'on' : ''
                            }
                            numGridColumns={configurationMeta.numGridColumns}
                            numGridRows={configurationMeta.numGridRows}
                          >
                            {configurationMeta.dimensions.north.map((thisLine, index) => {
                              return (
                                <ConfigurationDimensionHorizontal
                                  key={`configuration-dimension-north-${index}`}
                                  direction={thisLine.direction}
                                  startX={thisLine.startX + 5 + 1}
                                  endX={thisLine.endX - 5}
                                  startY={thisLine.startY - 7}
                                  endY={thisLine.endY - 7}
                                >
                                  <div>
                                    <span>
                                    {(thisLine.endX - thisLine.startX + 1)/4}&ldquo;
                                    </span>
                                  </div>
                                </ConfigurationDimensionHorizontal>
                              );
                            })}
                            {configurationMeta.dimensions.south.map((thisLine, index) => {
                              return (
                                <ConfigurationDimensionHorizontal
                                  key={`configuration-dimension-south-${index}`}
                                  direction={thisLine.direction}
                                  startX={thisLine.startX + 5 + 1}
                                  endX={thisLine.endX - 5}
                                  startY={thisLine.startY + 10}
                                  endY={thisLine.endY + 10}
                                >
                                  <div>
                                    <span>
                                      {(thisLine.endX - thisLine.startX + 1)/4}&ldquo;
                                    </span>
                                  </div>
                                </ConfigurationDimensionHorizontal>
                              );
                            })}
                            {configurationMeta.dimensions.east.map((thisLine, index) => {
                              return (
                                <ConfigurationDimensionVertical
                                  key={`configuration-dimension-east-${index}`}
                                  startX={thisLine.startX + 10}
                                  endX={thisLine.endX + 10}
                                  startY={thisLine.startY + 5 + 1}
                                  endY={thisLine.endY - 5}
                                >
                                  <div>
                                    <span>
                                    {(thisLine.endY - thisLine.startY + 1)/4}&ldquo;
                                    </span>
                                  </div>
                                </ConfigurationDimensionVertical>
                              );
                            })}
                            {configurationMeta.dimensions.west.map((thisLine, index) => {
                              return (
                                <ConfigurationDimensionVertical
                                  key={`configuration-dimension-west-${index}`}
                                  startX={thisLine.startX - 10}
                                  endX={thisLine.endX - 10}
                                  startY={thisLine.startY + 5 + 1}
                                  endY={thisLine.endY - 5}
                                >
                                  <div>
                                    <span>
                                    {(thisLine.endY - thisLine.startY + 1)/4}&ldquo;
                                    </span>
                                  </div>
                                </ConfigurationDimensionVertical>
                              );
                            })}
                          </ConfigurationDimensions>
                        )}

                        {piecesMatrix &&
                          Object.keys(piecesMatrix).length > 0 && (
                            <ConfiguratorGrid
                              numGridColumns={configurationMeta.numGridColumns}
                              numGridRows={configurationMeta.numGridRows}
                            >
                              {Object.keys(piecesMatrix).map((key) => {
                                return (
                                  <ConfigurationPiece
                                    key={`${piecesMatrix[key].x}${piecesMatrix[key].y}`}
                                    piece={piecesMatrix[key]}
                                    highlighted={piecesMatrix[key].highlighted}
                                    highlightedPieceType={highlightedPieceType}
                                    activePieceType={activePieceType}
                                    numGridColumns={
                                      configurationMeta.numGridColumns
                                    }
                                    numGridRows={configurationMeta.numGridRows}
                                    deleteHandler={removePieceFromMatrix}
                                    rotateHandler={rotatePieceInMatrix}
                                  />
                                );
                              })}
                            </ConfiguratorGrid>
                          )}
                        {piecesMatrix &&
                          Object.keys(piecesMatrix).length < 1 && (
                            <ConfigurationPieceButtons
                              numGridColumns={1}
                              numGridRows={1}
                            >
                              {!activePieceType && (
                                <ConfigurationZeroMessage>
                                  <Image
                                    src="/images/UpArrows.svg"
                                    alt=""
                                    width={535}
                                    height={84}
                                  />
                                  <ConfigurationZeroMessageContent>
                                    <h2><b>So Many</b> possibilities</h2>
                                    <p>
                                    Start your sectional by selecting one of the 
                                      <b className="above">pieces above.</b>
                                      <b className="below">pieces below.</b>
                                    </p>
                                  </ConfigurationZeroMessageContent>
                                </ConfigurationZeroMessage>
                              )}
                              {activePieceType && (
                                <ConfigurationPieceAddButton
                                  key={`configuration-piece-add-zero`}
                                  dropHandler={addPieceToMatrix}
                                  clickHandler={addPieceToMatrix}
                                  numGridColumns={20}
                                  configuratorWrapperWidth={
                                    configuratorWrapperWidth
                                  }
                                  connectingFrom="zero"
                                  action="addToZero"
                                  addToZero={true}
                                  newMatrixPiece={defineMatrixPiece(
                                    activePieceType
                                      ? activePieceType
                                      : STARTING_ENABLED_PIECE_TYPES[0],
                                    0,
                                    CONFIGURATOR_GRID_PAD,
                                    CONFIGURATOR_GRID_PAD
                                  )}
                                  piecesToMove={null}
                                  pieceX={0}
                                  pieceY={0}
                                  pieceWidth={20}
                                  pieceHeight={20}
                                />
                              )}
                            </ConfigurationPieceButtons>
                          )}                          
                      </ConfiguratorLayers>
                    </TransformComponent>
                    {activePieceType && !isBrowser && (
                      <CustomDragLayer activePieceType={activePieceType}/>
                    )}
                  </>
                )}
              </TransformWrapper>
            </ConfiguratorWrapper>
            <ConfigurationMenuTitle>
              <b>Drag and drop</b> or <b>tap</b> on the pieces to customize your sectional<span>:</span>
            </ConfigurationMenuTitle>
            <ConfigurationMenuWrapper>
              {enabledPieceTypes.map((enabledPieceType, index) => {
                return (
                  <li key={`configuration-menu-option-${enabledPieceType}`}>
                    <ConfigurationMenuOption
                      pieceType={enabledPieceType}
                      isActive={enabledPieceType === activePieceType}
                      handleConfigurationMenuOptionClick={setActivePieceType}
                      pieceTypeSetter={setActivePieceType}
                    />
                  </li>
                );
              })}
            </ConfigurationMenuWrapper>
            {activePieceType && (
              <ActivePieceTypePreview 
                activePieceType={activePieceType} 
              />
            )}
            <BuildYourOwnCTA>
              <Button.OutlineUI clickHandler={resetConfiguration}>Start Over</Button.OutlineUI>                
            </BuildYourOwnCTA>
            <ConfiguratorAside>
              <h2>Choose Your Color</h2>
              <ColorMenu size="large" />
              {configurationMeta && (
                <>
                  <ConfiguratorAsideSeparator />
                  <h2>In Your Selection</h2>
                  <TotalPriceTable
                    piecesList={configurationMeta.piecesList}
                    highlightedPieceType={highlightedPieceType}
                    clickHandler={toggleHighlightedPieceType}
                    totalPrice={configurationMeta.price}
                  />
                  <TotalPriceTableFooter>
                    <TotalPriceTableFooterLabel>
                      Total
                    </TotalPriceTableFooterLabel>
                    <TotalPriceTableFooterPrice>
                      {formatCurrency(configurationMeta.price)}
                    </TotalPriceTableFooterPrice>
                  </TotalPriceTableFooter>

                  <ConfiguratorAsideSeparator />
                  <h2>Dimensions</h2>
                  <p>
                    Width: {configurationMeta.productWidth}&ldquo; &times;
                    Depth: {configurationMeta.productDepth}&ldquo; &times;
                    Height: {configurationMeta.productHeight}&ldquo;
                  </p>
                  <ConfiguratorAsideSeparator />
                  {configurationMeta.piecesList && (
                    <EmailMessage list={configurationMeta.piecesList} />
                  )}
                </>
              )}
            </ConfiguratorAside>
          </ConfiguratorWrapGrid>
        </Container>
      </DndProvider>
    </>
  );
};

export default Configurator;
