import defineMatrixPiece from "./DefineMatrixPiece";

const shiftOtherGroupsAfterInsert = (piecesMatrix, newMatrixPiece, connectingFrom) => {

  console.log('shiftOtherGroupsAfterInsert', 'newMatrixPiece.groupNum', newMatrixPiece.groupNum, 'connectingFrom', connectingFrom);

  // make a copy of the piecesMatrix to mess around with
  let newPiecesMatrix = {...piecesMatrix};

  /*
    what is the greatest NE.x of all pieces in each group?
  */
  const groups = [];
  Object.keys(newPiecesMatrix).map(key => {
    const thisPieceGroupNum = newPiecesMatrix[key].groupNum;
    const thisPieceXMax = newPiecesMatrix[key].coords.NE.x;
    const thisPieceXMin = newPiecesMatrix[key].x;
    const thisPieceYMax = newPiecesMatrix[key].coords.SW.y;
    const thisPieceYMin = newPiecesMatrix[key].y;
    console.log('groups', 'thisPieceGroupNum', thisPieceGroupNum, 'thisPieceXMax', thisPieceXMax);
    if (!groups[thisPieceGroupNum]) {
      groups[thisPieceGroupNum] = {
        foo: 'bar',
        xMax: thisPieceXMax,
        xMin: thisPieceXMin,
        yMax: thisPieceYMax,
        yMin: thisPieceYMin
      }
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].xMax < thisPieceXMax) {
      groups[thisPieceGroupNum].xMax = thisPieceXMax;      
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].xMin > thisPieceXMin) {
      groups[thisPieceGroupNum].xMin = thisPieceXMin;      
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].yMax < thisPieceYMax) {
      groups[thisPieceGroupNum].yMax = thisPieceYMax;      
    }
    if (groups[thisPieceGroupNum] && groups[thisPieceGroupNum].yMin > thisPieceYMin) {
      groups[thisPieceGroupNum].yMin = thisPieceYMin;      
    }    
  });
  console.log('groups', groups);

  
  const newMatrixPieceGroupXMax = groups[newMatrixPiece.groupNum].xMax;
  const newMatrixPieceGroupYMax = groups[newMatrixPiece.groupNum].yMax;
  
  // loop through piecesMatrix and shift all pieces that are in a groupNum other than the newMatrixPiece's groupNum
  const shiftedPiecesMatrix = {};
  Object.keys(newPiecesMatrix).map(key => {
    const thisPiece = newPiecesMatrix[key];
    // if same groupNum, add a copy without shifting
    if (thisPiece.groupNum === newMatrixPiece.groupNum) {
      shiftedPiecesMatrix[`${thisPiece.x}${thisPiece.y}`] = thisPiece;
    }

    // if different groupNum, shift if necessary
    if (thisPiece.groupNum !== newMatrixPiece.groupNum) {
      
      // if connectingFrom is east
      // and thisPiece's group xMax is greater than newMatrixPiece's group xMax        
      if (connectingFrom === 'east' && groups[thisPiece.groupNum].xMax > newMatrixPieceGroupXMax) {
        const shiftedMatrixPiece = defineMatrixPiece(thisPiece.pieceType, thisPiece.rotation, thisPiece.x + newMatrixPiece.width, thisPiece.y, thisPiece.groupNum);
        shiftedPiecesMatrix[`${shiftedMatrixPiece.x}${shiftedMatrixPiece.y}`] = shiftedMatrixPiece;
      }
      // if not, add a copy without shifting
      if (connectingFrom === 'east' && groups[thisPiece.groupNum].xMax <= newMatrixPieceGroupXMax) {
        shiftedPiecesMatrix[`${thisPiece.x}${thisPiece.y}`] = thisPiece;
      }

      // and thisPiece's group yMax is greater than newMatrixPiece's group yMax
      if (connectingFrom === 'south' && groups[thisPiece.groupNum].yMax > newMatrixPieceGroupYMax) {
        const shiftedMatrixPiece = defineMatrixPiece(thisPiece.pieceType, thisPiece.rotation, thisPiece.x, thisPiece.y + newMatrixPiece.height, thisPiece.groupNum);
        shiftedPiecesMatrix[`${shiftedMatrixPiece.x}${shiftedMatrixPiece.y}`] = shiftedMatrixPiece;
      }
      // if not, add a copy without shifting
      if (connectingFrom === 'south' && groups[thisPiece.groupNum].yMax <= newMatrixPieceGroupYMax) {
        shiftedPiecesMatrix[`${thisPiece.x}${thisPiece.y}`] = thisPiece;
      }

    }
  });

  return shiftedPiecesMatrix;

}

export default shiftOtherGroupsAfterInsert;