import { useMutation } from 'react-query';
import * as API from '../services/API';

const createConfig = async (body) => {
  const data = await API.createConfig(body);

  return data;
};

export default function useCreateConfig({
  onError = () => {},
  onSuccess = () => {},
}) {
  return useMutation((newInvitation) => createConfig(newInvitation), {
    onError,
    onSuccess,
  });
}
