const PieceTypes = {
  // armless
  armless: {
    name: 'Armless Chair',
    nameA: 'Armless',
    nameB: 'Chair',
    description: "Extending your sectional couldn’t be easier with the stationary chair. Add as many as you need to get the perfect shape and size for your space. Also, use it independently as a freestanding chair.",
    model: '',
    price: 395,
    productHeight: 40,
    shift: {
      0: false,
      90: false,
      180: false,
      270: false
    },
    rotation: {
      0: {
        width: 133,
        height: 167,
        productWidth: 33.25,
        productDepth: 41.75,
        connects: {
          north: false,
          east: {
            chaiseRight: [0],
            reclinerRight:[0],
            corner: [90],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          },
          south: false,
          west: {
            chaiseLeft: [0],
            reclinerLeft:[0],
            corner: [0],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          }
        }
      },
      90: {
        width: 167,
        height: 133,
        productWidth: 41.75,
        productDepth: 33.25,
        connects: {
          north: {
            chaiseLeft: [90],
            reclinerLeft:[90],
            corner: [90],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
          },
          east: false,
          south: {
            chaiseRight: [90],
            reclinerRight:[90],
            corner: [180],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
          },
          west: false,
        }
      },
      180: {
        width: 133,
        height: 167,
        productWidth: 33.25,
        productDepth: 41.75,
        connects: {
          north: false,
          east: {
            chaiseLeft: [180],
            reclinerLeft:[180],
            corner: [180],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
          },
          south: false,
          west: {
            chaiseRight: [180],
            reclinerRight:[180],
            corner: [270],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
          }
        }
      },
      270: {
        width: 167,
        height: 133,
        productWidth: 41.75,
        productDepth: 33.25,
        connects: {
          north: {
            chaiseRight: [270],
            reclinerRight:[270],
            corner: [0],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          east: false,
          south: {
            chaiseLeft: [270],
            reclinerLeft:[270],
            corner: [270],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          west: false
        }
      }
    }
  },
  // armlessRecliner
  armlessRecliner: {
    name: 'Dual Power Armless Recliner',
    nameA: 'Dual Power Armless',
    nameB: 'Recliner',
    description: "Dual power functionality allows for easy adjustment in the headrest and reclining position, providing the ultimate in personalized comfort. Incorporate it as part of your sectional configuration or independently as a freestanding recliner.",
    model: '',
    price: 395,
    productHeight: 40,
    shift: {
      0: false,
      90: false,
      180: false,
      270: false
    },
    rotation: {
      0: {
        width: 133,
        height: 167,
        productWidth: 33.25,
        productDepth: 41.75,
        connects: {
          north: false,
          east: {
            chaiseRight: [0],
            reclinerRight:[0],
            corner: [90],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          },
          south: false,
          west: {
            chaiseLeft: [0],
            reclinerLeft:[0],
            corner: [0],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          }
        }
      },
      90: {
        width: 167,
        height: 133,
        productWidth: 41.75,
        productDepth: 33.25,
        connects: {
          north: {
            chaiseLeft: [90],
            reclinerLeft:[90],
            corner: [90],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
          },
          east: false,
          south: {
            chaiseRight: [90],
            reclinerRight:[90],
            corner: [180],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
          },
          west: false,
        }
      },
      180: {
        width: 133,
        height: 167,
        productWidth: 33.25,
        productDepth: 41.75,
        connects: {
          north: false,
          east: {
            chaiseLeft: [180],
            reclinerLeft:[180],
            corner: [180],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
          },
          south: false,
          west: {
            chaiseRight: [180],
            reclinerRight:[180],
            corner: [270],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
          }
        }
      },
      270: {
        width: 167,
        height: 133,
        productWidth: 41.75,
        productDepth: 33.25,
        connects: {
          north: {
            chaiseRight: [270],
            reclinerRight:[270],
            corner: [0],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          east: false,
          south: {
            chaiseLeft: [270],
            reclinerLeft:[270],
            corner: [270],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          west: false
        }
      }
    }
  },
  // reclinerLeft
  reclinerLeft: {
    name: 'Dual Power Left Arm Recliner',
    nameA: 'Dual Power Left Arm',
    nameB: 'Recliner',
    description: "This end is just the beginning of a great sectional! Dual power functionality allows for easy adjustment in the headrest and reclining position, providing the ultimate in personalized comfort. Plus, it comes with USB charging ports!",
    model: '',
    price: 495,
    productHeight: 40,
    shift: {
      0: true,
      90: true,
      180: false,
      270: false
    },
    rotation: {
      0: {
        width: 163,
        height: 167,
        productWidth: 40.75,
        productDepth: 41.75,
        connects: {
          north: false,
          east: {
            chaiseRight: [0],
            reclinerRight:[0],
            corner: [90],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          },
          south: false,
          west: false
        }
      },
      90: {
        width: 167,
        height: 163,
        productWidth: 41.75,
        productDepth: 40.75,
        connects: {
          north: false,
          east: false,
          south: {
            chaiseRight: [90],
            reclinerRight:[90],
            corner: [180],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
          },
          west: false,
        }
      },
      180: {
        width: 163,
        height: 167,
        productWidth: 40.75,
        productDepth: 41.75,
        connects: {
          north: false,
          east: false,
          south: false,
          west: {
            chaiseRight: [180],
            reclinerRight:[180],
            corner: [270],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
          }
        }
      },
      270: {
        width: 167,
        height: 163,
        productWidth: 41.75,
        productDepth: 40.75,
        connects: {
          north: {
            chaiseRight: [270],
            reclinerRight:[270],
            corner: [0],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          east: false,
          south: false,
          west: false
        }
      }
    }
  },
  // reclinerRight
  reclinerRight: {
    name: 'Dual Power Right Arm Recliner',
    nameA: 'Dual Power Right Arm',
    nameB: 'Recliner',
    description: 'All good things come to an end, and it better be comfortable! This one features dual power functionality allowing for easy adjustment in the headrest and reclining position, providing the ultimate in personalized comfort. Plus, it comes with USB charging ports.',
    model: '',
    price: 495,
    productHeight: 40,
    shift: {
      0: false,
      90: false,
      180: true,
      270: true
    },
    rotation: {
      0: {
        width: 163,
        height: 167,
        productWidth: 40.75,
        productDepth: 41.75,
        connects: {
          north: false,
          east: false,
          south: false,
          west: {
            chaiseLeft: [0],
            reclinerLeft:[0],
            corner: [0],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          }
        }
      },
      90: {
        width: 167,
        height: 163,
        productWidth: 41.75,
        productDepth: 40.75,
        connects: {
          north: {
            chaiseLeft: [90],
            reclinerLeft:[90],
            corner: [90],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
          },
          east: false,
          south: false,
          west: false,
        }
      },
      180: {
        width: 163,
        height: 167,
        productWidth: 40.75,
        productDepth: 41.75,
        connects: {
          north: false,
          east: {
            chaiseLeft: [180],
            reclinerLeft:[180],
            corner: [180],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [90],
          },
          south: false,
          west: false
        }
      },
      270: {
        width: 167,
        height: 163,
        productWidth: 41.75,
        productDepth: 40.75,
        connects: {
          north: {
            chaiseLeft: [270],
            reclinerLeft:[270],
            corner: [270],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          east: false,
          south: false,
          west: false
        }
      }
    }
  },
  // corner
  corner: {
    name: 'Corner Wedge',
    nameA: 'Corner',
    nameB: 'Wedge',
    description: "Add this to create an “L” or “U” shaped sectional. Watch your design take a turn for the better.",
    model: '',
    price: 495,
    productHeight: 40,
    shift: {
      0: false,
      90: false,
      180: false,
      270: false
    },   
    rotation: {
      0: {
        width: 213,
        height: 213,
        productWidth: 53.25,
        productDepth: 53.25,
        connects: {
          north: false,
          east: {
            chaiseRight: [0],
            reclinerRight:[0],
            corner: [90],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          },
          south: {
            chaiseLeft: [270],
            reclinerLeft:[270],
            corner: [270],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          west: false,
        }
      },
      90: {
        width: 213,
        height: 213,
        productWidth: 53.25,
        productDepth: 53.25,
        connects: {
          north: false,
          east: false,
          south: {
            chaiseRight: [90],
            reclinerRight:[90],
            corner: [180],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],            
          },
          west: {
            chaiseLeft: [0],
            reclinerLeft:[0],
            corner: [0],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],            
          }
        }
      },
      180: {
        width: 213,
        height: 213,
        productWidth: 53.25,
        productDepth: 53.25,
        connects: {
          north: {
            chaiseLeft: [90],
            reclinerLeft:[90],
            corner: [90],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],            
          },
          east: false,
          south: false,
          west: {
            chaiseRight: [180],
            reclinerRight:[180],
            corner: [270],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],            
          }
        }
      },
      270: {
        width: 213,
        height: 213,
        productWidth: 53.25,
        productDepth: 53.25,
        connects: {
          north: {
            chaiseRight: [270],
            reclinerRight:[270],
            corner: [0],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],            
          },
          east: {
            chaiseLeft: [180],
            reclinerLeft:[180],
            corner: [180],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
          },
          south: true,
          west: false
        }
      }
    }
  },
  // chaiseRight
  chaiseRight: {
    name: 'Dual Power Right Arm Reclining Chaise',
    nameA: 'Dual Power Right Arm Reclining',
    nameB: 'Chaise',
    description: "Designed for serious relaxation featuring dual power functionality allowing for easy adjustment in the headrest and reclining position. Plus, it comes with USB charging ports!",
    model: '',
    price: 595,
    productHeight: 40,
    shift: {
      0: false,
      90: false,
      180: true,
      270: true
    },
    rotation: {
      0: {
        width: 163,
        height: 252,
        productWidth: 40.75,
        productDepth: 63,
        connects: {
          north: false,
          east: false,
          south: false,
          west: {
            chaiseLeft: [0],
            reclinerLeft:[0],
            corner: [0],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
          }
        }
      },
      90: {
        width: 252,
        height: 163,
        productWidth: 63,
        productDepth: 40.75,
        connects: {
          north: {
            chaiseLeft: [90],
            reclinerLeft:[90],
            corner: [90],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
          },
          east: false,
          south: false,
          west: false,
        }
      },
      180: {
        width: 163,
        height: 252,
        productWidth: 40.75,
        productDepth: 63,
        connects: {
          north: false,
          east: {
            chaiseLeft: [180],
            reclinerLeft:[180],
            corner: [180],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
          },
          south: false,
          west: false
        }
      },
      270: {
        width: 252,
        height: 163,
        productWidth: 63,
        productDepth: 40.75,
        connects: {
          north: false,
          east: false,
          south: {
            chaiseLeft: [270],
            reclinerLeft:[270],
            corner: [270],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
          },
          west: false
        }
      }
    }
  },
  // chaiseLeft
  chaiseLeft: {
    name: 'Dual Power Left Arm Reclining Chaise',
    nameA: 'Dual Power Left Arm Reclining',
    nameB: 'Chaise',
    description: "The perfect ending to your perfect build. Dual power functionality allows for easy adjustment in the headrest and reclining position. Plus, it comes with USB charging ports!",
    model: '',
    price: 595,
    productHeight: 38,
    shift: {
      0: true,
      90: true,
      180: false,
      270: false
    },
    rotation: {
      0: {
        width: 163,
        height: 252,
        productWidth: 40.75,
        productDepth: 63,
        connects: {
          north: false,
          east: {
            chaiseRight: [0],
            reclinerRight:[0],
            corner: [90],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0]
          },
          south: false,
          west: false
        }
      },
      90: {
        width: 252,
        height: 163,
        productWidth: 63,
        productDepth: 40.75,
        connects: {
          north: false,
          east: false,
          south: {
            chaiseRight: [90],
            reclinerRight:[90],
            corner: [180],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90]
          },
          west: false
        }
      },
      180: {
        width: 163,
        height: 252,
        productWidth: 40.75,
        productDepth: 63,
        connects: {
          north: false,
          east: false,
          south: false,
          west: {
            chaiseRight: [180],
            reclinerRight:[180],
            corner: [270],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180]
          }
        }
      },
      270: {
        width: 252,
        height: 163,
        productWidth: 63,
        productDepth: 40.75,
        connects: {
          north: {
            chaiseRight: [270],
            reclinerRight:[270],
            corner: [0],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270]
          },
          east: false,
          south: false,
          west: false
        }
      }
    }
  },
  // console
  console: {
    name: 'Wood Top Console',
    nameA: 'Wood Top',
    nameB: 'Console',
    description: "Built for convenience, the console includes a touch-activated reading light and swivel-top wood table with hidden storage for easy access to your lounging essentials.",
    model: '',
    price: 395,
    productHeight: 40,
    shift: {
      0: false,
      90: false,
      180: false,
      270: false
    },
    rotation: {
      0: {
        width: 49,
        height: 166,
        productWidth: 12.25,
        productDepth: 41.5,
        connects: {
          north: false,
          east: {
            chaiseRight: [0],
            reclinerRight:[0],
            corner: [90],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],          
          },
          south: false,
          west: {
            chaiseLeft: [0],
            reclinerLeft:[0],
            corner: [0],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],          
          }
        }
      },
      90: {
        width: 166,
        height: 49,
        productWidth: 41.5,
        productDepth: 12.25,
        connects: {
          north: {
            chaiseLeft: [90],
            reclinerLeft:[90],
            corner: [90],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
            ottoman: [0]
          },
          east: false,
          south: {
            chaiseRight: [90],
            reclinerRight:[90],
            corner: [180],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
            ottoman: [0]
          },
          west: false
        }
      },
      180: {
        width: 49,
        height: 166,
        productWidth: 12.25,
        productDepth: 41.5,
        connects: {
          north: false,
          east: {
            chaiseLeft: [180],
            reclinerLeft:[180],
            corner: [180],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
            ottoman: [0]
          },
          south: false,
          west: {
            chaiseRight: [180],
            reclinerRight:[180],
            corner: [270],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
            ottoman: [0]
          }
        }
      },
      270: {
        width: 166,
        height: 49,
        productWidth: 41.5,
        productDepth: 12.25,
        connects: {
          north: {
            chaiseRight: [270],
            reclinerRight:[270],
            corner: [0],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
            ottoman: [0]
          },
          east: false,
          south: {
            chaiseLeft: [270],
            reclinerLeft:[270],
            corner: [270],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
            ottoman: [0]
          },
          west: false
        }
      }
    }
  },
  // consoleTech
  consoleTech: {
    name: 'Media Console',
    nameA: 'Media',
    nameB: 'Console',
    description: "The console includes Bluetooth speaker connectivity, retracting cupholders, wireless charging and a compartment with hidden storage for easy access to your lounging essentials. Get creative with placement and take your seating experience to the next level.",
    model: '',
    price: 495,
    productHeight: 24,
    shift: {
      0: false,
      90: false,
      180: false,
      270: false
    },
    rotation: {
      0: {
        width: 49,
        height: 166,
        productWidth: 12.25,
        productDepth: 41.5,
        connects: {
          north: false,
          east: {
            chaiseRight: [0],
            reclinerRight:[0],
            corner: [90],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
            ottoman: [0]
          },
          south: false,
          west: {
            chaiseLeft: [0],
            reclinerLeft:[0],
            corner: [0],
            armless: [0],
            armlessRecliner: [0],
            console: [0],
            consoleTech: [0],
            ottoman: [0]
          }
        }
      },
      90: {
        width: 166,
        height: 49,
        productWidth: 41.5,
        productDepth: 12.25,
        connects: {
          north: {
            chaiseLeft: [90],
            reclinerLeft:[90],
            corner: [90],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
            ottoman: [0]
          },
          east: false,
          south: {
            chaiseRight: [90],
            reclinerRight:[90],
            corner: [180],
            armless: [90],
            armlessRecliner: [90],
            console: [90],
            consoleTech: [90],
            ottoman: [0]
          },
          west: false
        }
      },
      180: {
        width: 49,
        height: 166,
        productWidth: 12.25,
        productDepth: 41.5,
        connects: {
          north: false,
          east: {
            chaiseLeft: [180],
            reclinerLeft:[180],
            corner: [180],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
            ottoman: [0]
          },
          south: false,
          west: {
            chaiseRight: [180],
            reclinerRight:[180],
            corner: [270],
            armless: [180],
            armlessRecliner: [180],
            console: [180],
            consoleTech: [180],
            ottoman: [0]
          }
        }
      },
      270: {
        width: 166,
        height: 49,
        productWidth: 41.5,
        productDepth: 12.25,
        connects: {
          north: {
            chaiseRight: [270],
            reclinerRight:[270],
            corner: [0],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
            ottoman: [0]
          },
          east: false,
          south: {
            chaiseLeft: [270],
            reclinerLeft:[270],
            corner: [270],
            armless: [270],
            armlessRecliner: [270],
            console: [270],
            consoleTech: [270],
            ottoman: [0]
          },
          west: false
        }
      }
    }
  },
  

}

export default PieceTypes;