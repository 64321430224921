/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 RAF-Chaise.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function RightChaise(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/RAF-Chaise.glb'))
  // const { nodes, materials } = useGLTF('m23d/RAF-Chaise.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.435, 0, 0.99]}>
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER060_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER061_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER062_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER063_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER064_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER065_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER066_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER067_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER068_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER069_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER070_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER071_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER072_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_CONTROLLER073_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE005_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE006_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE008_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE007_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH091_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH092_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_026_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER_OPEN004_Retopo002_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT028_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT029_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT031_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT030_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT032_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_RUBBER_FEET004_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT033_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH009_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_STICH010_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_UPHOLSTERY008_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Back_Standing027_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH096_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH097_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_SEATCUSHIONS_LARGE001_Retopo_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_025_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCSEAT_LARGE009_n3d.geometry} material={props.materials.fabric} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/RAF-Chaise.glb'))
// useGLTF.preload('m23d/RAF-Chaise.glb')
