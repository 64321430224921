
// green < 2 weeks
// yellow 2-4 weeks
// orange 1-3 months
// red > 3 months

const getRelativeTime = (timestamp) => {
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    const difference = timestamp - Date.now();
    
    const daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
    const weeksDifference = Math.round(difference / (1000 * 60 * 60 * 24 * 7));
    const monthsDifference = Math.round(difference / (1000 * 60 * 60 * 24 * 30));

    if (daysDifference < 14) return {
        color:'green', 
        text:'Available ' + rtf.format(daysDifference, 'days')
    };
    if (daysDifference >= 14 && daysDifference < 30) return {
        color:'yellow', 
        text:'Available ' + rtf.format(weeksDifference, 'weeks')
    };
    if (daysDifference >= 30 && daysDifference < 90) return {
        color:'orange', 
        text:'Available ' + rtf.format(monthsDifference, 'months')
    };
    if (daysDifference >= 90) return {
        color:'red', 
        text:'Available ' + rtf.format(monthsDifference, 'months')
    };
}


//available can either be 'Available' or date like '09/18/2023'
//returns array of [color, text]

const getAvailability = (availabile) => {
    if (availabile === 'Available') {
        return {
            color:'green', 
            text:'Currently Available'
        };
    } else {
        //convert to date stamp to format Intl likes
        const date = availabile.split('/')[2]+'-'+availabile.split('/')[0]+'-'+availabile.split('/')[1];
        return getRelativeTime(new Date(date).getTime());
    }
}

export default getAvailability;