import React from 'react';
import styled from 'styled-components';
import PriceTableRow from './PriceTableRow';
import formatCurrency from '../helpers/FormatCurrency';


const TotalPriceTableWrapper = styled.table`
  width: 100%;
`;
const TotalPriceTableFooter = styled.div`
  display: flex;
  gap: 22%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--lighter-gray);
  margin: 1.25rem 0 -0.375rem;
  padding: 0.725rem 0 0;
  @media (min-width: 50em) {
    margin: 1.25rem 0 0;
    padding: 0.725rem 0 0.5rem;
  }
`;
const TotalPriceTableFooterLabel = styled.span`
  font: var(--subheading);
  color: var(--rtg-dark-blue);
  text-transform: uppercase;
`;
const TotalPriceTableFooterPrice = styled.span`
  font: var(--h-large);
  color: var(--dark-tyrian-blue);
`;

const PriceTable = ({
  piecesList,
  totalPrice,
}) => {

  if (totalPrice === 0) {
    return ('');
  }
  if (piecesList) {
    // console.log('piecesList', piecesList);
    return (
      <>
      <TotalPriceTableWrapper>
        <tbody>
          {Object.keys(piecesList).map((key, index) => {
            if (piecesList[key].quantity > 0) {
              return (
                <PriceTableRow 
                  key={`price-table-row-${key}`}
                  name={piecesList[key].name}
                  pieceType={piecesList[key].pieceType} 
                  quantity={piecesList[key].quantity} 
                  price={piecesList[key].price} 
                  available={piecesList[key].available}
                  totalCost={piecesList[key].totalCost}
                  index={index}
                />
              )
            }
          })}
        </tbody>
      </TotalPriceTableWrapper>
      <TotalPriceTableFooter>
        <TotalPriceTableFooterLabel>
          Total
        </TotalPriceTableFooterLabel>
        <TotalPriceTableFooterPrice>
          {formatCurrency(totalPrice)}
        </TotalPriceTableFooterPrice>
      </TotalPriceTableFooter>
      </>
    );
  }
};

export default PriceTable;