import React, { useEffect, useCallback, useState } from 'react';
import * as Button from './Button';
import styled from 'styled-components';
import { FocusOn } from 'react-focus-on';
import { useAppContext } from '../context/app';
import Viewer from './Viewer';

const ModalWrap = styled.div`
  position: fixed;
  z-index: 1000;
  top: -100000rem;
  left: 0;
  right: 0;
  height: 100vh;
  opacity: 0;
  background-color: rgba(7, 38, 59, 0.4);
  transition: opacity 0.25s ease-in-out, top 0s ease 0.25s;
  &._displaytrue {
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    transition: opacity 0.25s ease-in-out;
  }
`;
const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  // padding: 1.25rem;
  box-shadow: 0 0.25rem 2.5rem rgba(0, 0, 0, 0.35);
  @media (min-width: 64em) {
    padding: 1.25rem;
  }
`;

const ModalClose = styled.button`
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  background-color: var(--white);
  position: absolute;
  z-index: 2;
  top: 1.875rem;
  right: 1.875rem;
  transition: background-color 0.25s ease-in-out;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0.4375rem;
    height: 0.0625rem;
    width: 1.15625rem;
    background-color: var(--dark-tyrian-blue);
    transform-origin: top left;
    transition: background-color 0.25s ease-in-out;
  }
  &:before {
    top: 0.4375rem;
    transform: rotate(45deg);
  }
  &:after {
    bottom: 0.4375rem;
    transform: rotate(-45deg);
  }
  &:hover,
  &:focus-visible {
    background-color: var(--dark-tyrian-blue);
    &:before,
    &:after {
      background-color: var(--white);
    }
  }
  &:focus {
    outline: 1px dotted rgba(0, 0, 0, 0.2);
  }
`;

const MenuWrapper = styled.div`
  display: grid;
  align-items: center;
  margin-top: 0.25rem;
  grid-template-columns: auto 300px;
  ul {
    padding-top: 0.25rem;
    margin: 0 !important;
  }
`;

const ViewerWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: var(--offwhite);
  @media (min-width: 64em) {
    height: 42rem;
    width: 62rem;
  }
`;

const ViewInstructions = styled.div`
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: 50%;
  white-space: nowrap;
  background-color: rgba(255,255,255,.6);
  border-radius: 1.5rem;
  color: var(--dark-tyrian-blue);
  font: var(--button);
  padding: 0.4375rem 1rem;
  transform: translateX(-50%);
`;


const ViewerModal = ({ config, closeHandler }) => {
  const { activeColor } = useAppContext();
  const [shown, setShown] = useState(false);

  const {id} = config;

  console.log('[modal color]', activeColor);

  useEffect(() => {
    setShown(true);
  }, []);

  const closeModal = useCallback(() => {
    setShown(false);
    setTimeout(() => {
      closeHandler();
    }, 250);
  }, [closeHandler]);

  return (
    <ModalWrap className={`_display${shown}`} data-shown={shown}>
      <FocusOn onClickOutside={closeModal} onEscapeKey={closeModal}>
        <ModalContent>
          <ModalClose autoFocus onClick={closeModal} />
          <ViewInstructions>Drag to rotate and pinch to zoom</ViewInstructions>
          <ViewerWrapper>
            <Viewer config={config} />
          </ViewerWrapper>          
        </ModalContent>
      </FocusOn>
    </ModalWrap>
  );
};

export default ViewerModal;
