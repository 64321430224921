import styled from "styled-components";

const Illustration = styled.svg`
  #confetti {
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50%;
    transition: opacity .15s, transform .35s cubic-bezier(.17,.67,.4,2.12);
    &.shown {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const EmailDesignIllustration = ( {sent }) => {
  return (
    
    <Illustration width="180" height="213" viewBox="0 0 180 213" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="circles">
        <rect x="21" y="75" width="138" height="138" rx="69" fill="#9CE80A"/>
        <rect x="26.5" y="80.5" width="127" height="127" rx="63.5" fill="#9CE80A" stroke="#1E3B66"/>
      </g>
      {!sent && (
        <g id="email-closed">
          <path d="M115.5 135.902L90 153.82L64.5 135.902H115.5Z" fill="white"/>
          <path d="M115.5 135.902L90 153.82L64.5 135.902" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M64.5 135.902H115.5V163.594C115.5 164.026 115.276 164.44 114.878 164.746C114.479 165.051 113.939 165.223 113.375 165.223H66.625C66.0614 165.223 65.5209 165.051 65.1224 164.746C64.7239 164.44 64.5 164.026 64.5 163.594V135.902Z" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M85.3516 150.562L65.1641 164.754" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M114.836 164.754L94.6484 150.562" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      )}
      {sent && (
        <g id="email-open">
          <rect x="21" y="75" width="138" height="138" rx="69" fill="#9CE80A"/>
          <rect x="26.5" y="80.5" width="127" height="127" rx="63.5" fill="#9CE80A" stroke="#1E3B66"/>
          <path d="M115.5 135.5L94.6484 150.375H85.3516L64.5 135.5L90 118.5L115.5 135.5Z" fill="white"/>
          <path d="M64.5 135.5V163.125C64.5 163.689 64.7239 164.229 65.1224 164.628C65.5209 165.026 66.0614 165.25 66.625 165.25H113.375C113.939 165.25 114.479 165.026 114.878 164.628C115.276 164.229 115.5 163.689 115.5 163.125V135.5L90 118.5L64.5 135.5Z" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M85.3516 150.375L65.1641 164.639" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M114.836 164.639L94.6484 150.375" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M115.5 135.5L94.6484 150.375H85.3516L64.5 135.5" stroke="#1E3B66" stroke-width="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      )}
      <g id="confetti" className={sent ? 'shown' : ''}>
        <path d="M33.2494 44.955C39.4966 42.3127 42.9331 35.7109 41.545 29.2904C44.0084 28.9203 46.478 28.5423 48.9413 28.1722C49.9862 37.8813 44.0605 46.8834 34.9051 49.6673C34.3558 48.0986 33.8065 46.5299 33.2432 44.9628L33.2494 44.955Z" fill="#0071BC"/>
        <path d="M56.14 105.975C55.05 103.245 52.22 101.695 49.42 102.245C49.28 101.175 49.14 100.105 49 99.0349C53.22 98.6649 57.06 101.305 58.19 105.295C57.51 105.515 56.82 105.745 56.14 105.975V105.975Z" fill="#009245"/>
        <path d="M67.0659 1.99996L61 2.22345L61.1116 5.2514L67.1775 5.02791L67.0659 1.99996Z" fill="#009245"/>
        <path d="M15.3469 68L14 72.2203L22.4596 74.9201L23.8065 70.6998L15.3469 68Z" fill="#FCEE21"/>
        <path d="M73.4896 120.388L73.6953 128.806L77.901 128.703L77.6953 120.285L73.4896 120.388Z" fill="#FCEE21"/>
        <path d="M61.36 58.5296C68.13 56.6896 73.97 59.9196 75.89 64.0296C74.38 64.0496 73.07 64.4996 72.07 65.1696C69.9 62.3496 64.23 60.8296 59 64.5896C59.32 62.5196 60.1 60.4396 61.36 58.5396V58.5296Z" fill="#662D91"/>
        <path d="M59.22 83.2545C64.82 82.1045 69.41 85.0345 70.77 88.4745C69.54 88.4145 68.45 88.7145 67.61 89.2045C65.98 86.7945 61.45 85.2645 57 88.0645C57.36 86.3945 58.11 84.7445 59.23 83.2645L59.22 83.2545Z" fill="#ED1E79"/>
        <path d="M96.0273 53.0003L95 53.4465L95.8327 55.3635L96.86 54.9172L96.0273 53.0003Z" fill="#0071BC"/>
        <path d="M87.24 139C88.54 140.62 90.76 141.08 92.54 140.14C92.86 140.83 93.17 141.53 93.49 142.22C90.72 143.34 87.58 142.34 86 139.88C86.41 139.59 86.83 139.29 87.25 139H87.24Z" fill="#009245"/>
        <path d="M102.63 0C103.53 0.81 104.85 0.859998 105.78 0.149998C106.03 0.519998 106.27 0.889998 106.52 1.26C105.04 2.16 103.14 1.89 102 0.619999C102.21 0.409999 102.42 0.21 102.63 0Z" fill="#C1272D"/>
        <path d="M175.398 26L171 27.4776L173.959 36.284L178.357 34.8063L175.398 26Z" fill="#009245"/>
        <path d="M92.142 88.0004L89 91.3875L95.7888 97.685L98.9308 94.2979L92.142 88.0004Z" fill="#FCEE21"/>
        <path d="M138.189 63.0003L138 68.7371L149.494 69.1164L149.683 63.3795L138.189 63.0003Z" fill="#FCEE21"/>
        <path d="M85.3878 82.2672L84.4805 84.072L85.3829 84.5257L86.2902 82.7209L85.3878 82.2672Z" fill="#662D91"/>
        <path d="M119.861 27.0002L119 30.7219L130.175 33.3079L131.036 29.5863L119.861 27.0002Z" fill="#FBB03B"/>
        <path d="M103.747 116.374L101.902 116.83L102.298 118.431L104.142 117.976L103.747 116.374Z" fill="#FBB03B"/>
        <path d="M150 98.0802C150.18 94.0002 153.02 91.3302 155.64 91.0002C155.38 91.8402 155.4 92.6502 155.59 93.3202C153.64 94.0202 151.78 96.9002 152.94 100.47C151.85 99.9202 150.84 99.1202 150 98.0802Z" fill="#ED1E79"/>
        <path d="M173.045 54.7036L168.684 55.7267L169.238 58.0925L173.6 57.0694L173.045 54.7036Z" fill="#C1272D"/>
        <path d="M90.5361 23.66L86.7109 26.7542L88.3901 28.8301L92.2153 25.7358L90.5361 23.66Z" fill="#ED1E79"/>
        <path d="M16.8252 30L13 33.0942L14.6792 35.1701L18.5044 32.0758L16.8252 30Z" fill="#ED1E79"/>
        <path d="M72.8304 123.358C73.5493 126.885 76.6263 129.375 80.1233 129.323C80.0609 130.646 79.994 131.972 79.9271 133.298C74.7583 132.823 70.69 128.788 70.2027 123.727C71.0801 123.603 71.953 123.482 72.8304 123.358V123.358Z" fill="#0071BC"/>
        <path d="M131.92 97.7912C130.99 94.8612 128.16 93.0312 125.19 93.3912C125.13 92.2612 125.06 91.1312 125 90.0012C129.43 89.9312 133.24 92.9912 134.11 97.2412C133.38 97.4212 132.65 97.6112 131.92 97.7912Z" fill="#0071BC"/>
        <path d="M112.51 96C112.64 96.68 113.24 97.17 113.91 97.16C113.9 97.41 113.88 97.67 113.87 97.93C112.87 97.83 112.09 97.05 112 96.07C112.17 96.05 112.34 96.03 112.51 96V96Z" fill="#C1272D"/>
        <path d="M115.264 110.945L112 112.066L114.245 118.605L117.509 117.484L115.264 110.945Z" fill="#009245"/>
        <path d="M103.843 128L95 132.428L97.2119 136.845L106.055 132.417L103.843 128Z" fill="#FCEE21"/>
        <path d="M111 85.77C111.37 81.73 114.31 79.22 116.92 79C116.63 79.83 116.61 80.62 116.77 81.3C114.8 81.91 112.83 84.68 113.8 88.27C112.74 87.68 111.77 86.83 111 85.77Z" fill="#662D91"/>
        <path d="M92.0295 112L86 117.104L88.7719 120.379L94.8014 115.274L92.0295 112Z" fill="#ED1E79"/>
        <path d="M104.277 42.0003L101 46.0509L108.464 52.0885L111.74 48.0378L104.277 42.0003Z" fill="#ED1E79"/>
      </g>
    </Illustration>
  )
}

export default EmailDesignIllustration;