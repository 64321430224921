import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PieceTypesMetaData from '../constants/PieceTypesMetaData';
import formatCurrency from '../helpers/FormatCurrency';
import getAvailability from '../helpers/GetAvailability';
import IconButton from './IconButton';
import PieceModal from './PieceModal';
import { useAppContext } from '../context/app';
import CloudinaryImage from './CloudinaryImage';

const ActivePieceTypePreviewWrapper = styled.div`
  position: relative;
  margin: 0 1rem 0.625rem;
  padding: 1rem 1.15rem 0.75rem;
  border: 2px solid var(--electric-lime);
  background: var(--eggshell);
  
  @media (min-width: 50em) {
    margin: 0 0 0.5rem;
    grid-area: preview;
    display: grid;
    grid-template-areas:
      "image"
      "content"
    ;
  }
  @media (min-width: 64em) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 0.625rem;
    margin: 0 0 1.5rem;
    grid-template-areas:
      "content image"
    ;
  }
`;

const ActivePieceTypePreviewActions = styled.div`
  position: absolute;
  top: 0.625rem;
  right: 1rem;
`;

const ActivePieceTypePreviewImageWrapper = styled.div`
  position: relative;
  pointer-events: none;
  display: none;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom 1.5rem left 50%;
    transition: opacity 0.15s;
    transform: scale(1.25);
  }
  @media (min-width: 50em) {
    display: block;
    grid-area: image;
    width: 100%;
    padding-bottom: 66%;
  }
`;
const ActivePieceTypePreviewContent = styled.div`
  width: 100%;  
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-areas:
    "title price"
    "available available"
  ;
  @media (min-width: 50em) {
    grid-area: content;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "description"
      "pricetitle"
      "price"
      "available"
  }
  h3 {
    grid-area: title;
    margin: 0;
    color: var(--dark-tyrian-blue);
  }
  p {
    margin: 0 0 1em;
    font: var(--body-small);
    display: none;
    &.availability {
      grid-area: available;
      font: var(--subheading);
      color: var(--rtg-dark-blue);
      text-transform: uppercase;
      display: block;
      margin: 0;
      &:before {
        content: '';
        display: inline-block;
        width: 0.5625rem;
        height: 0.5625rem;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
      &.green {
        &:before {
          background-color: #08A160;
        }
      }
      &.yellow {
        &:before {
          background-color: #F7C824;
        }
      }
      &.orange {
        &:before {
          background-color: #F28808;
        }
      }
      &.red {
        &:before {
          background-color: #DC2932;
        }
      }
    }
    @media (min-width: 50em) {
      display: block;
      grid-area: description;
    }
  }
  h4 {
    font: var(--subheading);
    color: var(--rtg-dark-blue);
    text-transform: uppercase;
    margin: 0;
    display: none;
    @media (min-width: 50em) {
      display: block;
      grid-area: pricetitle;
    }
  }
  h5 {
    grid-area: price;
    margin: 0 2rem 0 0;
    font: var(--body-medium-semibold);
    text-align: right;
    color: var(--dark-tyrian-blue);
    @media (min-width: 50em) {
      margin: 0;
      font: var(--h-large);
      text-align: left;
    }
  }
`;

const ActivePieceTypePreview = ({ activePieceType }) => {
  // console.log('[activePieceType]', activePieceType);
  const { activeColor, apiProducts } = useAppContext();
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  const activePieceTypeMeta = PieceTypesMetaData[activePieceType];
  const price = apiProducts[activeColor][activePieceType].price;
  
  const isAvailable = apiProducts[activeColor][activePieceType].availability  === 'Available' ? true : false;
  const availableDate = apiProducts[activeColor][activePieceType].availability === 'Available' ? '' : 'Available '+apiProducts[activeColor][activePieceType].availability+' ';
  const availabilityMeta = getAvailability(apiProducts[activeColor][activePieceType]?.availability);
  return (
    <>
      <ActivePieceTypePreviewWrapper
        data-is-available={availabilityMeta.color}
      >
        <ActivePieceTypePreviewActions>
          <IconButton
            type="ArrowsOut"
            ariaLabel={`view more`}
            clickHandler={() => toggleModal()}
          />
        </ActivePieceTypePreviewActions>
        <ActivePieceTypePreviewContent>
          <h3>{activePieceTypeMeta.name}</h3>
          <p>{activePieceTypeMeta.description}</p>
          <h4>Price</h4>
          <h5>{formatCurrency(price)}</h5>
          <p className={"availability "+availabilityMeta.color}>{availabilityMeta.text}</p>
        </ActivePieceTypePreviewContent>
        <ActivePieceTypePreviewImageWrapper>
          <CloudinaryImage
            imageId={`${activeColor}/three-quarters/${activePieceType}`}
            width={360}
            height={360}
            alt={activePieceTypeMeta.name}
          />
        </ActivePieceTypePreviewImageWrapper>
      </ActivePieceTypePreviewWrapper>
      {showModal && (
        <PieceModal pieceType={activePieceType} closeHandler={toggleModal} />
      )}
    </>
  );
};

export default ActivePieceTypePreview;
