const getOverlappingMatrixPiece = (piecesMatrix, newPiece) => {
  let overlappingPiece = null;
  // check all pieces in the piecesMatrix
  for (let key in piecesMatrix) {
    // if we are checking the same piece return null
    if (
      `${piecesMatrix[key].pieceType}${piecesMatrix[key].x}${piecesMatrix[key].y}` ===
      `${newPiece.pieceType}${newPiece.x}${newPiece.y}`
    ) {
      overlappingPiece = null;
      break;
    }
   
    // NE
    // if newPiece falls inside a matrixPiece    
    if (
      (newPiece.coords.NE.x >= piecesMatrix[key].coords.NW.x && newPiece.coords.NE.x <= piecesMatrix[key].coords.SE.x) && 
      (newPiece.coords.NE.y >= piecesMatrix[key].coords.NW.y && newPiece.coords.NE.y <= piecesMatrix[key].coords.SE.y) 
    ) {      
      overlappingPiece = piecesMatrix[key]; 
      break;
    };
    // if matrixPiece falls inside the newPiece
    if (
      (piecesMatrix[key].coords.NE.x >= newPiece.coords.NW.x && piecesMatrix[key].coords.NE.x <= newPiece.coords.SE.x) && 
      (piecesMatrix[key].coords.NE.y >= newPiece.coords.NW.y && piecesMatrix[key].coords.NE.y <= newPiece.coords.SE.y) 
    ) {      
      overlappingPiece = piecesMatrix[key]; 
      break;
    };

    // NW
    // if newPiece falls inside a matrixPiece
    if (
      (newPiece.coords.NW.x >= piecesMatrix[key].coords.NW.x && newPiece.coords.NW.x <= piecesMatrix[key].coords.SE.x) && 
      (newPiece.coords.NW.y >= piecesMatrix[key].coords.NW.y && newPiece.coords.NW.y <= piecesMatrix[key].coords.SE.y) 
    ) {
      overlappingPiece = piecesMatrix[key]; 
      break;
    };
    // if matrixPiece falls inside the newPiece
    if (
      (piecesMatrix[key].coords.NW.x >= newPiece.coords.NW.x && piecesMatrix[key].coords.NW.x <= newPiece.coords.SE.x) && 
      (piecesMatrix[key].coords.NW.y >= newPiece.coords.NW.y && piecesMatrix[key].coords.NW.y <= newPiece.coords.SE.y) 
    ) {
      overlappingPiece = piecesMatrix[key]; 
      break;
    };

    // SE
    // if newPiece falls inside a matrixPiece
    if (
      (newPiece.coords.SE.x >= piecesMatrix[key].coords.NW.x && newPiece.coords.SE.x <= piecesMatrix[key].coords.SE.x) && 
      (newPiece.coords.SE.y >= piecesMatrix[key].coords.NW.y && newPiece.coords.SE.y <= piecesMatrix[key].coords.SE.y) 
    ) {
      overlappingPiece = piecesMatrix[key]; 
      break;
    };
    // if matrixPiece falls inside the newPiece
    if (
      (piecesMatrix[key].coords.SE.x >= newPiece.coords.NW.x && piecesMatrix[key].coords.SE.x <= newPiece.coords.SE.x) && 
      (piecesMatrix[key].coords.SE.y >= newPiece.coords.NW.y && piecesMatrix[key].coords.SE.y <= newPiece.coords.SE.y) 
    ) {
      overlappingPiece = piecesMatrix[key]; 
      break;
    };

    // SW
    // if newPiece falls inside a matrixPiece
    if (
      (newPiece.coords.SW.x >= piecesMatrix[key].coords.NW.x && newPiece.coords.SW.x <= piecesMatrix[key].coords.SE.x) && 
      (newPiece.coords.SW.y >= piecesMatrix[key].coords.NW.y && newPiece.coords.SW.y <= piecesMatrix[key].coords.SE.y) 
    ) {
      overlappingPiece = piecesMatrix[key]; 
      break;
    };
    // if matrixPiece falls inside the newPiece
    if (
      (piecesMatrix[key].coords.SW.x >= newPiece.coords.NW.x && piecesMatrix[key].coords.SW.x <= newPiece.coords.SE.x) && 
      (piecesMatrix[key].coords.SW.y >= newPiece.coords.NW.y && piecesMatrix[key].coords.SW.y <= newPiece.coords.SE.y) 
    ) {
      overlappingPiece = piecesMatrix[key]; 
      break;
    };

  }
  return overlappingPiece;

}

export default getOverlappingMatrixPiece;