/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 NOmedia-Console.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function Console(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/Console.glb'))
  // const { nodes, materials } = useGLTF('m23d/Console.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.17,0,0.525]}>
        <mesh geometry={nodes.RTG_MT_WEDGE_WOOD_HARDWARE005_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_WOOD_HARDWARE002_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_WOOD_HARDWARE004_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHTINING_PARTS007_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHTINING_PARTS006_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHTINING_PARTS005_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHTINING_PARTS002_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHTINING_PARTS003_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHTINING_PARTS000_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_STICHES001_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_UPHOLSTERY_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_STICHES001_n3d2.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_STICHES000_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_STICHES000_n3d2.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_POWEROUTPUT004_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_POWEROUTPUT003_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_POWEROUTPUT002_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_POWEROUTPUT001_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_POWEROUTPUT000_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_RUBBER_FEET001_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_WEDGE_LIGHT_RUBBER_FEET000_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_WEDGE_WOOD_HARDWARE000_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_WOOD_HARDWARE001_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_WEDGE_WOODBOARD_n3d.geometry} material={props.materials.wood} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/Console.glb'))
// useGLTF.preload('m23d/Console.glb')
