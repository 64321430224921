/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 Wedge.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function Wedge(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/Wedge.glb'))
  // const { nodes, materials } = useGLTF('m23d/Wedge.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.83,0,0.84]} scale={[1.03,1,1.025]} rotation={[0, 1.585, 0]}>
        <mesh geometry={nodes.RTG_MT_CORNER_FEET_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.RTG_MT_CORNER_BASE_UPHOLSTERY_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_019_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER_CORNER2_Retopo_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_020_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER_CORNER1_Retopo_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_018_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_SEATCUSHIONS_CORNER000_Retopo_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Back_Standing023_n3d.geometry} material={props.materials.fabric} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/Wedge.glb'))
// useGLTF.preload('m23d/Wedge.glb')
