import * as htmlToImage from 'html-to-image';
import { IN_SAFARI } from '../../constants';

// See https://github.com/bubkoo/html-to-image#options for all available options.
const RENDERED_IMAGE_OPTIONS = { canvasWidth: 350, canvasHeight: 350 };

const renderNodeToJpeg = async (node) => {
  // Safari/iOS struggle with this kind of rendering - basically not all the assets are loaded
  // before render. The "solution" is to render the image several times to allow it to fully
  // load before returning the data URL for saving. Rendering it three times total is arbitrary
  // and could be increased if certain devices struggle to load everything in time.
  // See: https://github.com/bubkoo/html-to-image/issues/361#issuecomment-1402537176
  if (IN_SAFARI) {
    await htmlToImage.toJpeg(node, RENDERED_IMAGE_OPTIONS);
    await htmlToImage.toJpeg(node, RENDERED_IMAGE_OPTIONS);
  }

  const dataUrl = await htmlToImage.toJpeg(node, RENDERED_IMAGE_OPTIONS);

  return dataUrl;
};

export default renderNodeToJpeg;
