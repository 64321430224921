import { createRoot } from 'react-dom/client'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import styled from 'styled-components';
import { Canvas, useFrame } from '@react-three/fiber'
import {
  PerspectiveCamera,
  CameraControls,
  ContactShadows,
  BakeShadows,
} from '@react-three/drei';
import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { CONFIGURATOR_GRID_PAD } from '../../constants';
import * as Button from './Button';
import { Armless } from './m23d/Armless';
import { ArmlessRecliner } from './m23d/ArmlessRecliner';
import { Wedge } from './m23d/Wedge';
import { LeftRecliner } from './m23d/LAF-Recliner';
import { RightRecliner } from './m23d/RAF-Recliner';
import { LeftChaise } from './m23d/LAF-Chaise';
import { RightChaise } from './m23d/RAF-Chaise';
import { Console } from './m23d/Console';
import { MediaConsole } from './m23d/Media-Console';

import PieceTypes from '../constants/PieceTypesMetaData';
import { cloudinary3dAsset, cloudinary3dFile } from '../helpers/CloudinaryHelpers';
import { useAppContext } from '../context/app';

// Calculate the position in the viewer (voxel) based on pixel value
// NOTE: we know that 75 translates to 0.98
const pixelToVoxel = (n) => {  
  return (n*0.98)/158;
}

const degreesToRadiens = (degrees) => {
  const pi = Math.PI;
  return degrees * (pi / 180);
}

const calcPiecePosition = (piece) => {
  const pieceMeta = PieceTypes[piece.pieceType];
  // Flag: our 'x,y' is 3d 'x,z' but this can be cleaned up later
  // r0
  let paddingShift = -CONFIGURATOR_GRID_PAD;
  let calcX = pixelToVoxel(piece.x + paddingShift);
  let calcY = pixelToVoxel(piece.y + paddingShift);
  let calcZ = 0;
  let calcR = degreesToRadiens(360 - piece.rotation);

  // r90
  // 270 for real
  // translate right by piece Width (height at 90deg)
  if (piece.rotation === 90) {
    calcX = calcX +  pixelToVoxel(pieceMeta.rotation[90].width);
  }

  // r180
  // 180 for real :) no need to change r
  // translate down by piece height
  // translate right by piece width
  if (piece.rotation === 180) {
    calcX = calcX +  pixelToVoxel(pieceMeta.rotation[180].width);
    calcY = calcY +  pixelToVoxel(pieceMeta.rotation[180].height);
  }

  // r270
  // 90 for real
  // translate down by piece height
  if (piece.rotation === 270) {
    calcY = calcY +  pixelToVoxel(pieceMeta.rotation[270].height);   
  }

  // r45
  // 315 for real
  // translate right by piece productWidth (height at 90deg)
  if (piece.rotation === 45) {
    calcX = calcX +  pixelToVoxel(pieceMeta.rotation[45].offsetX);
    calcY = calcY +  pixelToVoxel(pieceMeta.rotation[45].offsetY);
  }

  // r135
  // 225 for real
  // translate right by piece Width (plus 20 for some reason) (height at 90deg)
  // translate down by piece productDepth
  if (piece.rotation === 135) {
    calcX = calcX +  pixelToVoxel(pieceMeta.rotation[135].offsetX);
    calcY = calcY +  pixelToVoxel(pieceMeta.rotation[135].offsetY);
  }

  // r225
  // 135 for real
  // translate right by piece productWidth (height at 90deg)
  // translate down by piece height (plus 20 for some reason)
  if (piece.rotation === 225) {
    calcX = calcX +  pixelToVoxel(pieceMeta.rotation[225].offsetX);
    calcY = calcY +  pixelToVoxel(pieceMeta.rotation[225].offsetY);
  }

  // r315
  // 45 for real
  // translate right by piece productWidth (height at 90deg)
  if (piece.rotation === 315) {
    calcX = calcX - pixelToVoxel(pieceMeta.rotation[315].offsetX);
    calcY = calcY +  pixelToVoxel(pieceMeta.rotation[315].offsetY);
  }

  return {
    x: calcX,
    y: calcY,
    z: calcZ,
    r: calcR
  }
}

const pieceTypeMap = {
  armless: Armless,
  armlessRecliner: ArmlessRecliner,
  corner: Wedge,
  reclinerLeft: LeftRecliner,
  reclinerRight: RightRecliner,
  chaiseRight: RightChaise,
  chaiseLeft: LeftChaise,
  console: Console,
  consoleTech: MediaConsole
}

const pieceColorMap = {
  'TERAMO SADDLE': [ 
    cloudinary3dAsset('/m23d/Saddle_Diffuse.webp'),
    cloudinary3dAsset('/m23d/Saddle_leather.webp')
  ],
  'TERAMO CHARCOAL': [ 
    cloudinary3dAsset('/m23d/Charcoal_Diffuse.webp'),
    cloudinary3dAsset('/m23d/Charcoal_leather.webp')
  ],
};
const woodMap = [
  cloudinary3dAsset('/m23d/Wood_Bump.webp'),
  cloudinary3dAsset('/m23d/Wood_Diffuse.webp')
]

const envFile = cloudinary3dFile('3d/empty_warehouse_01_2k.hdr');


const RotateWrapper = styled.div`
  position: absolute;
  display: inline-block;
  z-index: 1;
  bottom: 2rem;
  left: 50%;
  transform:translateX(-50%);
  button {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0.125rem 0.1875rem rgba(0,0,0,0.05);
    div {
      position: static;
      transform: none;
    }
  }
`;
const generatePieceMaterials = (fabricVisible, bumptexture, diffusetexture, envReflections, woodBump, woodDiffuse) => {
  
  return {
    "fabric": new THREE.MeshPhysicalMaterial({
      bumpMap: bumptexture,
      bumpScale: 0.005,
      map: diffusetexture,
      reflectivity: 0,
      refractionRatio: 1,
      dithering: true,
      clearcoat: 0.25,
      clearcoatRoughness: 0.45,
      visible: fabricVisible,
    }),
    "glass": new THREE.MeshPhysicalMaterial({
      color: 'white', 
      envMap: envReflections,
      envMapIntensity: 1.5,
      transmission:.97,
      roughness:0,
      thickness:0.5,
      visible: fabricVisible,
    }),
    "wood": new THREE.MeshPhysicalMaterial({
      emissive: 'rgba(25,9,5,255)',
      emissiveIntensity: 1,
      bumpMap: woodBump,
      bumpScale: 0.5,
      map: woodDiffuse,
      reflectivity: 0.45,
      refractionRatio: 1,
      dithering: true,
      clearcoat: 0.2,
      clearcoatRoughness: 0.4,
      visible: fabricVisible,
    }),
    "panel": new THREE.MeshStandardMaterial({ 
      color: "rgba(80,80,80,255)", 
      roughness:.3, 
      metalness:.5,
      visible: fabricVisible, 
    }),
    "feet": new THREE.MeshStandardMaterial({ color: "#000000", visible: fabricVisible, })
  };
}
const Viewer = ({ config }) => {
  console.log('[configuration]', config.configuration);
  const { activeColor } = useAppContext();
  const [viewAngle, setViewAngle] = useState(0);
  const [pieceMaterials, setPieceMaterials] = useState(null);
  const [bumptexture, setBumptexture] = useState(null);
  const [bumpLoaded, setBumpLoaded] = useState(false);
  const [woodBump, setWoodBump] = useState(null);
  const [woodBumpLoaded, setWoodBumpLoaded] = useState(false);
  const [woodDiffuse, setWoodDiffuse] = useState(null);
  const [woodDiffuseLoaded, setWoodDiffuseLoaded] = useState(false);
  const [diffusetexture, setDiffusetexture] = useState(null);
  const [diffuseLoaded, setDiffuseLoaded] = useState(false);
  const [envReflections, setEnvReflections] = useState(null);
  const [envLoaded, setEnvLoaded] = useState(false);
  useEffect(() => {
    const manager = new THREE.LoadingManager()

    const bump = new THREE.TextureLoader().load(pieceColorMap[activeColor][0], () => {setBumpLoaded(true);});
    bump.wrapS = THREE.RepeatWrapping;
    bump.wrapT = THREE.RepeatWrapping;
    bump.repeat.set(0.5, 0.5);
    bump.encoding = THREE.sRGBEncoding;
    setBumptexture(bump);

    const diffuse = new THREE.TextureLoader().load(pieceColorMap[activeColor][1], () => {setDiffuseLoaded(true);});
    diffuse.wrapS = THREE.RepeatWrapping;
    diffuse.wrapT = THREE.RepeatWrapping;
    diffuse.repeat.set(0.7, 0.7);
    diffuse.encoding = THREE.sRGBEncoding;
    setDiffusetexture(diffuse);

    const woodB = new THREE.TextureLoader().load(woodMap[0], () => {setWoodBumpLoaded(true);});
    woodB.wrapS = THREE.RepeatWrapping;
    woodB.wrapT = THREE.RepeatWrapping;
    woodB.repeat.set(2, 2);
    woodB.encoding = THREE.sRGBEncoding;
    setWoodBump(woodB);

    const woodD = new THREE.TextureLoader().load(woodMap[1], () => {setWoodDiffuseLoaded(true);});
    woodD.wrapS = THREE.RepeatWrapping;
    woodD.wrapT = THREE.RepeatWrapping;
    woodD.repeat.set(0.15, 0.15);
    woodD.encoding = THREE.sRGBEncoding;
    setWoodDiffuse(woodD);

    // let renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    // let envmaploader = new THREE.PMREMGenerator(renderer);
    const env = new RGBELoader().load(envFile, () => {
      // envmaploader.fromCubemap(env);
      env.mapping = THREE.EquirectangularReflectionMapping;
      setEnvLoaded(true);
      console.log('[env loaded]', envLoaded);
      setEnvReflections(env); 
    });
    
  },[]);
  useEffect(() => {
    if(bumptexture && bumpLoaded && diffusetexture && diffuseLoaded && envReflections && envLoaded && woodBumpLoaded && woodDiffuseLoaded){
      setPieceMaterials(generatePieceMaterials(0, bumptexture, diffusetexture, envReflections, woodBump, woodDiffuse));
      setTimeout(()=>{
        setPieceMaterials(generatePieceMaterials(1, bumptexture, diffusetexture, envReflections, woodBump, woodDiffuse));
      },100);
    }
  },[bumptexture, bumpLoaded, diffusetexture, diffuseLoaded, envReflections, envLoaded, woodBumpLoaded, woodDiffuseLoaded]);
  const cameraRef = useRef(null);
  const cameraControlsRef = useRef(null);

  const {
    name,
    configuration,
    productDepth,
    productHeight,
    productWidth
  } = config;

  //use different camera views for mobile vs desktop
  const defaultView = window.innerWidth < 1024 ? [5, 2, 5] : [2, 2, 2];
  
  const presetViews = window.innerWidth < 1024 ?
    [
      ['rotate',[1, 1, true ]],
      ['look',[0,-5,20,true]],
      ['setPosition',[5,5,5,true]],
      ['setPosition',[1,5,1,true]],
      ['setPosition',[5,2,5,true]],
    ]
    :
    [
      ['rotate',[1, 1, true ]],
      ['look',[(pixelToVoxel(productWidth) * -0.125),-7,20,true]],
      ['setPosition',[1,2,3,true]],
      ['setPosition',[1,5,1,true]],
      ['setPosition',[2,2,2,true]],
    ];

  const rotateCamera = useCallback(() => {
    console.log('presetview', presetViews[viewAngle], pixelToVoxel(productDepth));
    if(presetViews[viewAngle][0] === 'setPosition') {
      cameraControlsRef.current?.setTarget((pixelToVoxel(productWidth)*0.5),0,(pixelToVoxel(productDepth)*0.5), true);
      cameraControlsRef.current?.setPosition(...presetViews[viewAngle][1]);
    } else if(presetViews[viewAngle][0] === 'rotate') {
      cameraControlsRef.current?.setTarget((pixelToVoxel(productWidth)),(pixelToVoxel(productHeight)*0.5),(pixelToVoxel(productDepth)*0.5), true);
      cameraControlsRef.current?.rotate(...presetViews[viewAngle][1]);
    } else if(presetViews[viewAngle][0] === 'look') {
      cameraControlsRef.current?.setTarget((pixelToVoxel(productWidth)*0.75),0,0, true);
      cameraControlsRef.current?.lookInDirectionOf(...presetViews[viewAngle][1]);
    } else {
      console.log('invalid preset view encountered');
    }
    
    setViewAngle(viewAngle < presetViews.length-1 ? viewAngle+1 : 0);
    
    // other methods commented out for reference
    // cameraControlsRef.current?.lookInDirectionOf( 0, 0, 10, true );
    // cameraControlsRef.current?.rotate( azimuthAngle, polarAngle, enableTransition )
    // cameraControlsRef.current?.rotate( .75, 0, true );
    // cameraControlsRef.current?.setPosition( positionX, positionY, positionZ, enableTransition )
  }, [cameraControlsRef, viewAngle]);

  
  if(!pieceMaterials) {
    return null;
  }
  return (
    <>
      <Canvas 
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: "100%",
          height: "100%"
        }}
        camera={{ position: defaultView, fov: 60 }}
    >
        <CameraControls
          ref={cameraControlsRef}
          maxDistance={10}
          minDistance={3.12}
          minPolarAngle={-1}
          maxPolarAngle={1.5}
          boundaryFriction={0.5}
          // additional props
        />
        <PerspectiveCamera
          ref={cameraRef}
          // additional props
        />

        <hemisphereLight
          intensity={2}
          color={0xffffff}
          groundColor={0x111111}
        />
        <spotLight 
          position={[8,3,3]}
          intensity={[1]}
          penumbra={[1]}
        />
        <spotLight 
          position={[-5,3,4]}
          intensity={[2]}
          penumbra={[1]}
        />
        <spotLight 
          position ={[1,6,1]}
          intensity={[1]}
          penumbra={[1]}
        />

        <group position={[pixelToVoxel(productWidth)*-1, 0, pixelToVoxel(productDepth)*-1]} >
          {configuration.map((piece, index) => {
            const PieceComponent = pieceTypeMap[piece.pieceType];
            const pos = calcPiecePosition(piece);
            
            return (
              <PieceComponent 
                key={`piece-${index}`}
                position={[pos.x, 0, pos.y]}
                rotation={[0, pos.r, 0]}
                materials={pieceMaterials}
              />
            )
          })}        
        </group>
        <ContactShadows
          frames={1}
          resolution={1024} 
          scale={10}
          position={[0, 0, 0]}
          opacity={0.5}
          blur={.2}
          />
        <BakeShadows/>
      </Canvas>
      <RotateWrapper>
        <Button.OutlineUI
          icon='rotate'
          clickHandler={rotateCamera}
        >Rotate</Button.OutlineUI>
      </RotateWrapper>
    </>
  )
}

export default Viewer;
