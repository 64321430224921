import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../context/app';
import CloudinaryImage from './CloudinaryImage';
import { COLOR_NAME_MAP } from '../../constants';
const ColorMenuWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  gap: 1.375rem;
  &[data-size='small'] {
    justify-content: flex-start;
    gap: 0.25rem;
    li {
      height: 3rem;
    }
  }
  @media (min-width: 50em) {
    gap: 0.5rem;
  }
  @media (min-width: 64em) {
    justify-content: flex-start;
  }
`;

const ColorMenuOptionControl = styled.button`
  width: 3.75rem;
  &[data-size='small'] {
    width: 3rem;
    height: 3rem;
  }
  &:hover,
  &:focus-visible {
    div::before {
      transform: scale(1.1);
    }
  }
  &[data-active='on'] {
    pointer-events: none;
  }
  @media (min-width: 64em) {
    width: 5rem;
  }
`;

const ColorMenuOptionSwatch = styled.div`
  position: relative;
  width: 3.75rem;
  height: 3.75rem;
  border: 1px solid transparent;
  border-radius: 50%;
  &[data-size='small'] {
    width: 2.625rem;
    height: 2.625rem;
    margin: 0 auto;
    &[data-active='on'] {
      width: 3rem;
      height: 3rem;
    }
    img {
      top: 7.5%;
      bottom: 7.5%;
      left: 7.5%;
      right: 7.5%;
      width: 85%;
      height: 85%;
    }
  }
  &[data-active='on'] {
    border-color: var(--dark-tyrian-blue);
  }
  img {
    position: absolute;
    transition: all 0.15s;
    top: 6.45%;
    bottom: 6.45%;
    left: 6.45%;
    right: 6.45%;
    width: 87.1%;
    height: 87.1%;
    overflow: hidden;
    border-radius: 50%;
    background-size: cover;
  }

  @media (min-width: 64em) {
    width: 5rem;
    height: 5rem;
  }
`;

const ColorMenuOptionLabel = styled.span`
  display: block;
  margin-top: 0.125rem;
  text-align: center;
  font: var(--body-small);
  height: 1rem;
  color: var(--dark-gray);
  &[data-size='small'] {
    display: none;
  }
`;

const ColorMenuOption = ({ size, color, activeColor, setActiveColor }) => {
  const clickHandler = useCallback(() => {
    setActiveColor(color);
  }, [color, setActiveColor]);

  return (
    <li data-size={size}>
      <ColorMenuOptionControl
        aria-pressed={color === activeColor}
        onClick={clickHandler}
        aria-label={color}
        data-active={color === activeColor ? 'on' : ''}
        data-size={size}
      >
        <ColorMenuOptionSwatch
          data-active={color === activeColor ? 'on' : ''}
          color={color}
          data-size={size}
        >
          <CloudinaryImage
            imageId={`${color}/swatch`}
            width={300}
            height={300}
            alt={`${color} color swatch`}
          />
        </ColorMenuOptionSwatch>
        <ColorMenuOptionLabel data-size={size}>
          {COLOR_NAME_MAP[color]}
        </ColorMenuOptionLabel>
      </ColorMenuOptionControl>
    </li>
  );
};

const ColorMenu = ({ size, customActiveColor, customSetActiveColor }) => {
  const { activeColor, setActiveColor, availableColors } = useAppContext();
  const activeMenuColor = customActiveColor || activeColor;
  const setActiveMenuColor = customSetActiveColor || setActiveColor;

  return (
    <ColorMenuWrapper data-size={size}>
      {availableColors.map((color, index) => {
        return (
          <ColorMenuOption
            key={`color-menu-option-${index}`}
            size={size}
            color={color}
            activeColor={activeMenuColor}
            setActiveColor={setActiveMenuColor}
          />
        );
      })}
    </ColorMenuWrapper>
  );
};

export default ColorMenu;
