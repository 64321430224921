import PieceTypesMetaData from "../constants/PieceTypesMetaData";
/*
  Define all the info and metadata for a piece from
  - pieceType
  - rotation
  - x
  - y
*/
const defineMatrixPiece = (pieceType, rotation, x, y, groupNum=1) => {
  // console.log('solomonitor', 'definematrixpiece', pieceType, rotation, x, y);  
  const pieceTypeMetaData = PieceTypesMetaData[pieceType];
  const price = pieceTypeMetaData.price;
  const width = pieceTypeMetaData.rotation[rotation].width;
  const height = pieceTypeMetaData.rotation[rotation].height;
  const productHeight = pieceTypeMetaData.productHeight;
  const productWidth = pieceTypeMetaData.rotation[rotation].productWidth;
  const productDepth = pieceTypeMetaData.rotation[rotation].productDepth;
  const xMax = x + width - 1;
  const yMax = y + height - 1;
  const NW = {x: x, y: y};
  const NE = {x: xMax, y: y};
  const SE = {x: xMax, y: yMax};
  const SW = {x: x, y: yMax};
  const connects = pieceTypeMetaData.rotation[rotation].connects;
  return (
    {      
      groupNum: groupNum,
      pieceType: pieceType,
      x: x,
      y: y,
      coords: {NW: NW, NE: NE, SE: SE, SW: SW},
      width: width,
      height: height,
      rotation: rotation,
      connects: connects,
      // highlighted: highlighted,
      meta: {
        price: price,
        productHeight: productHeight,
        productWidth: productWidth,
        productDepth: productDepth
      },
      neighbor: {
        north: null,
        east: null,
        south: null,
        west: null,
      }
    }
  )
}

export default defineMatrixPiece;