import getNeighbor from "./GetNeighbor";

function isTouchingHorizontal(seg1, seg2) {
  return (seg1.endX + 1 === seg2.startX && seg1.endY === seg2.startY) ||
         (seg1.startX === seg2.endX + 1 && seg1.startY === seg2.endY);
}

function combineSegmentsHorizontal(seg1, seg2) {
  if (seg1.endX + 1 === seg2.startX && seg1.endY === seg2.startY) {
    return { startX: seg1.startX, startY: seg1.startY, endX: seg2.endX, endY: seg2.endY };
  } else {
    return { startX: seg2.startX, startY: seg2.startY, endX: seg1.endX, endY: seg1.endY };
  }
}

function simplifySegmentsHorizontal(segments) {
  let simplified = true;
  while (simplified) {
    simplified = false;
    for (let i = 0; i < segments.length - 1 && !simplified; i++) {
      for (let j = i + 1; j < segments.length; j++) {
        if (isTouchingHorizontal(segments[i], segments[j])) {
          segments[i] = combineSegmentsHorizontal(segments[i], segments[j]);
          segments.splice(j, 1);
          simplified = true;
          break;
        }
      }
    }
  }
  return segments;
}

function isTouchingVertical(seg1, seg2) {
  return (seg1.endX === seg2.startX && seg1.endY + 1 === seg2.startY) ||
         (seg1.startX === seg2.endX && seg1.startY === seg2.endY + 1);
}

function combineSegmentsVertical(seg1, seg2) {
  if (seg1.endX === seg2.startX && seg1.endY + 1 === seg2.startY) {
    return { startX: seg1.startX, startY: seg1.startY, endX: seg2.endX, endY: seg2.endY };
  } else {
    return { startX: seg2.startX, startY: seg2.startY, endX: seg1.endX, endY: seg1.endY };
  }
}

function simplifySegmentsVertical(segments) {
  let simplified = true;
  while (simplified) {
    simplified = false;
    for (let i = 0; i < segments.length - 1 && !simplified; i++) {
      for (let j = i + 1; j < segments.length; j++) {
        if (isTouchingVertical(segments[i], segments[j])) {
          segments[i] = combineSegmentsVertical(segments[i], segments[j]);
          segments.splice(j, 1);
          simplified = true;
          break;
        }
      }
    }
  }
  return segments;
}

const defineDimensions = (piecesMatrix) => {
  const dimensions = {
    north: [],
    south: [],
    east: [],
    west: [],
  };
  Object.keys(piecesMatrix).map((key) => {
    const thisPiece = piecesMatrix[key];
    // Check North
    const northNeighbor = getNeighbor('north', piecesMatrix, thisPiece.x, thisPiece.y);
    // If no northNeighbor, save the top corners coordinates in dimensions.north array
    if (!northNeighbor) {
      dimensions.north.push({
        startX: thisPiece.coords.NW.x,
        startY: thisPiece.coords.NW.y,
        endX: thisPiece.coords.NE.x,
        endY: thisPiece.coords.NE.y,
      });
    }
    // Check South
    const southNeighbor = getNeighbor('south', piecesMatrix, thisPiece.x, thisPiece.y);
    // If no southNeighbor, save the bottom corners coordinates in dimensions.south array
    if (!southNeighbor) {
      dimensions.south.push({
        startX: thisPiece.coords.SW.x,
        startY: thisPiece.coords.SW.y,
        endX: thisPiece.coords.SE.x,
        endY: thisPiece.coords.SE.y,
      });
    }
    // Check East
    const eastNeighbor = getNeighbor('east', piecesMatrix, thisPiece.x, thisPiece.y);
    // If no eastNeighbor, save the right corners coordinates in dimensions.east array
    if (!eastNeighbor) {
      dimensions.east.push({
        startX: thisPiece.coords.NE.x,
        startY: thisPiece.coords.NE.y,
        endX: thisPiece.coords.SE.x,
        endY: thisPiece.coords.SE.y,
      });
    }
    // Check West
    const westNeighbor = getNeighbor('west', piecesMatrix, thisPiece.x, thisPiece.y);
    // If no westNeighbor, save the left corners coordinates in dimensions.west array
    if (!westNeighbor) {
      dimensions.west.push({
        startX: thisPiece.coords.NW.x,
        startY: thisPiece.coords.NW.y,
        endX: thisPiece.coords.SW.x,
        endY: thisPiece.coords.SW.y,
      });
    }
  });

  console.log('*defineDimensions', 'dimensions', dimensions);

  const simplifiedDimensions = {
    north: simplifySegmentsHorizontal(dimensions.north),
    south: simplifySegmentsHorizontal(dimensions.south),
    east: simplifySegmentsVertical(dimensions.east),
    west: simplifySegmentsVertical(dimensions.west)
  };

  return simplifiedDimensions;
}

export default defineDimensions;