import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import formatCurrency from '../helpers/FormatCurrency';
import getAvailability from '../helpers/GetAvailability';
import PieceModal from './PieceModal';
import { useAppContext } from '../context/app';
import CloudinaryImage from './CloudinaryImage';

const StyledPriceTableRow = styled.tr`
    position: relative;
    td {
        &.icon {
            cursor: pointer;
            background: var(--offwhite);
            width: 1.875rem;
            img {
                vertical-align: bottom;
                width: 100%;
                height: auto;
            }
            div {
                height: 1.875rem;
            }
        }
        &.availabilityIcon {
            width: 0.5625rem;
            &:before {
                content: '';
                display: inline-block;
                width: 0.5625rem;
                height: 0.5625rem;
                border-radius: 50%;
                margin-right: 0.5rem;
              }
              &.green {
                &:before {
                  background-color: #08A160;
                }
              }
              &.yellow {
                &:before {
                  background-color: #F7C824;
                }
              }
              &.orange {
                &:before {
                  background-color: #F28808;
                }
              }
              &.red {
                &:before {
                  background-color: #DC2932;
                }
              }
        }
        &.name {
            cursor: pointer;
            padding-left: 0.5rem;
            div {
                height: 0.875rem;
                background: var(--light-gray);
                width: 10rem;
            }
            .availableDate {
                color: red;
            }
            @media (min-width: 50em) {
                padding-left: 1.5rem;
            }
        }
        &.price {
            text-align: right;
            white-space: nowrap;
            div {
                float: right;
                height: 0.875rem;
                background: var(--light-gray);
                width: 5rem;
            }
            span.basePrice {
                display: none;
            }
            @media (min-width: 30em) {
                span.basePrice {
                display: inline;
                }
            }
            @media (min-width: 50em) {
                span.basePrice {
                display: none;
                }
            }
        }
        font: var(--body-medium);
        b {
            font: var(--body-medium-semibold);
            color: var(--dark-tyrian-blue);
        }
    }
    &:hover {
        .datemessage {
            opacity: 1;
        }
    }
`;

const DateMessage = styled.div`
    position: absolute;
    top: -0.75rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--rtg-dark-blue);
    border-radius: 0.25rem;
    padding: 0.25rem 0.625rem;
    font: var(--subheading);
    color: var(--white);
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.25s .5s ease-in-out;
`

const PriceTableRow = ({ name, pieceType, quantity, price, available, totalCost, index }) => {
    const { activeColor } = useAppContext();
    const availableDate = available === 'Available' ? '' : 'Available '+available;
    const availabilityMeta = getAvailability(available);
    const [showModal, setShowModal] = useState(false);

    function toggleModal() {
        setShowModal(!showModal);
    }
    return (
        <StyledPriceTableRow key={`price-table-entry-${index}`}>
            <td className={"availabilityIcon " + availabilityMeta.color} onClick={() => toggleModal()} />
            <td className="icon" onClick={() => toggleModal()}>
                <CloudinaryImage
                    imageId={`${activeColor}/three-quarters/${pieceType}`}
                    alt={pieceType}
                    width={50}
                    height={50}
                />
            </td>
            <td className="name" onClick={() => toggleModal()}>
                {name}
            </td>
            <td className="price">
                <span className='basePrice'>{formatCurrency(price)}</span> x {quantity} ={' '}
                <b>{formatCurrency(totalCost)}</b>
            </td>
            {/* <td>
                <IconButton
                    type="Trash"
                    ariaPressed={
                    highlightedPieceType === pieceType
                    }
                    ariaLabel={`Highlight ${name} pieces to delete`}
                    clickHandler={() => clickHandler(pieceType)}
                />
            </td> */}
            {availabilityMeta.color !== 'green' && (
                <DateMessage className='datemessage'>{availabilityMeta.text}</DateMessage>
            )}
            {showModal && (
                <PieceModal pieceType={pieceType} closeHandler={toggleModal} />
            )}
        </StyledPriceTableRow>
    );
};

export default PriceTableRow;