import { AdvancedImage, responsive, placeholder } from '@cloudinary/react';
import PropTypes from 'prop-types';

import { cloudinaryImage } from '../helpers/CloudinaryHelpers';

const CloudinaryImage = ({
  imageId,
  format,
  quality,
  alt,
  width,
  height,
  ...otherProps
}) => {
  const img = cloudinaryImage({ imageId, format, quality, alt, width, height });
  const steps = [width, width * 2];

  // console.log('previewImage in CloudinaryImage', 'img', img);

  return (
    <AdvancedImage
      cldImg={img}
      plugins={[responsive({ steps })]}
      alt={alt}
      {...otherProps}
    />
  );
};

CloudinaryImage.defaultProps = {
  format: 'webp',
  quality: 75,
};

CloudinaryImage.propTypes = {
  imageId: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  quality: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default CloudinaryImage;
