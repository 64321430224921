import defineMatrixPiece from "./DefineMatrixPiece";
import checkMatrixForOverlappingPieces from "./CheckMatrixForOverlappingPieces";

const rotatePieceAndCheckForOverlap = (x,y, currentRotation, groupNum, piecesMatrix) => {
  console.log('rotatePieceAndCheckForOverlap()', x,y, currentRotation, groupNum, piecesMatrix);

  // Define origPiecesMatrix to have a deep clone of the piecesMatrix
  let origPiecesMatrix = {...piecesMatrix};
  
  // Define newPiecesMatrix to edit
  let newPiecesMatrix = {...piecesMatrix};
  
  // Define rotatingPiece, the piece we are deleting
  const rotatingPiece = origPiecesMatrix[`${x}${y}`];

  const rotatingPieceType = rotatingPiece.pieceType;

  const rotationAmount = rotatingPieceType === 'swivelChair' ? 45 : 90;

  // Rotate the piece
  let newRotation = currentRotation + rotationAmount;
  if (newRotation === 360) { newRotation = 0; }

  console.log('rotatePieceAndCheckForOverlap', 'DEFINING rotatedPiece', rotatingPieceType, newRotation, x, y, groupNum);
  const rotatedPiece = defineMatrixPiece(rotatingPieceType, newRotation, x, y, groupNum);

  // Delete the piece and add the rotated piece
  delete newPiecesMatrix[`${x}${y}`];
  newPiecesMatrix[`${x}${y}`] = rotatedPiece;

  // Check for overlapping pieces
  const overlappingPieces = checkMatrixForOverlappingPieces(newPiecesMatrix);

  console.log('rotatePieceAndCheckForOverlap', 'overlappingPieces:', overlappingPieces);

  // If there are overlapping pieces, return false
  if (overlappingPieces) {
    return false;
  }

  // If there are no overlapping pieces, return the new piecesMatrix
  if (!overlappingPieces) {
    console.log('rotatePieceAndCheckForOverlap', 'YES YOU CAN ROTATE');
    console.log('rotatePieceAndCheckForOverlap', 'RETURNING rotatedPiece', rotatedPiece);
    return newPiecesMatrix;
  }
}

const rotatePiece = async (x,y, currentRotation, groupNum, piecesMatrix) => {
  // check first rotation, and if it's good, return it
  const firstRotation = rotatePieceAndCheckForOverlap(x,y, currentRotation, groupNum, piecesMatrix);
  if (firstRotation) {
    return firstRotation;
  }
  // if first rotation is bad, check second rotation
  else {
    const secondRotation = rotatePieceAndCheckForOverlap(x,y, currentRotation + 90, groupNum, piecesMatrix);
    // if second rotation is good, return it
    if (secondRotation) {
      return secondRotation;
    }
    // if second rotation is bad, return the original piecesMatrix 
    else {
      return piecesMatrix;
    }
  }
}

export default rotatePiece;