import defineMatrixPiece from "./DefineMatrixPiece";
import getNeighbor from "./GetNeighbor";
import { CONFIGURATOR_GRID_PAD } from '../../constants';

console.log('CONFIGURATOR_GRID_PAD', CONFIGURATOR_GRID_PAD);

const configurationToPiecesMatrix = (configuration) => {
  const newPiecesMatrix = {};
  
  configuration.map((piece) => {
        
    newPiecesMatrix[`${piece.x + CONFIGURATOR_GRID_PAD}${piece.y + CONFIGURATOR_GRID_PAD}`] = defineMatrixPiece(piece.pieceType, piece.rotation, piece.x + CONFIGURATOR_GRID_PAD, piece.y + CONFIGURATOR_GRID_PAD);

  });

  // get neihgbors for each piece and update the neighbor property
  Object.keys(newPiecesMatrix).map((key) => {
    const thisPiece = newPiecesMatrix[key];
    thisPiece.neighbor = {
      north: getNeighbor('north', newPiecesMatrix, thisPiece.x, thisPiece.y),
      south: getNeighbor('south', newPiecesMatrix, thisPiece.x, thisPiece.y),
      east: getNeighbor('east', newPiecesMatrix, thisPiece.x, thisPiece.y),
      west: getNeighbor('west', newPiecesMatrix, thisPiece.x, thisPiece.y),
    };
  });

  return newPiecesMatrix;
  
};

export default configurationToPiecesMatrix;