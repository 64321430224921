import { CONFIGURATOR_GRID_PAD } from '../../constants';
import defineDimensions from './DefineDimensions';

const updateConfigurationMeta = (piecesMatrix, activeColor, apiProducts, showDimensions) => {
  let newPrice = 0;
  let newHeight = 0;
  let newPiecesList = {
    corner: {
      pieceType: 'corner',
      name: 'Corner',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    armless: {
      pieceType: 'armless',
      name: 'Armless',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    armlessRecliner: {
      pieceType: 'armless',
      name: 'Armless',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    reclinerLeft: {
      pieceType: 'reclinerLeft',
      name: 'Left Arm Recliner',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    reclinerRight: {
      pieceType: 'reclinerRight',
      name: 'Right Arm Recliner',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    chaiseLeft: {
      pieceType: 'chaiseLeft',
      name: 'Left Arm Chaise',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    chaiseRight: {
      pieceType: 'chaiseRight',
      name: 'Right Arm Chaise',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    console: {
      pieceType: 'console',
      name: 'Storage Console',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
    consoleTech: {
      pieceType: 'consoleTech',
      name: 'Media Console With Tech',
      quantity: 0,
      price: 0,
      totalCost: 0,
      available: ''
    },
  };
  let newGridXMax = 0;
  let newGridXMin = 0;
  let newGridYMax = 0;
  let newGridYMin = 0;

  let newWidth = 0;
  let newDepth = 0;

  let largerOfGridDimensions = 0;

  let newDimensions = {
    north: [],
    south: [],
    east: [],
    west: [],
  };

  if (piecesMatrix) {
    Object.keys(piecesMatrix).map((key) => {
      const thisPiece = piecesMatrix[key];
      // Add price
      newPrice = newPrice + apiProducts[activeColor][thisPiece.pieceType].price;      
      // Get Height
      if (thisPiece.meta.productHeight > newHeight) {
        newHeight = thisPiece.meta.productHeight;
      }
      // Add To newPiecesList
      newPiecesList[thisPiece.pieceType].quantity++;
      newPiecesList[thisPiece.pieceType].totalCost +=
        apiProducts[activeColor][thisPiece.pieceType].price;
      newPiecesList[thisPiece.pieceType].price =
        apiProducts[activeColor][thisPiece.pieceType].price;
      
      // Add Availability
      newPiecesList[thisPiece.pieceType].available = apiProducts[activeColor][thisPiece.pieceType].availability;

      console.log("newPiecesList", newPiecesList[thisPiece.pieceType].available, apiProducts[activeColor][thisPiece.pieceType].availability);

      // gridXMax
      if (thisPiece.coords.SE.x >= newGridXMax) {
        newGridXMax = thisPiece.coords.SE.x;
      }
      // gridXMin
      if (thisPiece.coords.NW.x <= newGridXMin) {
        newGridXMin = thisPiece.coords.NW.x;
      }

      // gridYMax
      if (thisPiece.coords.SE.y >= newGridYMax) {
        newGridYMax = thisPiece.coords.SE.y;
      }
      // gridYMin
      if (thisPiece.coords.NW.y <= newGridYMin) {
        newGridYMin = thisPiece.coords.NW.y;
      }
    });

    // Get Dimensions
    if (showDimensions) {
      newDimensions = defineDimensions(piecesMatrix);
      console.log('newDimensions', newDimensions);
    }
    
    largerOfGridDimensions = newGridXMax >= newGridYMax ? newGridXMax : newGridYMax;

    console.log('WeirdDimensions', newGridXMax, newGridYMax, largerOfGridDimensions);
    if (newGridXMax > 0) { newWidth = (newGridXMax + 1 - CONFIGURATOR_GRID_PAD) / 4; }
    if (newGridYMax > 0) { newDepth = (newGridYMax + 1- CONFIGURATOR_GRID_PAD) / 4; }
    
  }

  return {
    id: 'custom-config',
    numGridColumns: largerOfGridDimensions + CONFIGURATOR_GRID_PAD,
    numGridRows: largerOfGridDimensions + CONFIGURATOR_GRID_PAD,
    dimensions: newDimensions,
    gridXMin: newGridXMin,
    gridXMax: newGridXMax,             
    gridYMin: newGridYMin,
    gridYMax: newGridYMax,
    piecesList: newPiecesList,
    price: newPrice,
    productWidth: newWidth,
    productDepth: newDepth,
    productHeight: newHeight,
    configuration: Object.values(piecesMatrix),
  };
};

export default updateConfigurationMeta;
