import defineMatrixPiece from "./DefineMatrixPiece";
import definePiecesForDelete from "./DefinePiecesForDelete";
import checkMatrixForOverlappingPieces from "./CheckMatrixForOverlappingPieces";
import shiftOtherGroupsAfterDelete from "./ShiftOtherGroupsAfterDelete";
import { CONFIGURATOR_GRID_PAD } from "../../constants";

const removePieceFromMatrixAndShift = async (x,y, piecesMatrix) => {

  // Define origPiecesMatrix to have a deep clone of the piecesMatrix
  let origPiecesMatrix = {...piecesMatrix};
  // Define newPiecesMatrix to edit
  let newPiecesMatrix = {...piecesMatrix};
  
  // Define deletedPiece, the piece we are deleting
  const deletedPiece = origPiecesMatrix[`${x}${y}`];

  // Define deletedPieceGroupNum, the groupNum of the piece we are deleting
  const deletedPieceGroupNum = deletedPiece.groupNum;

  // Delete the piece
  delete newPiecesMatrix[`${x}${y}`];

  // Stop the presses if this is a corner piece connected to 2 pieces
  if (deletedPiece.pieceType === 'corner') {
    let cornerConnections = 0;
    if (deletedPiece.neighbor.north) { cornerConnections++; }
    if (deletedPiece.neighbor.east) { cornerConnections++; }
    if (deletedPiece.neighbor.south) { cornerConnections++; }
    if (deletedPiece.neighbor.west) { cornerConnections++; }
    if (cornerConnections > 1) {
      // Reset Neighbors
      Object.keys(newPiecesMatrix).map((key) => {
        const thisPiece = newPiecesMatrix[key];
        thisPiece.neighbor = {
          north: null,
          east: null,
          south: null,
          west: null
        }      
      });
      console.log('deleteShiftFix', 'no shift', 'because corner');
      return newPiecesMatrix;
    }
  }

  // Now we are checking for pieces to move
  // Do we go N,S,E or W? Depends on rotation of the piece

  // East (r0 or r180) by default
  let direction = 'east';
  let shiftX = -deletedPiece.width;
  let shiftY = 0;

  // South if piece is r90 or r270
  if ( deletedPiece.rotation === 90 || deletedPiece.rotation === 270) {
    // console.log('deleteSouth', 'r90 or r270');
    direction = 'south';
    shiftX = 0;
    shiftY = -deletedPiece.height;
  }
  
  // Define the pieces that'll move
  const piecesToMove = await definePiecesForDelete(origPiecesMatrix, deletedPiece, deletedPiece, direction);
    
  // if length of piecesToMove = length of origPiecesMatrix we have a loop
  // only delete current piece is loop (already deleted in newPiecesMatrix)
  if (piecesToMove.length === Object.keys(origPiecesMatrix).length) {
    Object.keys(newPiecesMatrix).map((key) => {
      const thisPiece = newPiecesMatrix[key];
      thisPiece.neighbor = {
        north: null,
        east: null,
        south: null,
        west: null
      }      
    });
    console.log('deleteShiftFix', 'no shift', 'because loop');
    return newPiecesMatrix;
  }


  // If we don't have a loop, shift the newPiecesMatrix pieces
  const shiftedPiecesMatrix = {};
  const groups = [];
  
  Object.keys(newPiecesMatrix).map((key) => {
    const thisPiece = newPiecesMatrix[key];
    if (thisPiece.groupNum && !groups.includes(thisPiece.groupNum)) {
      groups.push(thisPiece.groupNum);
    }
    const thisPieceShouldMove = piecesToMove.filter((obj) => obj.id === `${thisPiece.x}${thisPiece.y}`).length > 0;
    const newX = thisPieceShouldMove ? thisPiece.x + shiftX : thisPiece.x;
    const newY = thisPieceShouldMove ? thisPiece.y + shiftY : thisPiece.y;
    const shiftedMatrixPiece = defineMatrixPiece(thisPiece.pieceType, thisPiece.rotation, newX, newY, thisPiece.groupNum);
    shiftedPiecesMatrix[`${shiftedMatrixPiece.x}${shiftedMatrixPiece.y}`] = shiftedMatrixPiece;
  });  

  const multipleGroups = groups.length > 1;
  
  // After shifting, checkMatrixForOverlappingPieces
  // return newPiecesMatrix (only delete, don't shift)
  if (checkMatrixForOverlappingPieces(shiftedPiecesMatrix)) {
    // Reset the neighbors
    Object.keys(newPiecesMatrix).map((key) => {
      const thisPiece = newPiecesMatrix[key];
      thisPiece.neighbor = {
        north: null,
        east: null,
        south: null,
        west: null
      }      
    });
    console.log('deleteShiftFix', 'no shift', 'because overlap');
    return newPiecesMatrix;
  }

  // shift other groups if a piece is left
  // const groupShiftedPiecesMatrix = Object.keys(shiftedPiecesMatrix).length > 0 && multipleGroups ? shiftOtherGroupsAfterDelete(shiftedPiecesMatrix, deletedPiece, direction) : shiftedPiecesMatrix; 
  
  const groupShiftedPiecesMatrix = shiftedPiecesMatrix;
  
  // Now zero-out the configuration
  let lowestX = 99999999999999;
  let lowestY = 99999999999999;

  // Iterate over shiftedPiecesMatrix
  // Define the lowest x and y coord and then shift everything to zero out
  Object.keys(groupShiftedPiecesMatrix).map((key) => {
    if (groupShiftedPiecesMatrix[key].x < lowestX) {
      lowestX = groupShiftedPiecesMatrix[key].x;
    }
    if (groupShiftedPiecesMatrix[key].y < lowestY) {
      lowestY = groupShiftedPiecesMatrix[key].y;
    }
  });

  const zeroOutPiecesMatrix = {};
    
    Object.keys(groupShiftedPiecesMatrix).map((key) => {
      const zeroOutX = groupShiftedPiecesMatrix[key].x - lowestX + CONFIGURATOR_GRID_PAD;
      const zeroOutY = groupShiftedPiecesMatrix[key].y - lowestY + CONFIGURATOR_GRID_PAD;
      const zeroOutMatrixPiece = defineMatrixPiece(groupShiftedPiecesMatrix[key].pieceType, groupShiftedPiecesMatrix[key].rotation, zeroOutX, zeroOutY, groupShiftedPiecesMatrix[key].groupNum);
      zeroOutPiecesMatrix[`${zeroOutX}${zeroOutY}`] = zeroOutMatrixPiece;
    });

    return zeroOutPiecesMatrix;

}

export default removePieceFromMatrixAndShift;