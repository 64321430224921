import React from "react";
import styled from "styled-components";
import { ArrowsOutGlyph, MinusGlyph, PlusGlyph, XGlyph, TrashGlyph, RotateGlyph } from "./Icons";

/* 
  IconButton
  Example: this is used in the configurator

  <IconButton type="Minus" ariaLabel="zoom out" clickHandler={zoomOut} />

  We're importing all the glyphs, and defining a TYPE_MAP object so we can
  specify the glyph with the 'type' prop.

  IconButtonElement styles the button with background, shadow, and glyph colors
*/

const IconButtonElement = styled.button`
--icon-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.15);
--icon-scale: 1;
--icon-background-color: var(--white);
--icon-glyph-color: var(--dark-tyrian-blue);
&[aria-pressed='true'],
&:hover,
&focus-visible {
  --icon-background-color: var(--rtg-blue);
  --icon-glyph-color: var(--white);
}
&:active {
  --icon-scale: 0.975;
  --icon-box-shadow: 0 0.0125rem 0.0125rem rgba(0, 0, 0, 0.15);
}
width: 1.8125rem;
height: 1.8125rem;
padding: 0.125rem;
&[data-style='rounded'] {
  width: 2.5rem;
  height: 2.5rem;
}
&[data-with-label='on'] {
  display: grid !important;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 0.625rem;
  width: auto;
}
`;

const Label = styled.span`
  font: var(--button);
  text-transform: uppercase;
  color: var(--dark-tyrian-blue);
`;

const Icon = styled.div`
  background: var(--icon-background-color);
  --glyph-color: var(--icon-glyph-color);
  box-shadow: var(--icon-box-shadow);
  transition: all 0.15s;
  transform: scale(var(--icon-scale));
  width: 1.8125rem;
  height: 1.8125rem;
  padding: 0.125rem;
  position: relative;
  &[data-style='rounded'] {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    border: 0.125rem solid var(--icon-glyph-color);
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const IconButton = ({ type, style, ariaLabel, ariaPressed, label, clickHandler }) => {
  const TYPE_MAP = {
    ArrowsOut: ArrowsOutGlyph,
    Minus: MinusGlyph,
    Plus: PlusGlyph,
    X: XGlyph,
    Trash: TrashGlyph,
    Rotate: RotateGlyph
  };
  const Glyph = TYPE_MAP[type];
  return (
    <IconButtonElement
      aria-pressed={ariaPressed}
      aria-label={ariaLabel}
      onClick={clickHandler}
      data-style={style}
      data-with-label={label ? 'on' : ''}
    >
      <Icon data-style={style}>
        <Glyph />
      </Icon>
      {label && <Label>{label}</Label>}
    </IconButtonElement>
 )
}

export default IconButton;