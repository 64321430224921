/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 armless.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function Armless(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/armless.glb'))
  // const { nodes, materials } = useGLTF('m23d/armless.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.41,0,0.54]} scale={[0.99, 1, 1]}>
        <mesh geometry={nodes.Seat024_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH120_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH119_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Hardware_Cover024_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Feet067_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_021_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_SEATCUSHIONS_RECLINER_CLOSED016_Retopo005_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_057_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_STICH070_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Back_Standing020_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_013_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER010_Retopo_n3d.geometry} material={props.materials.fabric} />
      </group>
    </group>
  )  
}

// useGLTF.preload(cloudinary3dAsset('m23d/armless.glb'))
// useGLTF.preload('m23d/armless.glb')
