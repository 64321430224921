/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 armless.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../../helpers/CloudinaryHelpers'

export function ArmlessRecliner(props) {
  const { nodes, materials } = useGLTF(cloudinary3dAsset('m23d/armless-recliner.glb'))
  // const { nodes, materials } = useGLTF('m23d/armless-recliner.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.395,0,0.54]} scale={[0.99, 1, 1]}>
        <mesh geometry={nodes.StitchGenerator_StitchObj_021_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_SEATCUSHIONS_RECLINER_CLOSED016_Retopo005_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_054_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Back_Standing025_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT026_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT025_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT024_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_RAFRECLINER_ARM_POWEROUTPUT023_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.Seat022_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.SEAT_CONTROLERS026_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.SEAT_CONTROLERS025_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.SEAT_CONTROLERS024_n3d.geometry} material={props.materials.panel} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER_STICHES044_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.Feet066_n3d.geometry} material={props.materials.feet} />
        <mesh geometry={nodes.Hardware_Cover023_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER_STICHES043_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.StitchGenerator_StitchObj_013_n3d.geometry} material={props.materials.fabric} />
        <mesh geometry={nodes.RTG_MT_BACKCUSHIONS_RECLINER010_Retopo_n3d.geometry} material={props.materials.fabric} />
      </group>
    </group>
  )
}

// useGLTF.preload(cloudinary3dAsset('m23d/armless-recliner.glb'))
// useGLTF.preload('m23d/armless-recliner.glb')
